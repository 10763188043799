import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { baseUrl, theme } from "../../../../config/Config";
import { getApiCall, postApiCall } from "../../../../ApiHelper";
import { Button, Grid } from "@mui/material";
import ApproverLevelDataGrid from "./ApproverLevelDataGrid";
import { initialResponseData } from "./ApproverLevelDialogBox";
import { approverLevelResponse } from "../../../interfaces/Interfaces";
import ApproverLevelDialogBox from "./ApproverLevelDialogBox";
import { ToastMessage } from "../../../components/utils/ToastMessage";
import { toast } from "../../../interfaces/Interfaces";

function ApproverLevel() {
  const [rowData, setRowData] = useState<any>();
  const [addApproverLevelData, setAddApproverLevelData] = useState<any>();
  const [type, setType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [approverDataLoading, setApproverDataLoading] =
    useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [gridDropDown, setGridDropDown] = useState<any>({
    approverLevels: [],
    authName: [],
    funds: [],
    fundTypes: [],
    CreatedBy: [],
    CreatedOn: [],
    UpdatedBy: [],
    UpdatedOn: [],
  });
  const [updateData, setUpdateData] =
    useState<approverLevelResponse>(initialResponseData);

  useEffect(() => {
    if (!openDialog) {
      setUpdateData(initialResponseData);
    }
  }, [openDialog]);

  useEffect(() => {
    if (submitClicked) {
      getApproverLevelData();
    }
  }, [submitClicked]);

  useEffect(() => {
    getApproverLevelData();
  }, []);

  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
    setTimeout(() => setToast(false), 4000);
  };
  const getApproverLevelData = async () => {
    var url = `${baseUrl}ApproverLevels/info`;
    try {
      setLoading(true);
      const res = await getApiCall(url).then((response: any) => {
        const formattedRows = response?.data?.map((row: any) => ({
          ...row,
          meetsBudget:
            row?.meetsBudget === true
              ? "Yes"
              : row?.meetsBudget === false
                ? "No"
                : undefined,
          isAmtHigherThanValuationPercentage:
            row?.isAmtHigherThanValuationPercentage === true
              ? "Yes"
              : row?.isAmtHigherThanValuationPercentage === false
                ? "No"
                : undefined,
          marketRate:
            row?.marketRate === true
              ? "Yes"
              : row?.marketRate === false
                ? "No"
                : undefined,
          ecoTerms:
            row?.ecoTerms === true
              ? "Yes"
              : row?.ecoTerms === false
                ? "No"
                : undefined,
          isActive: row?.isActive ? "Yes" : "No",
        }));
        setRowData(formattedRows);
        setGridDropDown({
          approverLevels: response.approverLevels,
          authName: response.authName,
          funds: response.funds,
          fundTypes: response.fundTypes,
          createdBy: response.createdBy,
          createdOn: response.createdOn,
          updatedBy: response.updatedBy,
          updatedAt: response.updatedAt,
        });
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      errorToastMsgwithCode(error);
      console.log("error in fetching funds", error);
    }
  };
  const addApproverLevel = async () => {
    setSubmitClicked(false);
    setOpenDialog(true);
    setLoading(false);
    if (addApproverLevelData === null || addApproverLevelData === undefined) {
      var url = `${baseUrl}ApproverLevels/dropdowns`;
      setApproverDataLoading(true);
      try {
        const res = await getApiCall(url).then((response: any) => {
          setAddApproverLevelData(response);
          setApproverDataLoading(false);
        });
      } catch (error) {
        errorToastMsgwithCode(error);
        console.log("error in fetching funds", error);
      }
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <Grid item lg={4}>
          <h2 className="text-left mstitle">Authorizations Approver Levels</h2>
        </Grid>
        <ApproverLevelDataGrid
          rowdata={rowData}
          loading={loading}
          addApproverLevel={addApproverLevel}
          gridDropDown={gridDropDown}
          setOpen={setOpenDialog}
          updateRow={setUpdateData}
          submitClicked={submitClicked}
          type={setType}
        />
      </div>
      {openDialog ? (
        <ApproverLevelDialogBox
          open={openDialog}
          setOpen={setOpenDialog}
          approverDataLoading={approverDataLoading}
          addApproverLevelData={addApproverLevelData}
          updateRow={updateData}
          submitClicked={setSubmitClicked}
          rowdata={rowData}
          type={type}
        />
      ) : null}
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
    </ThemeProvider>
  );
}

export default ApproverLevel;
