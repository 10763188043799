import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { baseUrl } from "../../../../config/Config";
import { postApiCall } from "../../../../ApiHelper";
import { fundemailValdiate } from "../../../interfaces/Interfaces";
import { fundEmailResponse } from "../../../interfaces/Interfaces";
import { ToastMessage } from "../../../components/utils/ToastMessage";
import { toast } from "../../../interfaces/Interfaces";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";

import { approverValidate } from "../../../interfaces/Interfaces";
import { Key, PanoramaSharp } from "@mui/icons-material";
import { isForInStatement } from "typescript";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const FundsEmailDialogBox = (props: any) => {
  console.log(props?.approverdropdown, "props");
  const [toast, setToast] = useState(false);
  const [responseData, setResponseData] = useState<fundEmailResponse>({
    id: null,
    fundName: [],
    authorizationType: {
      name: "",
      value: "",
    },
    emails: "",
    isActive: true,
  });
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [disableLoader, setDisableLoader] = useState<boolean>(false);
  const [validateData, setValidateData] = useState<fundemailValdiate>({
    authName: false,
    fundName: false,
    emails: false,
  });
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [initailData, setInitialData] = useState<any>({
    authName: "",
    fundName: "",
  });

  useEffect(() => {
    setResponseData(props.updateRow);
    setInitialData({
      authName: props.updateRow.authorizationType.value,
      fundName: props.updateRow.fundName,
    });
  }, [props?.updateRow]);
  const validateEmail = (emailString: string): any => {
    // Regex to match valid emails
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Split the emails by semicolon and trim any extra spaces
    const emails = emailString.split(";").map((email) => email.trim());
    // Check if every email matches the email pattern
    return emails.every((email) => emailRegex.test(email));
  };

  const checkValidation = () => {
    validateEmail(responseData?.emails ?? "");
    setValidateData({
      authName: responseData?.authorizationType.value == "" ? true : false,
      fundName: responseData?.fundName?.length == 0 ? true : false,
      emails: responseData?.emails == "" ? true : false,
    });
    return (
      responseData?.authorizationType.name == "" ||
      responseData?.fundName?.length == 0 ||
      responseData?.authorizationType.name == ""
    );
  };
  const checkDuplicate = (rowData: any[]) => {
    if (
      props.type === "Edit" &&
      initailData.authName === responseData?.authorizationType?.value &&
      initailData.fundName === responseData.fundName
    ) {
      return false;
    } else {
      var check = rowData.some(
        (item) =>
          item.authName === responseData.authorizationType.value &&
          item.fundName === responseData.fundName
      );

      return check;
    }
  };

  const handleSubmitClick = async () => {
    var checkFormValidation = checkValidation();
    var checkForDuplicate = checkDuplicate(props.rowdata);   
    if (
      checkFormValidation !== true &&
      !checkForDuplicate &&
      validateEmail(responseData.emails ?? "") === true
    ) {
      setButtonLoader(true);
      handleSubmit();
    } else if (checkForDuplicate) {
      setToast(true);
      setToastMessage({
        message: "Fund emails already exists",
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    } else if (responseData?.emails && validateEmail(responseData?.emails ?? "") === false) {
      setToast(true);
      setToastMessage({
        message: "Please enter valid email",
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    } else {
      setToast(true);
      setToastMessage({
        message: "Please fill in the required field(s)",
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };

  const handleSubmit = async () => {
    var url = `${baseUrl}FundEmail/data`;
    try {
      const payloaddata = {
        id: responseData.id,
        fundName: responseData.fundName,
        emails: responseData.emails,
        isActive: responseData?.isActive,
        authcode: responseData?.authorizationType.name,
      };
      const res = await postApiCall(url, payloaddata).then(() => {
        setToast(true);
        setToastMessage({
          message: "Fund emails saved sucessfully",
          severity: "success",
        });
        setButtonLoader(false);
        setDisableLoader(true);
        setTimeout(() => setToast(false), 2000);
        setTimeout(() => props.setOpen(false), 2000);
      });
      props.submitClicked(true);
    } catch (error: any) {
      setToast(true);
      setToastMessage({
        message: `${error.response?.data?.description} (${error?.response?.data?.code})`,
        severity: "error",
      });
      setButtonLoader(false);
      setTimeout(() => setToast(false), 4000);
    }
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        fullWidth
        className="common-dialog-box"
      >
        <DialogTitle id="alert-dialog-title">
          {props.type} Funds Email
        </DialogTitle>

        <DialogContent
          style={{
            height: "calc(60vh - 64px)",
            justifyContent: "center",
          }}
          className="form-field-label"
        >
          {/* First two fields on the top */}
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Grid>
                <label>Authorization Type</label>
                <span className="mandatory"> * </span>
              </Grid>
              <Grid>
                <Autocomplete
                  options={
                    props?.approverdropdown?.authTypes
                      ? Object?.keys(props?.approverdropdown?.authTypes)?.map(
                        (key) => ({
                          name: key,
                          value: props?.approverdropdown?.authTypes[key],
                        })
                      )
                      : []
                  }
                  value={responseData?.authorizationType}
                  getOptionLabel={(option) => option?.value}
                  onChange={(event: any, newValue: any) => {
                    setResponseData({
                      ...responseData,
                      authorizationType: newValue,
                    });

                    setValidateData({ ...validateData, authName: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={validateData?.authName}
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid>
                <label htmlFor="field1">Fund Name</label>

                <span className="mandatory"> * </span>
              </Grid>
              <Grid>
                <Autocomplete
                  multiple
                  options={props?.approverdropdown?.funds ?? []}
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  limitTags={1}
                  value={responseData?.fundName ?? []}
                  onChange={(event, newInputValue) => {
                    if (newInputValue.includes('Others')) {
                      setResponseData({ ...responseData, fundName: ['Others'] });
                    } else if (responseData?.fundName?.includes('Others')) {
                    
                      setResponseData({ ...responseData, fundName: newInputValue.filter(item => item !== 'Others') });
                    } else {
                      setResponseData({ ...responseData, fundName: newInputValue });
                    }
                    setValidateData({ ...validateData, fundName: false });
                  }}
                  getOptionDisabled={(option: string): boolean => {
                    return Boolean(responseData?.fundName?.includes('Others') && option !== 'Others');
                  }}
                 
                  renderOption={(props, option: any, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      ></Checkbox>
                      {option}
                    </li>
                   )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      error={validateData.fundName}
                    />
                  )}
                  size="small"
                />
              </Grid>
              {/* Bottom field, centered */}
            </Grid>
            <Grid item xs={6} md={6}>
              <label htmlFor="field1">Email</label>
              <span className="mandatory"> * </span>
              <TextField
                placeholder="Please enter multiple emails with ;"
                size="small"
                fullWidth
                minRows={1}
                maxRows={1}
                error={validateData?.emails}
                value={responseData?.emails}
                onChange={(event) => {
                  setResponseData({
                    ...responseData,
                    emails: event.target.value,
                  });
                  setValidateData({ ...validateData, emails: false });
                }}
              >
                {" "}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={responseData?.isActive}
                    onChange={(event) =>
                      setResponseData({
                        ...responseData,
                        isActive: event.target.checked,
                      })
                    }
                  />
                }
                label="Is Active"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#343434",
                    lineHeight: "24px",
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              props.setOpen(false);
            }}
            color="primary"
            variant="outlined"
            className="btn-tertiary"
          >
            Cancel
          </Button>

          <LoadingButton
            className={disableLoader ? "btn-primary-disabled" : "btn-primary"}
            variant="contained"
            disabled={disableLoader}
            loading={buttonLoader}
            onClick={() => {
              handleSubmitClick();
            }}

          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
    </>
  );
};
export default FundsEmailDialogBox;
