import { baseUrl, theme } from "../config/Config"
import { ThemeProvider } from "@mui/material/styles";
import Loader from "../Authorizations/components/utils/Loader";
import QAHeaderMain from "./Header/QAHeaderMain";
import QAActionButton from "./components/QAActionButton";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { useEffect, useState } from "react";
import { getApiCall } from "../ApiHelper";
import QAAttestation from "./components/QAAttestation";
import { CircularProgress } from "@mui/material";
import { setLoading } from "../redux/slices/loaderSlice";
const gridwidth = {
  width: "99%",
};

const QAComponent = (props: any) => {

  const { id } = useParams();
  const [data, setData] = useState<any>();
  const[isloading , setisloading] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setisloading(true)
        var url = `${baseUrl}Attestations/qadata?id=${id}`;
        try {
          const response = await getApiCall(url);
          setData(response);
        } catch (error) {
          console.log("error in fetching data", error);
          
        }
        setisloading(false)
      }
    };
    fetchData();
  }, [id]);

  return (
    <>
    
    
    {!isloading ?  <div className="form-dv form-field-label" style={gridwidth} >            
      <ThemeProvider theme={theme}>
          <QAHeaderMain qaData={data} />
          <QAAttestation
            canattest={data?.canAttest}
            attestdescription={data?.attestationDescription}
            sectorheadname={data?.sectorHeadName}
            isAttested = {data?.isAttested}
            formId = {id}
          />
          <QAActionButton />
          <Loader />
        </ThemeProvider>
      </div> : <div  className="loading-backdrop loading-backdrop-full">
             <CircularProgress />
           </div>}
    </>
  );
};

export default QAComponent;
