import {useEffect, useState} from "react";
const useConfig = () => {
    const [config, setConfig] = useState<any>();
  
    useEffect(() => {
      const loadAsync = async () => {
        const response = await fetch('/appsettings.json');
        const json = await response.json();
        setConfig(json);
        localStorage.setItem("config", JSON.stringify(json));
      };
      loadAsync();
    }, []);
  
    return [config];
  };
  
  export default useConfig;