import { baseUrl } from "../../config/Config";
import { getApiCall, postApiCall } from "../../ApiHelper";


export class DashboardService {

  getTabs(mailId: string|undefined) {
    var url = `${baseUrl}` + "Dashboard/dashboardViews";
    const res = getApiCall<string[]>(url).then((data) => data);
    return res;
  }

  FetchDashboardAuthorization(payload:any) {
    var url = `${baseUrl}Dashboard/AuthorizationData`;   
    const res = postApiCall(url, payload).then((data) => data);
    return res;
  }

  FetchDashboardAuthorizationExcel(payload:any) {
    var url = `${baseUrl}Dashboard/ExcelAuthorizationData`;   
    const res = postApiCall(url, payload).then((data) => data);
    return res;
  }

}
