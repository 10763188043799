import { useCallback, useState } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { approversPayload , toast } from '../../../interfaces/Interfaces';
import { getNewFormData } from '../../../../redux/slices/newFormDataSlice';

// Custom hook for selecting state
export const useAppState = () => {
    const formData = useAppSelector((state) => state.getFormData.formData);
    const submitErrorModel = useAppSelector((state) => state.submitFormError);
    const userdata = useAppSelector((state) => state.userContextDetail.data);
    const leaseData = useAppSelector((state) => state.commonData.leaseData);
    const capitalAuthFormType = useAppSelector((state) => state.commonData.formType);
    const authAmountChangeByAm = useAppSelector((state) => state.commonData.amountChangeByAm);
    const authorizationData = useAppSelector((state) => state.authorizationData.data);
    const approvedAmount = useAppSelector((state) => state.budgetedData.data.budgetedAmount);
    const budgetedData = useAppSelector((state) => state.budgetedData);
    const nonBudgetedData = useAppSelector((state) => state.nonBudgetedData);
    const ModProjectDetailData = useAppSelector((state) => state.modProjectDetailsData.data);
    const ecoTermChange = useAppSelector((state) => state.commonData.data);
    const leaseProjectDetails = useAppSelector((state) => state.leaseProjectDetail.data);
    const proposaldata = useAppSelector((state) => state.proposaldata);
    const AuthData = useAppSelector(getNewFormData);
  
    return {
      formData,
      submitErrorModel,
      userdata,
      leaseData,
      capitalAuthFormType,
      authAmountChangeByAm,
      authorizationData,
      approvedAmount,
      budgetedData,
      nonBudgetedData,
      ModProjectDetailData,
      ecoTermChange,
      leaseProjectDetails,
      proposaldata,
      AuthData,
    };
  };

  // Custom hook for managing local state
export const useLocalState = (formData: any) => {
    const [showValuationText, setShowValuationText] = useState(false);
    const [loading, setLoading] = useState(false);
    const [approversList, setApproversList] = useState<any>();
    const [showApprovers, setShowApprovers] = useState(true);
    const [selectedAssetManager, setSelectedAssetManager] = useState<{ name: string, email: string }>({ name: "", email: "" });
    const [selectedPropertyManager, setSelectedPropertyManager] = useState<{ name: string, email: string } | null>({ name: "", email: "" });
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState<toast>({
      message: "",
      severity: "",
    });
    const [valuationSelect, setValuationSelect] = useState(() => {
      if (formData?.isAmtHigherThanValuationPercentage === true) return "Yes";
      if (formData?.isAmtHigherThanValuationPercentage === false) return "No";
      return "";
    });
    const [authApproversRequest, setAuthApproversRequest] = useState<approversPayload>({
      authType: "",
      fundName: "",
      amount: 0.0,
      approvedAmount: 0,
      isAmtHigherThanValuationPercentage: false,
      term: 0,
      sqft: 0.0,
      ecoTerms: null,
      terminationFee: "",
      terminationTime: "",
      isOmni: formData?.propertyManagementCompanyName === "OMNI",
      isOmniBudgeted: formData?.propertyManagementCompanyName === "OMNI" && formData?.formType,
      marketRate: null
    });

  
    return {
      showValuationText,
      setShowValuationText,
      loading,
      setLoading,
      approversList,
      setApproversList,
      valuationSelect,
      setValuationSelect,
      authApproversRequest,
      setAuthApproversRequest,
      showApprovers,
      setShowApprovers,
      selectedAssetManager,
      setSelectedAssetManager,
      selectedPropertyManager,
      setSelectedPropertyManager,
      toast,
      setToast,
      toastMessage,
      setToastMessage
    };
  };
  