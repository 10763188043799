import { useEffect, useState } from "react";
import { uploadedDocumentProps, toast } from "../Interfaces/AttestInterfaces";
import moment from "moment";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
  GridPreProcessEditCellProps,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { filesTobeUploaded } from "../redux/slices/attestFileUpload";
import { baseUrl } from "../../config/Config";
import { ToastMessage } from "../../Authorizations/components/utils/ToastMessage";
import {
  alldocuments,
  getAllDocumentData,
  getAllDocuments,
} from "../redux/slices/attestdocumentslice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getApiCall, postApiCall } from "../../ApiHelper";
import { DialogBox } from "../../attestutils/DialogBox";
import { debounce } from "lodash";
import { TextField } from "@mui/material";


export function AttestUploadedDocument({
  document,
  mode,
}: uploadedDocumentProps) {

  const [toast, setToast] = useState(false);
  const [selectedKey, setSelectedKey] = useState<any>();
  const [confirmationDialogBox, setConfirmationDialogBox] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.ivaFormData.ivaFormData);
  const allDocuments = useAppSelector(getAllDocumentData);
  const uploadedDoc = useAppSelector((state) => state.attestUploadData.data);
  const attestalldocuments = useAppSelector((state) => state.attestdocumentData.alldocuments)
  useEffect(() => {
    dispatch(filesTobeUploaded(allDocuments));
    dispatch(alldocuments(allDocuments))
  }, [allDocuments]);
  const apiRef = useGridApiRef()
  let columns: GridColDef[] = [
    {
      field: "docName",
      headerName: "Document",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <a
              className="downloadLink"
              onClick={() => {
                downloadFilePresignedUrl(params.formattedValue);
              }}
            >
              {params.row.docName}
            </a>
          </>
        );
      },
    },
    {
      field: 'comment',
      headerName: 'Comments',
      editable: true,
      flex: 2,
      renderEditCell: (params) => {
        return (
            <TextField
              fullWidth
              autoFocus
              value={params.value}
              onChange={(e) => {
                const newRow = { ...params.row, [params.field]: e.target.value };
                params.api.setEditCellValue({ 
                  id: params.id, 
                  field: params.field, 
                  value: e.target.value 
                });
                debouncedHandleEdit(newRow);
              }}
            />
        );
      }
    },
    
    {
      field: "uploadedOn",
      headerName: "Uploaded Date",
      flex: 2,
      renderCell: (params) => {
        return moment(params.row.uploadedOn).format("MMM-DD-YYYY hh:mm A");
      },
    },

    {
      field: "action",
      headerName: "Action",
      type: "actions",
      flex: 2,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (mode === "edit") {
          return (
            <>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                style={{ color: "#BA1A2E" }}
                label="Delete"
                onClick={() => {
                  setConfirmationDialogBox(true);
                  setSelectedKey(params.id);
                }}
                color="inherit"
              />
            </>
          );
        }
      },
    },
  ];

  
  const debouncedHandleEdit = debounce((row: any) => {
    handelEditComment(row);
  }, 300);

  if (!formData?.isFormEditable && !formData?.allowDocumentUpload) {
    columns = [
      {
        field: "docName",
        headerName: "Document",
        flex: 2,
        renderCell: (params) => {
          return (
            <>
              <a
                className="downloadLink"
                onClick={() => {
                  downloadFilePresignedUrl(params.formattedValue);
                }}
              >
                {params.row.docName}
              </a>
            </>
          );
        },
      },

      {
        field: "comment",
        headerName: "Comments",
        flex: 2,
        editable: false,
      },
      {
        field: "uploadedOn",
        headerName: "Uploaded Date",
        flex: 2,
        renderCell: (params) => {
          return moment(params.row.uploadedOn).format("MMM-DD-YYYY hh:mm A");
        },
      },
    ];
  }
  const handleDocDelete = async () => {
    let invaid: any = formData?.id;
    const request = {
      authId: invaid,
      fileKey: selectedKey,
    };
    var url = `${baseUrl}Document/invaldelete`;
    try {
      await postApiCall(url, request).then((response: any) => {
        if (response === true) {
          dispatch(getAllDocuments(invaid));
          setToast(true);
          setToastMessage({
            message: "File Deleted sucessfully",
            severity: "success",
          });
          setTimeout(() => setToast(false), 4000);
        } else {
          setToast(true);
          setToastMessage({
            message: "Technical issue incountered while uploading",
            severity: "error",
          });
          setTimeout(() => setToast(false), 4000);
        }
      });
    } catch (err: any) {
      const msg = err.response.data.description;
      setToast(true);
      setToastMessage({
        message: msg,
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };
  const downloadFilePresignedUrl = async (fileName: string): Promise<void> => {
    try {
      if (typeof document === "undefined") {
        throw new Error("Document is not available");
      }

      var url = `${baseUrl}Document/download?authId=${formData?.id}&fileName=${encodeURIComponent(fileName)}`;

      const response: any = await getApiCall(url);
      const presignedUrl = response.url;

      try {
        // For all file types, use fetch to get the blob
        const fileResponse = await fetch(presignedUrl);
        const blob = await fileResponse.blob();
        // Create blob URL
        const blobUrl = window.URL.createObjectURL(blob);
        // Create link element
        const link = window.document.createElement("a") as HTMLAnchorElement;
        link.href = blobUrl;
        link.download = fileName;
        link.style.display = "none";
        // Add to document, click and remove
        window.document.body.appendChild(link);
        link.click();
        // Cleanup
        setTimeout(() => {
          window.document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
      } catch (downloadError) {
        console.error("Download failed:", downloadError);

        // Fallback method
        const link = window.document.createElement("a") as HTMLAnchorElement;
        link.href = presignedUrl;
        link.download = fileName;
        link.style.display = "none";
        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Download error:", error);
      throw error;
    }
  };

  const handelEditComment = (newRow: any) => {
    const updatedUsers = attestalldocuments?.map((doc: any) => {
      if (doc.fileKey === newRow.fileKey) {
        return { ...doc, comment: newRow.comment };
      }
      return doc;
    });
    dispatch(alldocuments(updatedUsers));
  };

  return (
    <>
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
      <Box sx={{ width: "100%" }}>
        <div className="Container">
          <div className="Grid">
            <DataGridPro
              sx={{
                backgroundColor: "white",
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#00313C",
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
                "& .MuiDataGrid-filterIcon": {
                  color: "white",
                },
              }}
              editMode="cell"
              isCellEditable={(params) => {
                return params.field === 'comment';
              }}
              pagination
              pageSizeOptions={[10, 25, 40, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableColumnMenu={true}
              autoHeight={true}
              hideFooter={attestalldocuments?.length ? false : true}
              className={attestalldocuments?.length ? "" : "table-no-row-found"}
              getRowId={(row) => row.fileKey}
              rows={attestalldocuments ?? []}
              columns={columns}
            />
          </div>
        </div>
      </Box>
      {formData?.isFormEditable || formData?.allowDocumentUpload ? (
        <DialogBox
          openDialogBox={confirmationDialogBox}
          setOpenDialogBox={setConfirmationDialogBox}
          setConfirm={handleDocDelete}
          dialogText={"Are you sure you want to delete the file?"}
        />
      ) : (
        ""
      )}
    </>
  );
}
