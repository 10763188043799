import { Outlet } from "react-router-dom";
import { UserDetails } from "../Authorizations/components/utils/UserDetails";
import { useDocumentTitle } from "../customHooks/useDocumentTitle";

const LandingPageHeaderRoot = (props: any) => {
    useDocumentTitle('GR AM Portal')
    return (
        <>        
            <div className="header">
                <h2 className="app-title">
                    <div className="logo"></div> <span>| Asset Management Portal</span>
                </h2>
                <div className="header-right">
                    <UserDetails accounts={props.accounts} signOutClickHandler={props.signOutClickHandler} />
                </div>
            </div>
            <div className="landingpage-container">
                <Outlet />
            </div>
        </>
    );
};

export default LandingPageHeaderRoot;