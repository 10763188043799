import AccordionTab from "../../AccordianTab";
import AttestFileupload from "../Attestfileupload/AttestFileupload";
import { AttestUploadedDocument } from "../Attestfileupload/AttestUploadedDocument";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import { alldocuments } from "../redux/slices/attestdocumentslice";

const AttestAttachments: React.FC = () => {
  const formData = useAppSelector((state) => state.ivaFormData.ivaFormData);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(alldocuments(formData?.documents));
  }, [formData?.id]);


  return (
    <AccordionTab
      title={"Attachments"}
      children={
        <div>
          {formData?.isFormEditable || formData?.allowDocumentUpload ? (
            <div className="select-tab">
              <AttestFileupload />
            </div>
          ) : null}
          <AttestUploadedDocument document={[]} mode={"edit"} />
        </div>
      }
    ></AccordionTab>
  );
};
export default AttestAttachments;
