import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
  };
}) as typeof Chip;

const AppBreadcrumbs = () => {
  const nav = useNavigate();

  const goToDashboard = () => {
    setTimeout(() => {
      nav("/attestations/invval/dashboard");
    }, 0);
  };

  const ivaformdataid = useAppSelector(
    (state) => state?.ivaFormData?.ivaFormData?.investmentId
  );

  const ivaformdata = useAppSelector((state) => state.ivaFormData);

  var pathName = window.location.pathname;
  const pathnames = pathName.split("/").filter((x) => x);
  const formBreadcrumb1 =
  
     "Quarterly Attestation" 
   
  const formBreadcrumb2 =
    pathnames[0] == null || ivaformdataid == null
      ? " "
      : "/" +
        (!ivaformdata?.loading
          ? ivaformdataid + " - " + "Investment Valuation Change"
          : "");

  const id = pathnames.slice(-1)[0];
  const id2 = pathnames.slice(-2)[0];
  const id3 = pathnames.slice(-3)[0];
  const breadcrumbNameMap: { [key: string]: string } = {
    "/attestations/dashboard": " Dashboards",
    "/attestations/settings": " Settings",
    "/attestations/reports": " Reports",
  };

  breadcrumbNameMap["/attestations/" + "invval" + "/" + id2 + "/" + id] =
    formBreadcrumb2;
  breadcrumbNameMap["/attestations/" + "qa" + "/" + id2 + "/" + id] =
    formBreadcrumb1;

  breadcrumbNameMap["/attestations/settings/qtrlyattestationsfundsectors"] =
    " Settings/ Qtrly Attestations Fund And Sectors";
  breadcrumbNameMap["/attestations/invval/dashboard"] = "Inv Val Dashboard";
  breadcrumbNameMap["/attestations/qa/dashboard"] =
    "Qtrly Attestations Dashboard";
  breadcrumbNameMap["/attestations/reports/attestationreport"] =
    " Reports/Attestation Report";

  if (breadcrumbNameMap[window.location.pathname] === undefined) {
    breadcrumbNameMap[pathName] = " NA";
  }

  return (
    <div role="presentation" className="mt-10 mb-10">
      <Breadcrumbs aria-label="breadcrumb" className="brd">
        <StyledBreadcrumb
          label="Attestations"
          onClick={() => goToDashboard()}
          icon={<HomeIcon fontSize="small" className="hm" />}
        />
        {pathnames.map((name, index) => {
          if (index + 1 == pathnames.length) {
            const goTo = `/${pathnames.slice(0, index + 1).join("/")}`;

            const mappedValue = breadcrumbNameMap[goTo];
            return mappedValue
              .split("/")
              .filter((x) => x)
              .map((n, i) => <StyledBreadcrumb label={n} component="a" />);
          }
        })}
      </Breadcrumbs>
    </div>
  );
};

export default AppBreadcrumbs;
