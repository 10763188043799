import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../../config/Config";
import { getApiCall } from "../../../ApiHelper";
import { RootState } from "../../../redux/store";

type InitialState = {
    tabs: string[] | null,
    error: string,
    loading: boolean
}

const initialState: InitialState = {
    tabs: null,
    error: '',
    loading: false
}

let cacheKey: string | undefined = "";
export const getAttestationsTabs = createAsyncThunk<string[],string|undefined, { state: RootState }>("getAttestationsTabs", async (payload: string | undefined, { getState, rejectWithValue }) => {
    const state = getState().attestationDbTabs;
  
    if (state.tabs!=null && cacheKey !== "") {
      // Return the current data if it exists in the state and cacheKey is set
      return state.tabs;
    }
    if (state.tabs===null && cacheKey === payload) {
      // Return an empty array if there is no data in both the state and the cache
      return {} as string[];
    }
    try {
        var url = `${baseUrl}Dashboard/ivaviews`;
        const response = await getApiCall<string[]>(url);
        cacheKey = payload;
        return response;
    } catch (error: any) {
        console.error('Error in GET request:', error);
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const dashboardTabsSlice = createSlice({
    name: 'attestationDbTabs',
    initialState,
    reducers:  {

       
                resetState: (state) => {
                    state.tabs = [];
                    state.error = "";
                    state.loading = false;
                },
            },
            
    extraReducers: (builder) => {
            builder.addCase(getAttestationsTabs.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAttestationsTabs.fulfilled, (state, action: PayloadAction<string[] | null>) => {
                state.loading = false;
                state.tabs = action.payload;
            })
            .addCase(getAttestationsTabs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
           
    }
});

export const getDashboardTabs = (state:any) => state.attestationDbTabs.tabs

export default dashboardTabsSlice.reducer;
