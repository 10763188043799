import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuditButton, } from "../../Authorizations/interfaces/Interfaces";
import { baseUrl } from "../../config/Config";
import { getApiCall } from "../../ApiHelper";

type InitialState = {
    formData: FormData | null,
    error: string,
    loading: boolean
}

const initialState: InitialState = {
    formData: null,
    error: '',
    loading: false
}

export const getAuditDetails = createAsyncThunk("getAuditDetails", async (payload: any , {rejectWithValue}) => {
    try {
        var url = `${baseUrl}AuditTrail/infobyid?id=${payload}`;
        const response = await getApiCall<FormData>(url);
        return response;
    } catch (error: any) {
        console.error('Error in GET request:', error);
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const newAuditDataSlice = createSlice({
    name: 'newAuditData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
            builder.addCase(getAuditDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAuditDetails.fulfilled, (state, action: PayloadAction<FormData | null>) => {
                state.loading = false;
                state.formData = action.payload;
            })
            .addCase(getAuditDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
    }
});

export const getNewAuditData = (state:any) => state.newAuditData.formData

export default newAuditDataSlice.reducer;