import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useAppSelector } from "../../../redux/hooks";
import moment from "moment";

const AttestHeaderMain = (props: any) => {

  const formData = useAppSelector((state) => state.ivaFormData.ivaFormData);
  return (
    <>
      <Grid container>
        <Grid item xs={12} className="grid-title">
          <div className="header-form-header">
            {formData?.formTitle}
          </div>
        </Grid>
      </Grid>
      <Grid container item xs={12} className="grid-padding">
        <Grid item xs={12}></Grid>
      </Grid>
      <Grid container className="grid-header">
        <Grid container item xs={12}>
          <Grid container item xs={12} md={8}>

          </Grid>
          <Grid container item xs={12} md={4}>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <label>Status</label>
                <span>:</span>
              </Grid>
              <Grid item xs={12} md={6}>
                {formData?.status}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <label>Created On</label>
                <span>:</span>
              </Grid>
              <Grid item xs={12} md={6}>
                {formData?.ivaCreatedOn
                  ? moment(formData?.ivaCreatedOn).format(
                    "MMM-DD-YYYY hh:mm A"
                  )
                  : ""}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <label>Submitted By</label>
                <span>:</span>
              </Grid>
              <Grid item xs={12} md={6} className="grid-text-wrap">
                  {formData?.submittedByName}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AttestHeaderMain;
