import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useAppDispatch } from "../redux/hooks";
import { attestgetUserData } from "../Attestations/redux/slices/attestuserContextDetailslice";
import { useMsal } from "@azure/msal-react";
import { getUserData } from "../redux/slices/userContextDetailSlice";

// Fixed TypeScript definitions for styled components
const StyledCard = styled(Card)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease-in-out",
  cursor: "pointer",
  background: "rgba(255, 255, 255, 0.9)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.2)",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 20px 40px rgba(0, 0, 0, 0.1)",
  },
}));

interface GradientButtonProps {
  color1: string;
  color2: string;
}

const GradientButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "color1" && prop !== "color2",
})<GradientButtonProps>(({ color1, color2 }) => ({
  background: `linear-gradient(45deg, ${color1}, ${color2})`,
  color: "white",
  padding: "12px 24px",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
  },
}));

interface IconWrapperProps {
  bgcolor: string;
}

const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bgcolor",
})<IconWrapperProps>(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor,
  borderRadius: "50%",
  padding: theme.spacing(2),
  display: "inline-flex",
  marginBottom: theme.spacing(2),
  "& svg": {
    fontSize: "2.5rem",
    color: "white",
  },
}));

const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accounts, instance } = useMsal();

  useEffect(() =>
  {
    localStorage.removeItem("dashBoardPayload");

  }, [])
  const applications = [
    {
      title: "Authorizations",
      description:
        "Manage and track authorization requests efficiently with our comprehensive authorization management system",
      path: "/authorizations/dashboard",
      icon: <BusinessCenterIcon />,
      colors: {
        primary: "#253746",
        secondary: "#253746",
        iconBg: "#253746",
      },
    },
    {
      title: "Attestations",
      description:
        "Streamline your attestation process with our intuitive and powerful attestation management platform",
      
      path: "/attestations/invval/dashboard",
      icon: <AssignmentIcon />,
      colors: {
        primary: "#253746",
        secondary: "#253746",
        iconBg: "#253746",
      },
    },
  ];

  const handleClick = (app: any) => {
    if (app.path.startsWith("/attestations")) {
      dispatch(attestgetUserData());
    } else {
      dispatch(getUserData(accounts[0].username));
    }
    navigate(app.path);

  };

  return (
    <div className="landingpage-container">
      <Box textAlign="center" mb={8}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontWeight: 300,
            background: "#253746",
            backgroundClip: "text",
            textFillColor: "transparent",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            mb: 3,
          }}
        >
          Welcome to Asset Management Portal
        </Typography>
        <Typography
          variant="h5"
          sx={{
            maxWidth: "600px",
            margin: "0 auto",
            color: "#253746",
          }}
        >
          Choose your application to get started
        </Typography>
      </Box>

      <Grid container className="wrapper" spacing={6} justifyContent="center">
        {applications.map((app, index) => (
          <Grid item xs={12} md={6} key={index}>
            <StyledCard onClick={() => handleClick(app)}>
              <CardContent sx={{ p: 4, textAlign: "center" }}>
                <IconWrapper bgcolor={app.colors.iconBg}>
                  {app.icon}
                </IconWrapper>
                <Typography
                  variant="h4"
                  component="h2"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  {app.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    mb: 3,
                    lineHeight: 1.6,
                  }}
                >
                  {app.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "center", pb: 4 }}>
                <GradientButton
                  variant="contained"
                  size="large"
                  color1={app.colors.primary}
                  color2={app.colors.secondary}
                >
                  Launch  {app.title}
                </GradientButton>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default LandingPage;
