import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../../config/Config";
import { getApiCall } from "../../../ApiHelper";
import { IvaFormData } from "../../Interfaces/AttestInterfaces";

type InitialState = {
    ivaFormData: IvaFormData | null,
    error: string,
    loading: boolean
}

const initialState: InitialState = {
    ivaFormData: null,
    error: '',
    loading: false
}

export const getIvaFormData = createAsyncThunk("getIvaFormData", async (payload: string, { rejectWithValue }) => {
    try {
        var url = `${baseUrl}Attestations/getIVAForm?id=${payload}`;
        const response = await getApiCall<IvaFormData>(url);
        return response;
    } catch (error: any) {
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})` || error.message);
    }
})

const ivaFormDataSlice = createSlice({
    name: 'ivaFormData',
    initialState,
    reducers: {
        resetState: (state) => {
            state.ivaFormData = {} as IvaFormData;
            state.error = "";
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getIvaFormData.pending, (state) => {
            state.loading = true;
        })
            .addCase(getIvaFormData.fulfilled, (state, action: PayloadAction<IvaFormData | null>) => {
                state.loading = false;
                state.ivaFormData = action.payload;
            })
            .addCase(getIvaFormData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
    }
});

export const { resetState: resetInvestmentDataStateAction } = ivaFormDataSlice.actions;
export default ivaFormDataSlice.reducer;

