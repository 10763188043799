import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
} from "@mui/material";
import {
  DataGridPro,
  GridCellParams,
  GridLoadingOverlay,
  GridRenderHeaderFilterProps,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddIcon from "@mui/icons-material/Add";
import dayjs, { Dayjs } from "dayjs";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import fundsemailcolumn from "./fundsemailcolumn.json"
import { toUpper } from "lodash";
import * as XLSX from "xlsx";
import { LoadingButton } from "@mui/lab";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Fundsdatagrid = (props: any) => {
  const [rowData, setRowData] = useState<any>();
  const [headingFilters, setHeadingFilters] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [excelLoading, setExcelLoading] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [filterModelState, setFilterModelState] = useState<any>();

  useEffect(() => {
    setRowData(props.rowdata);
    if (filterModelState?.items !== undefined) {
      filterData(filterModelState);
    }
    setHeadingFilters({
      createdOn: props.gridDropDown.CreatedOn,
      createdBy: props.gridDropDown.CreatedBy,
      updatedBy: props.gridDropDown.UpdatedBy,
      updatedAt: props.gridDropDown.UpdatedAt,
      fundName: props.gridDropDown.fundName,
      authName: props.gridDropDown.authName,
    });
  }, [props.rowdata]);

  const CustomTextHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    const { colDef, inputRef } = params;
    return (
      <TextField
        id="standard-basic"
        variant="standard"
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        key={props.selectedTab || props.isActive}
        onChange={(event) => {
          handleFilterChange(event.target.value, params.item.field);
        }}
        InputProps={{
          ref: inputRef
        }}
      />
    );
  };

  const dropDownFiltering = (value: Array<string>, field: string, filteredRows: any) => {
    var filteredDropdownData: any = [];

    if (field === "fund") {
      value.forEach((selectedDropdown: string) => {
        var filteredData = filteredRows.filter((fundRow: any) => fundRow[field] !== undefined && (toUpper(fundRow[field]).includes(toUpper(selectedDropdown))));
        console.log(filteredDropdownData);

        filteredData.forEach((perFilterd: any) => {
          var isIncluded = false;
          filteredDropdownData.forEach((element: any) => {
            if (element.id === perFilterd.id) {
              isIncluded = true;
            }
          });

          if (!isIncluded) {
            filteredDropdownData.push(perFilterd);
          }

        });
      });
    } else {
      value.forEach((selectedDropdown: string) => {
        var filteredData = filteredRows.filter((fundRow: any) => fundRow[field] !== undefined && (toUpper(fundRow[field]) === toUpper(selectedDropdown)));

        filteredData.forEach((perFilterd: any) => {
          filteredDropdownData.push(perFilterd);
        });
      });
    }

    return filteredDropdownData;
  }
  const handleFilterChange = (e: any, field: string) => {
    var value = e;
    var fieldName = field;
    var filterModel = {
      items: [],
    };
    setSelectedOptions({
      ...selectedOptions,
      ...{
        [fieldName]: value,
      },
    });
    filterModel.items = {
      ...selectedOptions,
      ...{
        [fieldName]: value,
      },
    };
    filterData(filterModel);
    setFilterModelState(filterModel);
  };

  const filterData = (filterModel: any) => {
    var filteredRowData: any = props.rowdata;
    for (const key of Object.keys(filterModel.items)) {
      switch (typeof filterModel.items[key]) {
        case "object":
          if (filterModel.items[key].length !== 0) {
            filteredRowData = dropDownFiltering(
              filterModel.items[key],
              key,
              filteredRowData
            );
          } else {
          }

          break;
        case "string":
          if (filterModel.items[key].length !== 0) {
            filteredRowData = filteredRowData?.filter(
              (fundRow: any) =>
                fundRow[key] !== undefined &&
                toUpper(fundRow[key]).includes(toUpper(filterModel.items[key]))
            );
          } else {
          }
          break;
        default:
          break;
      }
    }
    setRowData(filteredRowData);
  };

  const CustomDropDownHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    var columnFilterList = headingFilters[params.item.field] || [];
    const { colDef, inputRef } = params;

    return (
      <Autocomplete
        className="autocomplete-root"
        ListboxProps={{
          className: "autocomplete-dashboard"
        }}
        multiple
        limitTags={1}
        id="tags-standard"
        onChange={(event, newInputValue) => {
          handleFilterChange(newInputValue, params.item.field);
        }}
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        options={columnFilterList}
        disableCloseOnSelect
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option}
          </li>
        )}
        fullWidth
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    );
  };

  const columns: any = fundsemailcolumn.colDef.map((colDef: any) => {
    let { valueFormatter, ...rest } = colDef;
    if (
      colDef.field === "createdBy" ||
      colDef.field === "createdOn" ||
      colDef.field === "updatedBy" ||
      colDef.field === "updatedAt" ||

      colDef.field === "fundName"
    ) {
      return {
        ...rest,
        renderHeaderFilter: CustomDropDownHeaderFilter,
      };
    }
    else
      if (colDef.field === "authName") {
        return {
          ...rest,
          renderHeaderFilter: CustomDropDownHeaderFilter,
          cellClassName: (params: GridCellParams<any, number>) => {
            if (!params.value) {
              return "project-name-blank";
            }
            return "project-name-cell";
          },
        };
      }
      else {
        return {
          ...rest,
          renderHeaderFilter: CustomTextHeaderFilter,
        };
      }
  });
  const handleCellClick = (params: any) => {
    if (params.field === "authName") {
      props.updateRow({
        id: params.row.id,
        fundName: params.row.fundName.split(",").map((item: string) => item.trim()),
        authorizationType: {
          name: params.row.authCode,
          value: params.row.authName,
        },
        emails: params.row.emails,
        isActive: params.row.isActive === "Yes"

      });
      props.type("Edit");
      props.open(true);
      props.addApproverdropdown()

    }
  };
  const exportExcel = () => {
    setExcelLoading(true);
    const data = rowData.map((row: any) =>
      fundsemailcolumn.colDef.map((col) => row[col.field] || "")
    );
    const worksheet: any = XLSX.utils.aoa_to_sheet([
      fundsemailcolumn.colDef.map((col) => col.headerName),
      ...data,
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FundsEmail");
    const now = dayjs();
    const hour = now.format("HH");
    const minute = now.format("mm");
    const day = now.format("DD");
    const month = now.format("MM");
    const year = now.format("YYYY");
    const seconds = now.format("ss");
    const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;
    XLSX.writeFile(workbook, `fundsEmail${timestamp}.xlsx`);
    setExcelLoading(false);
  };

  console.log(rowData, "rowdata");
  return (
    <>
      <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          {" "}
          <Button
            variant="contained"
            className="primary"
            onClick={() => {
              props.addApproverdropdown();
              props.type("Add");
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Add
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton variant="contained" loading ={excelLoading} className="primary" onClick={exportExcel}>
            <FileDownloadOutlinedIcon sx={{ mr: 1 }} />
            EXCEL
          </LoadingButton>
        </Grid>
      </Grid>
      <Box className="dashboard-table-wrapper">
        <div style={{ width: "100%" }}>
          <DataGridPro
            className="dashboard-dataGrid"
            slots={{
              loadingOverlay: GridLoadingOverlay,
            }}
            sx={{
              pointerEvents: props.loading ? 'none' : 'auto',
              opacity: props.loading ? 0.7 : 1,
            }}
            getRowId={(row) => row.id}
            rows={rowData ?? []}
            columns={columns}
            headerFilters
            columnHeaderHeight={48}
            headerFilterHeight={48}
            pageSizeOptions={[25, 50, 100]}
            rowHeight={40}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            loading={props.loading}
            pagination
            disableColumnMenu={true}
            autoHeight
            onCellClick={handleCellClick}
            hideFooter={false}
          />
        </div>
      </Box>
    </>
  );
};

export default Fundsdatagrid;
