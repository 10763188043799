import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { getNewFormInfo } from "../../../redux/slices/newFormDataSlice";

import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  getInvdetailsdata,
  getinvdetails,
} from "../../../redux/slices/createAuthSlice";
import {
  InvAuthDetails,
  getAuthorizationRequest,
} from "../../interfaces/Interfaces";
import { useNavigate } from "react-router-dom";
import { getNewFormData } from "../../../redux/slices/newFormDataSlice";
import { authData, resetAuthDataStateAction } from "../../../redux/slices/authDataSlice";
import { resetAuthorizationDataStateAction } from "../../../redux/slices/authorizationDataSlice";
import { resetBudgetedDataStateAction } from "../../../redux/slices/budgetedDataSlice";
import { resetNonBudgetedDataStateAction } from "../../../redux/slices/nonBudgetedDataSlice";
import { resetprojectPropertyDetailDataStateAction } from "../../../redux/slices/propertyProjectDetailSlice";
import { resetSubmitFormErrorStateAction } from "../../../redux/slices/submitFormErrorSlice";
import { resetProposalDataStateAction } from "../../../redux/slices/proposalSlice";
import { resetleaseProjectDetailStateAction } from "../../../redux/slices/leaseProjectDetailSlice";
import { resetJVReasonForAuthStateAction } from "../../../redux/slices/jvAuthReasonSlice";
import { resetModprojectDetailDataStateAction } from "../../../redux/slices/modProjectDetailSlice";
import { LoadingButton } from "@mui/lab";
import { resetFormDataStateAction } from "../../../redux/slices/getFormDataSlice";

interface CreateAuthProps {
  open: boolean;
  handleclose: () => void;
  setAuthId: string;
}

const CreateAuth: React.FC<CreateAuthProps> = (props: any) => {
  const [selectedAuth, setSelectedAuth] = useState<string>();
  const [reditectAuth, setReditectAuth] = useState<string>();
  const [fundName, setFundName] = useState<string>();
  const [invId, setInvID] = useState<string>();

  const payload: getAuthorizationRequest = {
    invId: invId as string,
    authType: selectedAuth as string,
    fundName: fundName as string,
  };
  const data = useAppSelector(getInvdetailsdata);
  const AuthData = useAppSelector(getNewFormData);
  const drpdownLoading = useAppSelector((state) => state.invauthdetails.loading);
  const AuthDataLoading = useAppSelector((state) => state.newFormData.loading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clearState = () => {
    dispatch(resetSubmitFormErrorStateAction());
    dispatch(resetBudgetedDataStateAction());
    dispatch(resetNonBudgetedDataStateAction());
    dispatch(resetAuthorizationDataStateAction());
    dispatch(resetprojectPropertyDetailDataStateAction());
    dispatch(resetAuthDataStateAction());
    dispatch(resetProposalDataStateAction());
    dispatch(resetleaseProjectDetailStateAction());
    dispatch(resetJVReasonForAuthStateAction());
    dispatch(resetModprojectDetailDataStateAction());
    dispatch(resetFormDataStateAction());
  }

  const SubmitButton = async () => {
    clearState();
    dispatch(getNewFormInfo(payload));

  };

  useEffect(() => {
    if (AuthData?.Id) {
      
  navigate(`/authorizations/form/${AuthData?.Id}`,{state : {parentComponent: AuthData?.ParentComponent}});
      props.handleclose();
      setFundName(undefined);
      setInvID(undefined);
      setSelectedAuth(undefined);
    }
  }, [AuthData?.Id]);

  const handelClose = () => {
    props.handleclose();
    setFundName(undefined);
    setInvID(undefined);
    setSelectedAuth(undefined);
  }



  const disabledbutton = !(invId && fundName && selectedAuth)
  return (
    <div>

      <Dialog open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dashboard-dialog-box">
        <DialogTitle>Create Authorizations</DialogTitle>

        <DialogContent>
          <Autocomplete
            options={
              data
                ? Object.keys(data.authTypes)?.map((key) => ({
                  name: key,
                  value: data?.authTypes[key],
                }))
                : []
            }
            getOptionLabel={(option) => option?.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Authorizations Type"
              />
            )}
            sx={{ marginTop: 2 }}
            onChange={(event: any, newValue: any) => {
              setSelectedAuth(newValue?.name);
              setReditectAuth(newValue?.value)
            }}
            loading={drpdownLoading}
          />
          <Autocomplete
            options={
              data
                ? data.invs?.map((key: any) => ({
                  key,
                }))
                : []
            }
            getOptionLabel={(option) => option.key.invName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="   Investment ID"
              />
            )}
            sx={{ marginTop: 2 }}
            onChange={(event: any, newValue: any) => {
              setFundName(newValue?.key?.fundName);
              setInvID(newValue?.key?.invId);
            }}
            loading={drpdownLoading}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={disabledbutton}
            onClick={SubmitButton}
            variant="contained"
            className="upload-btn"
            loading={AuthDataLoading}
          >
            Create
          </LoadingButton>
          <Button className="tertiary" onClick={handelClose}>Cancel</Button>
        </DialogActions>


      </Dialog>

    </div>





  );
};
export default CreateAuth;
