import React, { useEffect, useState } from "react";
import FundsDataGrid from "./Verbiagedatagrid";
import { ThemeProvider } from "styled-components";
import { baseUrl, theme } from "../../../../config/Config";
import { getApiCall, postApiCall } from "../../../../ApiHelper";
import Verbiagedatagrid from "./Verbiagedatagrid";
import AddDialog from "./VerbiageDialogBox";
import { Button, Grid } from "@mui/material";
import VerbiageDialogBox from "./VerbiageDialogBox";
import { verbiageProjectResponse } from "../../../interfaces/Interfaces";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { toast } from "../../../interfaces/Interfaces";
import { ToastMessage } from "../../../components/utils/ToastMessage";

function Fund() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [rowData, setRowData] = useState<any>();
  const [fundNameDropdown, setFundNameDropdown] = useState<any>();
  const [authorizationDropDown, setauthorizationDropDown] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [authorization, setAuthorization] = useState<any>();
  const [fund, setFundData] = useState<any>();
  const [verbiage, setVerbiageData] = useState<any>();
  const [code, setcode] = useState<any>();
  const [id, setid] = useState<any>();
  const [authType, setAuthType] = useState<any>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [adHocDropdown, setAdHocDropdown] = useState<any>();
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [createdon, setCreatedOn] = useState<any>()
  const [createdby, setCreatedBy] = useState<any>()
  const [updatedby, setUpdatedBy] = useState<any>()
  const [updatedat, setUpdatedAt] = useState<any>()
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [updateData, setUpdateData] = useState<verbiageProjectResponse>({
    fundName: [],
    id: null,
    detailText: "",
    authorizationType: {
      name: "",
      value: "",
    },
    isActive: true

  });

  useEffect(() => {
    if (submitClicked) {
      getFundsData();
    }
  }, [submitClicked]);

  useEffect(() => {
    getFundsData();
  }, []);

  useEffect(() => {
    if (!openDialog) {
      setUpdateData({
        fundName: [],
        id: null,
        detailText: "",
        authorizationType: {
          name: "",
          value: "",
        },
        isActive: true

      });
    }
  }, [openDialog]);

  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
    setTimeout(() => setToast(false), 4000);
  };

  const getFundsData = async () => {
    var url = `${baseUrl}authverbiage/details`;
    try {
      setLoading(true);
      const res = await getApiCall(url).then((response: any) => {
        const formattedRows = response?.verbiages?.map((row: any) => ({
          ...row,
          isActive: row?.isActive ? "Yes" : "No",
        }));
        setRowData(formattedRows);
        const extractedvalues = Object.values(response?.authTypes);
        const extractedcodes = Object.keys(response?.authTypes);
        setAuthType(response?.authTypes);
        setcode(extractedcodes);
        const verbiagdata = response?.verbiages.map(
          (item: any) => item.detailText
        );
        setAuthorization(extractedvalues);
        setFundData(response?.fundNames);
        setCreatedOn(response?.createdOn)
        setCreatedBy(response?.createdBy)
        setUpdatedBy(response?.updatedBy)
        setUpdatedAt(response?.updatedAt)
        setVerbiageData(verbiagdata);
        const extractedid = response?.id;
        console.log(extractedid, "id");
        setid(extractedid);
        setSubmitClicked(false);
        setLoading(false);
        setFundNameDropdown(response.fundNames);
        const extractdropdown = Object.values(response?.authTypes);
        setauthorizationDropDown(extractdropdown);
      });
    } catch (error) {
      setLoading(false);
      errorToastMsgwithCode(error);
      console.log("error in fetching funds", error);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <Grid item lg={4}>
          <h2 className="text-left mstitle"> Authorizations Verbiage</h2>
        </Grid>

        {openDialog ? (
          <VerbiageDialogBox
            open={openDialog}
            setOpen={setOpenDialog}
            authdata={authorization}
            fundNames={fund}
            verbiage={verbiage}
            code={code}
            id={id}
            authtype={authType}
            updateRow={updateData}
            adHocDropdown={adHocDropdown}
            type={type}
            submitClicked={setSubmitClicked}
          />
        ) : null}
        <Verbiagedatagrid
          rowdata={rowData}
          loading={loading}
          fundNameDropdown={fundNameDropdown}
          createdropdown={createdby}
          authorizationDropDown={authorizationDropDown}
          createdondropdown={createdon}
          updatedbydropdown={updatedby}
          updatedatdropdown={updatedat}
          updateRow={setUpdateData}
          open={setOpenDialog}
          setOpen={setOpenDialog}
          id={id}
          type={setType}
        />
        {toast && (
          <ToastMessage
            message={toastMessage.message}
            severity={toastMessage.severity}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export default Fund;
