import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridFilterModel,
  GridHeaderFilterCellProps,
  GridLoadingOverlay,
  GridPaginationModel,
  GridRenderHeaderFilterProps,
  GridSortModel,
  useGridApiContext,
} from "@mui/x-data-grid-pro";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { debounce } from "lodash";
import _debounce from 'lodash/debounce';
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { AttestationService } from "../Services/AttestationsDashboardService";
import attestdashboardviews from "./attestationDashboardViews.json"
import { ToastMessage } from "../../Authorizations/components/utils/ToastMessage";
import { dashboardDropdownData } from "../redux/slices/dashboardDropdownSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getuserdetailsdata } from "../../redux/slices/userContextDetailSlice";
import { resetmaterialchangeStateAction } from "../redux/slices/MaterialChangeDataSlice";
import { resetSubmitFormErrorStateAction } from "../redux/slices/attestsubmitforerrorslice";
import { resetInvestmentDataStateAction } from "../redux/slices/IvaFormDataslice";
import { baseUrl } from "../../config/Config";
import { postApiCall } from "../../ApiHelper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const localStoragePayload = "attestationsDashBoardPayload";

const AttestationDashboardDataGrid = (props: any) => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [rows, setRows] = useState<any>([]);
  const [headingFilters, setHeadingFilters] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rowCount, setRowCount] = useState<number>(0);
  const attestationService = new AttestationService();
  const [valueDb, setValueDb] = useState<any>({});

  const [storedTab, setStoredTab] = useState<any>();
  const [isActive, setIsActive] = useState<any>({
    Value: true,
  });


  const [excelDownloading, setExcelDownloading] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [openRestorePopup, setOpenRestorePopup] = React.useState(false);
  const [deleteattestation, setDeleteattestation] = React.useState({
    id: "",
    isDelete: false,
  });

  const [restoreattestation, setRestoreattestation] = React.useState({
    id: "",
    isDelete: true,
  });

  const [toast, setToast] = useState(false);

  const [toastMessage, setToastMessage] = useState({
    message: "",
    severity: "",
  });

  const userdata = useAppSelector(getuserdetailsdata);

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  const handleClickOpenDeletePopup = (props: any) => {
    setDeleteattestation({
      id: props.formID,
      isDelete: true,
    });
    setOpenDeletePopup(true);
  };

  const handleCloseRestorePopup = () => {
    setOpenRestorePopup(false);
  };

  const handleClickOpenRestorePopup = (props: any) => {
    setRestoreattestation({
      id: props.formID,
      isDelete: false,
    });
    setOpenRestorePopup(true);
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: pageSize,
  });

  const [sortedModel, setSortedModel] = useState({
    ascSort: false,
    sortedColumnName: "",
  });

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });

  const [selectedTab, setSelectedTab] = useState("");

  const filterDropdown = useAppSelector(
    (state: { dashBoardDropdown: { data: any } }) => {
      return state.dashBoardDropdown.data;
    }
  );

  const cleanFilterStates = () => {
    setValueDb({});
    setFilterModel({
      items: [],
    });
    setPaginationModel({
      page: 0,
      pageSize: pageSize,
    });
  }

  useEffect(() => {
    if (props.isActive != undefined && props.isActive != "") {
      setPaginationModel({
        page: 0,
        pageSize: pageSize,
      });
      isActive.Value = props.isActive;
      localStorage.setItem("attestInvType", JSON.stringify({ isActive: props.isActive }));
      if (props.selectedTab.length !== 0) {
        fetchData(paginationModel, { items: [] }, true);
      }
      setValueDb({});
    }

  }, [props.isActive]);

  useEffect(() => {
    var storedfilterItems: any = [];
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");
    var storedPage = localStorageDashboardPayload.paginationSet?.page;
    var storedPageSize = localStorageDashboardPayload.paginationSet?.pageSize;

    if (Object.keys(localStorageDashboardPayload).length !== 0) {
      setValueDb(localStorageDashboardPayload.filterItems);
      setPageSize((storedPageSize !== undefined && storedPageSize !== null) ? storedPageSize : 10);
      setPaginationModel({
        page: (storedPage !== undefined && storedPage !== null) ? storedPage : 0,
        pageSize: (storedPageSize !== undefined && storedPageSize !== null) ? storedPageSize : 10,
      });
      storedfilterItems = localStorageDashboardPayload.filterItems
    } else {
      cleanFilterStates();
    }

    if (selectedTab !== props.selectedTab) {

      setSelectedTab(props.selectedTab)
      fetchData({
        page: (storedPage !== undefined && storedPage !== null) ? storedPage : 0,
        pageSize: (storedPageSize !== undefined && storedPageSize !== null) ? storedPageSize : 10,
      }, { items: storedfilterItems }, false);
    }
  }, [props.selectedTab]);


  const handleCellClick = (params: any) => {

    dispatch(resetInvestmentDataStateAction())
    dispatch(resetSubmitFormErrorStateAction())
    dispatch(resetmaterialchangeStateAction())

    if (params.field === "invValChangeName") {
      
      nav(`/attestations/invval/form/${params.row.formID}`)
    }
  };

  const handelPaginationModel = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel);
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");

    if (Object.keys(localStorageDashboardPayload).includes("filterItems")) {
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, selectedTab: props.selectedTab, paginationSet: paginationModel }));
      fetchData(paginationModel, { items: localStorageDashboardPayload.filterItems }, false);
    } else {
      localStorage.setItem(localStoragePayload, JSON.stringify({ filterItems: { invValChangeName: "" }, selectedTab: props.selectedTab, paginationSet: paginationModel }));
      fetchData(paginationModel, filterModel, false);
    }
  };

  const handelSortModelChange = (sortModel: GridSortModel) => {
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");
    if (sortModel.length == 0) {
      sortedModel.sortedColumnName = "";
      sortedModel.ascSort = false;
    } else {
      sortedModel.sortedColumnName = sortModel[0].field;
      if (sortModel[0].sort == "asc") {
        sortedModel.ascSort = true;
      } else {
        sortedModel.ascSort = false;
      }
    }

    if (Object.keys(localStorageDashboardPayload).includes("filterItems")) {
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, selectedTab: props.selectedTab }));
      fetchData(paginationModel, { items: localStorageDashboardPayload.filterItems }, false);
    } else {
      localStorage.setItem(localStoragePayload, JSON.stringify({ filterItems: { invValChangeName: "" }, selectedTab: props.selectedTab }));
      fetchData(paginationModel, filterModel, false);
    }
  };

  const fetchData = async (
    paginationModel: GridPaginationModel,
    filterModel: GridFilterModel,
    activeAll: boolean
  ) => {
    setLoading(true);

    await GetAttestations(paginationModel, filterModel, false, activeAll)
      .then((data: any) => {

        const formattedRows = data?.data.map((row: any) => ({
          ...row,
          updatedAt: row?.updatedAt !== undefined ? moment(row?.updatedAt).format("MMM-DD-YYYY hh:mm A") : undefined
        }))
        setRows(formattedRows);
        if (data?.dropdownValues) {
          var dropdownValues = { ...data?.dropdownValues };

          dispatch(dashboardDropdownData(dropdownValues));
          setHeadingFilters(dropdownValues);
          setStoredTab(props.selectedTab);
        } else {
          setHeadingFilters(filterDropdown);
        }
        if (data?.rowCount >= 0) {
          setRowCount(data?.rowCount);
        }
      })
      .catch((error: any) => {
        errorToastMsgwithCode(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportExcel = async () => {
    setExcelDownloading(true);
    try {
      const dashboardData = await GetAttestations(paginationModel, { items: valueDb }, true, false);

      // Fetch the data from the presigned URL

      const response = await fetch(dashboardData.url);
      const blobData = await response.blob();
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = url;
      link.download = response.url.split("/dashboard/")[1].split("?")[0].split(".")[0].toString() + ".xls";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);


      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 0);
    } catch (error: any) {
      console.log(error);
    } finally {
      setExcelDownloading(false);
    }
  };

  const GetAttestations = async (
    paginationModel: GridPaginationModel,
    filterModel: GridFilterModel,
    isExcel: boolean,
    activeAll: boolean
  ) => {

    var viewName = props.selectedTab;
  
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");


    if (Object.keys(localStorageDashboardPayload).length !== 0) {
      viewName = localStorageDashboardPayload.selectedTab
    }

    var columnFilters: any | null = null;
    columnFilters = filterModel?.items;


    const dashBoardPayload: any = {
      //mailId: currentUserEmail?.toLowerCase(),
      isActive: isActive.Value === "true",
      viewName: viewName,
      columnFilters: columnFilters.length === undefined ? columnFilters : null,
      pagesize: paginationModel.pageSize,
      pageNumber: paginationModel.page,
      sortedColumnName: sortedModel.sortedColumnName,
      ascSort: sortedModel.ascSort,
      isDropdownRequired: activeAll || props.selectedTab !== storedTab,
      isForExcel: isExcel
    };

    if (isExcel) {
      return await attestationService
        .FetchDashboardAttestationsExcel(dashBoardPayload)
        .then((data: any) => {
          return data;
        });
    }

    return await attestationService
      .FetchDashboardAttestations(dashBoardPayload)
      .then((data: any) => {
        return data;
      });
  };

  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
  };
  const handleDeleteattestation = async () => {
    var url = `${baseUrl}Attestations/deleteorrestore`;
    const deletePayload = {
      id: deleteattestation.id,
      isDelete: true,
    };
    try {
      const res = await postApiCall(url, deletePayload);
      setRows(
        rows.filter((item: any) => item.formID !== deleteattestation.id)
      );
      setPaginationModel({
        page: 0,
        pageSize: pageSize,
      });
      setRowCount(rowCount - 1);
      setToast(true);
      setToastMessage({
        message: "Attestation deleted sucessfully",
        severity: "success",
      });
      setTimeout(() => setToast(false), 4000);
      handleCloseDeletePopup();
    } catch (error) {
      errorToastMsgwithCode(error)
    }
  };

  const handleRestore = async () => {
    var url = `${baseUrl}Attestations/deleteorrestore`;
    const restorePayload = {
      id: restoreattestation.id,
      isDelete: false,
    };
    try {
      const res = await postApiCall(url, restorePayload);
      setRows(
        rows.filter((item: any) => item.formID !== restoreattestation.id)
      );
      setPaginationModel({
        page: 0,
        pageSize: pageSize,
      });
      setRowCount(rowCount - 1);

      setLoading(false);
      setToast(true);
      setToastMessage({
        message: "Attestation restored sucessfully",
        severity: "success",
      });
      setTimeout(() => setToast(false), 4000);
      handleCloseRestorePopup();
    } catch (error) {
      errorToastMsgwithCode(error)
    }
  };

  const sendDashboardRequest = () => {
    filterModel.items = valueDb;
    paginationModel.page = 0;
    setFilterModel(filterModel);
    fetchData(paginationModel, filterModel, false);
  };

  const ref = useRef(sendDashboardRequest);

  useEffect(() => {
    ref.current = sendDashboardRequest;
  }, [valueDb]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);

  const handleDropdownFilterChange = (field: any, value: any, drpDownFilters: any) => {
    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");

    if (field.toString() === "updatedAt") {
      setValueDb({ ...valueDb, [field]: value.map((item: any) => moment(item).format("YYYY-MM-DDTHH:mm:ss")) });
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, filterItems: { ...valueDb, [field]: value.map((item: any) => moment(item).format("YYYY-MM-DDTHH:mm:ss")) }, selectedTab: props.selectedTab, paginationSet: paginationModel }));
    } else {
      setValueDb({ ...valueDb, [field]: value });
      localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, filterItems: { ...valueDb, [field]: value }, selectedTab: props.selectedTab, paginationSet: paginationModel}));
    }

    debouncedCallback();
  }

  const CustomDropDownHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    const { colDef, inputRef } = params;
    let fieldName = params.item.field;

    var columnFilterList = headingFilters[params.item.field] || [];

    var storedfilterItems: any = [];
    var filterValue = [];

    const NAME_FIELDS_MAP: any = {
      assetManagerName: true,
      sectorHeadName: true,
      portfolioManagerName: true,
      assetManagerHeadName: true,
      createdByName: true
    };

    if (NAME_FIELDS_MAP[fieldName] && headingFilters.length !== 0) {
      columnFilterList = Object.values(headingFilters[params.item.field]) || [];
    }

    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");

    if (Object.keys(localStorageDashboardPayload).length !== 0) {
      storedfilterItems = localStorageDashboardPayload.filterItems
      if (Object.keys(storedfilterItems).includes("updatedAt") && colDef.field == "updatedAt") {
        filterValue = localStorageDashboardPayload.filterItems[colDef.field].map((item: any) => moment(item).format("MMM-DD-YYYY"));
      } else if (Object.keys(storedfilterItems).includes(colDef.field)) {
        filterValue = localStorageDashboardPayload.filterItems[colDef.field];
      }
    }

    if (params.item.field === "updatedAt") {
      columnFilterList = columnFilterList.map((item: any) => moment(item).format("MMM-DD-YYYY"));
    }

    return (
      <Autocomplete
        className="autocomplete-root"
        ListboxProps={{
          className: "autocomplete-dashboard"
        }}
        multiple
        key={props.selectedTab || props.isActive}
        limitTags={1}
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        id="tags-standard"
        onChange={(event, newInputValue) => {
          handleDropdownFilterChange(params.item.field, newInputValue, headingFilters);
        }}
        value={filterValue}
        options={columnFilterList}
        disableCloseOnSelect
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option}

          </li>
        )}
        fullWidth
        renderInput={(params) => <TextField  {...params} variant="standard" />}
      />
    );
  };

  const CustomTextHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    const { colDef, inputRef } = params;
    var storedfilterItems: any = [];
    var filterValue = "";

    const localStorageDashboardPayload = JSON.parse(localStorage.getItem(localStoragePayload) || "[]");

    if (Object.keys(localStorageDashboardPayload).length !== 0) {
      storedfilterItems = localStorageDashboardPayload.filterItems

      if (Object.keys(localStorageDashboardPayload.filterItems).includes(params.colDef.field)) {
        filterValue = localStorageDashboardPayload.filterItems[params.colDef.field];
      }
    }

    return (
      <TextField
        id="standard-basic"
        variant="standard"
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        key={props.selectedTab || props.isActive}
        onChange={(event) => {
          setValueDb({ ...valueDb, [params.item.field]: event.target.value });
          debouncedCallback();
          localStorage.setItem(localStoragePayload, JSON.stringify({ ...localStorageDashboardPayload, filterItems: { ...valueDb, [params.item.field]: event.target.value }, selectedTab: props.selectedTab }));
        }}
        value={filterValue}
        InputProps={{
          ref: inputRef
        }}
      />
    );
  };

  const columns: any = attestdashboardviews.colDef.map((colDef: any) => {

    let { valueFormatter, ...rest } = colDef;
    if (
      colDef.field === "fundName" ||
      colDef.field === "invId" ||
      colDef.field === "yearQuarter" ||
      colDef.field === "assetManagerName" ||
      colDef.field === "sectorHeadName" ||
      colDef.field === "portfolioManagerName" ||
      colDef.field === "status" ||
      colDef.field === "assetManagerHeads" ||
      colDef.field === "assetManagerHeadName" ||
      colDef.field === "createdByName" ||
      colDef.field === "updatedAt"
    ) {
      return {
        ...rest,
        renderHeaderFilter: CustomDropDownHeaderFilter,
      };
    } else {
      if (colDef.field === "invValChangeName") {
        return {
          ...rest,
          renderHeaderFilter: CustomTextHeaderFilter,
          cellClassName: (params: GridCellParams<any, number>) => {
            if (!params.value) {
              return "attestation-name-blank";
            }

            return "attestations-name-cell";
          },
        };
      }
      return {
        ...rest,
        renderHeaderFilter: CustomTextHeaderFilter,
      };
    }
  });


  if (userdata.isAdmin) {
    columns.unshift({
      headerName: "Actions",
      field: "Actions",
      type: "actions",
      getActions: ({ ...rest }) => {
        const shouldShowDeleteIcon = true;
        if (storedTab !== "DELETED INV VAL CHANGES") {
          return shouldShowDeleteIcon ? [
            <>
              <GridActionsCellItem
                sx={{
                  color: "#253746",
                }}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={(e) => handleClickOpenDeletePopup(rest.row)}
              />
            </>,
          ] : [];
        } else {
          return [
            <>
              <GridActionsCellItem
                sx={{
                  color: "#253746",
                }}
                icon={<RestoreFromTrashIcon />}
                label="Restore"
                onClick={(e) => handleClickOpenRestorePopup(rest.row)}
              />
            </>,
          ];
        }
      },
    });
  }

  return (
    <>

      <Box className="dashboard-table-wrapper">
        <Grid item xs={12} sm={6} md={1} className="master-excel-grid">
          <LoadingButton
            variant="contained"
            className="excel-btn-reports"
            loading={excelDownloading}
            onClick={exportExcel}
          ><FileDownloadOutlinedIcon sx={{ mr: "0.5px" }} />EXCEL</LoadingButton>
        </Grid>
        <div style={{ width: "100%" }}>
          <DataGridPro
            className="dashboard-dataGrid"
            slots={{
              loadingOverlay: GridLoadingOverlay,
            }}
            sx={{
              pointerEvents: loading ? 'none' : 'auto',
              opacity: loading ? 0.7 : 1,
            }}
            getRowId={(row) => row.formID}
            rows={rows ?? []}
            rowCount={rowCount}
            columns={columns}
            headerFilters
            columnHeaderHeight={48}
            headerFilterHeight={48}
            pageSizeOptions={[5, 10, 100]}
            rowHeight={30}
            paginationMode="server"
            sortingMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handelPaginationModel}
            loading={loading}
            onSortModelChange={(model: GridSortModel) =>
              handelSortModelChange(model)
            }
            disableRowSelectionOnClick
            onCellClick={handleCellClick}
            pagination
            disableColumnMenu={true}
            autoHeight
            hideFooter={false}
          />
        </div>
        <Dialog
          open={openDeletePopup}
          onClose={handleCloseDeletePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="common-dialog-box"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to delete the selected attestation?"}
          </DialogTitle>
          <DialogActions>
            <Button
              className="primary-outline"
              onClick={handleCloseDeletePopup}
            >
              No
            </Button>
            <Button
              className="primary-outline"
              onClick={handleDeleteattestation}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openRestorePopup}
          onClose={handleCloseRestorePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="common-dialog-box"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to restore the selected attestation?"}
          </DialogTitle>
          <DialogActions>
            <Button
              className="primary-outline"
              onClick={handleCloseRestorePopup}
            >
              No
            </Button>
            <Button className="primary-outline"
             onClick={handleRestore}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
    </>
  );
};

export default AttestationDashboardDataGrid;
