import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
} from "../../Authorizations/components/shared/fileUpload/FileUpload.styles";
import { ToastMessage } from "../../Authorizations/components/utils/ToastMessage";
import { toast } from "../../Authorizations/interfaces/Interfaces";
import { LoadingButton } from "@mui/lab";
import { getApiCall } from "../../ApiHelper";
import { postApiCall } from "../../ApiHelper";
import axios from "axios";
import { baseUrl } from "../../config/Config";
import {
  getAllDocuments,
  uploadingDocuments,
  remainingAttachments,
} from "../redux/slices/attestdocumentslice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = (bytes: any) =>
  Math.round(bytes / KILO_BYTES_PER_BYTE);

const AttestFileupload = () => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.ivaFormData.ivaFormData);
  const fileInputField = useRef(null);
  const [files, setFiles] = useState<any>({});
  const [toast, setToast] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const addNewFiles = (newFiles: any) => {
    var ApprovedfileType = [
      "bmp",
      "csv",
      "xls",
      "xlsb",
      "xlsm",
      "xlsx",
      "docx",
      "dotx",
      "emp",
      "heic",
      "htm",
      "html",
      "jpeg",
      "jpg",
      "msg",
      "pdf",
      "png",
      "pptx",
      "rtf",
      "tif",
      "txt",
      "xps",
      "zip",
      "mov",
      "tng",
      "docs",
    ];
    for (let file of newFiles) {
      const fileType = file.name.split(".").slice(-1)[0]?.toLowerCase();
      if (!ApprovedfileType.includes(fileType)) {
        setToast(true);
        setToastMessage({
          message: "Please upload file with valid extension",
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      } else if (files.name) {
      } else {
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const handleNewFileUpload = (e: any) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      dispatch(remainingAttachments(Object.keys(updatedFiles).length));
    }
  };

  const handleuploadButtonClick = async () => {
    dispatch(uploadingDocuments(true));
    let ivaformid: any = formData?.id

    // Use Promise.all to wait for all file uploads to complete
    await Promise.all(
      Object.values(files).map(async (file: any) => {
        setLoadingButton(true);
        if (file) {
          let fileName: string = file.name;
          let encodedFileName: string = encodeURIComponent(fileName);
          var url = `${baseUrl}Document/upload?authId=${ivaformid}&fileName=${encodedFileName}`;
          try {
            let response: any = await getApiCall(url);
            if (!!response.url) {
              await uploadPresignedUrl(response.url, file, ivaformid);
            } else {
              setToast(true);
              setLoadingButton(false);
              dispatch(uploadingDocuments(false));
              setToastMessage({
                message: "Technical issue incountered while uploading",
                severity: "error",
              });
              setTimeout(() => setToast(false), 4000);
            }
          } catch (err: any) {
            dispatch(uploadingDocuments(false));
            const msg = err.response.data.description;
            setToast(true);
            setLoadingButton(false);
            setToastMessage({
              message: msg,
              severity: "error",
            });
            setTimeout(() => setToast(false), 4000);
          }
        }
      })
    );

    // This will run after all files have been processed
    if (filearray.length > 0) {
      await SaveDoctodatabase(ivaformid, filearray);
    }
  };
  const SaveDoctodatabase = async (ivaformid: any, file: any[]) => {
    var url = `${baseUrl}Document/SaveAttestDocToDatabase`;
    const SaveDocToDBResponse = {
      authId: ivaformid,
      docNames: file,
    };
    try {
      await postApiCall(url, SaveDocToDBResponse).then((response: any) => {
        if (response === true) {
          setFiles({});
          dispatch(remainingAttachments(0));
          setLoadingButton(false);
          dispatch(getAllDocuments(ivaformid));
          dispatch(uploadingDocuments(false));
        } else {
          dispatch(uploadingDocuments(false));
          setToast(true);
          setLoadingButton(false);
          setToastMessage({
            message: "Technical issue incountered while uploading",
            severity: "error",
          });
          setTimeout(() => setToast(false), 4000);
        }
      });
    } catch (err: any) {
      dispatch(uploadingDocuments(false));
      const msg = err.response.data.description;
      setToast(true);
      setLoadingButton(false);
      setToastMessage({
        message: msg,
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };
  let filearray: any = [];

  const uploadPresignedUrl = async (res: any, file: any, ivaformid: any) => {
    if (file) {
      try {
        await axios
          .put(res, file, {
            headers: {
              "Content-Type": file.type,
            },
          })
          .then(async (response: any) => {
            if (response.status === 200) {
              filearray.push(file.name);
            }
          });
      } catch (err: any) {
        dispatch(uploadingDocuments(false));
        const msg = err.response.data.description;
        setToast(true);
        setLoadingButton(false);
        setToastMessage({
          message: msg,
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      }
    }
  };

  const removeFile = (fileName: any) => {
    delete files[fileName];
    setFiles({ ...files });
    dispatch(remainingAttachments(Object.keys(files).length));
  };

  return (
    <>
      <FileUploadContainer className="upload-file-wrap">
        <CloudUploadIcon />
        <DragDropText>Drop files to attach, or browse</DragDropText>
        <b className="valid-file-type">
          Valid file types: bmp, csv, xls, xlsb, xlsm, xlsx, docx, dotx, emp,
          heic, htm, html, jpeg, jpg, msg, pdf, png, pptx, rtf, tif, txt, xps,
          zip, mov, docs, tng
        </b>
        <FormField
          data-testid="formField"
          className="fileUpload-formfield"
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          multiple
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <PreviewList data-testid="previewList">
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <CloseIcon onClick={() => removeFile(fileName)} />
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
      <LoadingButton
        disabled={Object.keys(files).length <= 0}
        className="upload-btn"
        loading={loadingButton}
        onClick={handleuploadButtonClick}
        variant="contained"
        component="span"
      >
        Upload Documents
      </LoadingButton>
      {toast && (
        <ToastMessage message={toastMessage.message} severity="error" />
      )}
    </>
  );
};

export default AttestFileupload;
