import React, { useEffect, useState } from 'react';
import { reportRequest } from '../../../interfaces/Interfaces';
import { baseUrl, theme } from '../../../../config/Config';
import { postApiCall } from '../../../../ApiHelper';
import { useAppSelector } from '../../../../redux/hooks';
import { Autocomplete, Button, Checkbox, Grid, TextField, ThemeProvider, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AdministrationDataGrid from './AdministrationDataGrid';
import moment from 'moment';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import * as XLSX from 'xlsx';
import { administrationColumns } from './administrationColumns';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from '@mui/lab';
import { ToastMessage } from '../../../components/utils/ToastMessage';
import { toast } from '../../../interfaces/Interfaces';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Administration: React.FC<Record<any, any>> = () => {
    const currentUserEmail: string = useAppSelector((state:
        { currentUser: { data: { email: any } } }) => { return state.currentUser.data.email; });
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const [periodFrom, setPeriodFrom] = useState(dayjs(firstDay))
    const [periodTo, setPeriodTo] = useState(dayjs(date))
    const [assetManagerDropDown, setAssetManagerDropDown] = useState<string[]>([])
    const [portfolioDropDown, setPortfoliorDropDown] = useState<string[]>([])
    const [selectedAssetManager, setSelectedAssetManager] = useState<string[]>()
    const [selectedPortfolio, setSelectedPortfolio] = useState<string[]>()
    const [rowData, setRowData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState<toast>({
      message: "",
      severity: "",
    });
    const [excelLoading, setExcelLoading] = useState<boolean>(false)
    const [pdfLoading, setPdfLoading] = useState<boolean>(false)
    const [reportRequest, setReportRequest] = useState<reportRequest>({
        mailId: currentUserEmail?.toLowerCase(),
        columnFilters: {
            portfolio: [],
            assetManager: [],
            startDate: periodFrom,
            endDate: periodTo
        },
        isInitialRequest: true
    })
    var url = `${baseUrl}Reports/ReportsData`;

    useEffect(() => {
        const data = getReportData()
    }, [currentUserEmail]);

    const errorToastMsgwithCode = (err: any) => {
        setToast(true);
        setToastMessage({
          message: `${err.response.data.description} (${err.response.data.code})`,
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      };

    const getReportData = async () => {
        try {
            setLoading(true)
            const res = await postApiCall(url, reportRequest).then((response: any) => {

                response?.reportDropdowns && setAssetManagerDropDown(response?.reportDropdowns.assetManager);
                response?.reportDropdowns && setSelectedAssetManager(response?.reportDropdowns.assetManager);
                response?.reportDropdowns && setPortfoliorDropDown(response?.reportDropdowns.portfolio);
                response?.reportDropdowns && setSelectedPortfolio(response?.reportDropdowns.portfolio);
                const transformedData = response.data.map((item: any) => {
                    const Approvers = item.approvers.reduce((acc: any, curr: any) => {
                        acc[`approver${curr.approver}Name`] = curr.name;
                        acc[`approver${curr.approver}Level`] = curr.level;
                        return acc;
                    }, {});
                    const authDate = moment(item.authDate).format("MMM-DD-YYYY hh:mm A")
                    return { ...item, ...Approvers, authDate };
                });

                const totalRow = {
                    formId: "",
                    authType: "Total",
                    authAmount: response?.total
                };
                if (response?.total == 0) {
                    setRowData([])

                }
                else {
                    setRowData([totalRow, ...transformedData]);
                }
                setLoading(false)

            })

        } catch (error) {
            setLoading(false);
            errorToastMsgwithCode(error);
            console.log("error in restore", error);
        }
    }
    const handelRunClick = () => {
        reportRequest.columnFilters = {
            portfolio: selectedPortfolio,
            assetManager: selectedAssetManager,
            startDate: periodFrom,
            endDate: periodTo
        };
        (assetManagerDropDown.length || portfolioDropDown.length) !== 0 || undefined
            ? reportRequest.isInitialRequest = false : reportRequest.isInitialRequest = true
        setReportRequest(reportRequest)
        getReportData()

    }

    const exportPdf = () => {
        setPdfLoading(true);
        const doc = new jsPDF()
        doc.setFontSize(18);
        if (rowData?.length > 0) {
            doc.text(`Administration of Investments (${periodFrom.format("MMM-DD-YYYY")} to ${periodTo.format("MMM-DD-YYYY")})`, 14, 22);
            const formattedRows = rowData.map((row: any) => ({
                ...row,
                authAmount: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(row?.authAmount)
            }))
            autoTable(doc, {
                startY: 30,
                columns: administrationColumns.map(col => ({ header: col.headerName, datakey: col.field })),
                body: formattedRows.map((row: any) => administrationColumns.map(col => row[col.field])),
                headStyles: { fillColor: '#253746' },
                styles: { fontSize: 5 },
                tableWidth: 'auto',
                columnStyles: { 5: { halign: 'right' } },
                margin: { top: 10, right: 5, left: 5 },

            });

            const now = dayjs();
            const hour = now.format('HH'); // 12-hour format
            const minute = now.format('mm');
            const day = now.format('DD');
            const month = now.format('MM');
            const year = now.format('YYYY');
            const seconds = now.format('ss')
            const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;

            doc.save(`Administration_of_investments_${timestamp}.pdf`)
            setPdfLoading(false);

        }
    }
    const exportExcel = () => {
        if (rowData?.length > 0) {
            setExcelLoading(true)
            const data = rowData.map((row: any) =>
                administrationColumns.map(col => row[col.field] || '')
            );
            const worksheet: any = XLSX.utils.aoa_to_sheet([
                administrationColumns.map(col => col.headerName),
                ...data,
            ]);
            for (let i = 2; i <= data.length + 1; i++) {
                worksheet[`F${i}`].z = '$0';
            }
            for (let i = 2; i <= data.length + 1; i++) {
                worksheet[`H${i}`].z = '0';
                worksheet[`H${i}`].s = { alignment: { horizontal: 'right' } }
            }
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'DataGrid');

            const now = dayjs();
            const hour = now.format('HH');
            const minute = now.format('mm');
            const day = now.format('DD');
            const month = now.format('MM');
            const year = now.format('YYYY');
            const seconds = now.format('ss')
            const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;

            XLSX.writeFile(workbook, `Administration_of_investments_${timestamp}.xlsx`);
            setExcelLoading(false)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="wrapper">
                <div className="reports-container">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <label>Select report parameters</label>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Grid>
                                <label>Period From</label>
                            </Grid>
                            <Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        format="MMM-DD-YYYY"
                                        slotProps={{ textField: { fullWidth: true, size: "small" , inputProps : {readOnly : true} } }}
                                        value={periodFrom}
                                        maxDate={periodTo}
                                        onChange={(newValue) => {
                                            if (newValue !== null) {
                                                setPeriodFrom(newValue)
                                            }
                                        }
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <Grid>
                                <label>Period To</label>
                            </Grid>
                            <Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        format="MMM-DD-YYYY"
                                        slotProps={{ textField: { fullWidth: true, size: "small" , inputProps : {readOnly : true} } }}
                                        value={periodTo}
                                        minDate={periodFrom}
                                        onChange={(newValue) => {
                                            if (newValue !== null) {
                                                setPeriodTo(newValue)
                                            }
                                        }
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid>
                                <label>Portfolio</label>
                            </Grid>
                            <Grid>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    size="small"
                                    // className="autocomplete-root"
                                    id="checkboxes-tags-demo"
                                    disableCloseOnSelect
                                    options={portfolioDropDown ?? []}
                                    value={selectedPortfolio ?? []}
                                    limitTags={1}
                                    onChange={(event, newInputValue) =>
                                        setSelectedPortfolio(newInputValue)
                                    }
                                    renderOption={(props, option, { selected }) => {
                                        const { ...optionProps } = props;
                                        return (
                                            <li  {...optionProps}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid>
                                <label>Asset Manager</label>
                            </Grid>
                            <Grid>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    size="small"
                                    // className="autocomplete-root"
                                    disableCloseOnSelect
                                    limitTags={1}
                                    id="checkboxes-tags-demo"
                                    options={assetManagerDropDown ?? []}
                                    value={selectedAssetManager ?? []}
                                    getOptionLabel={(option) => option}
                                    onChange={(event, newInputValue) =>
                                        setSelectedAssetManager(newInputValue)
                                    }
                                    renderOption={(props, option, { selected }) => {
                                        const { ...optionProps } = props;
                                        return (
                                            <li {...optionProps}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                            <Grid className="reports-grid">
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    className="run-btn-reports"
                                    onClick={handelRunClick}
                                ><PlayArrowIcon sx={{ mr: "2.5px", mb: "2.5px" }} />Run</LoadingButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} className="grid-hide">

                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                            <Grid className="reports-grid">
                                <LoadingButton
                                    variant="contained"
                                    className="excel-btn-reports"
                                    loading = {excelLoading}
                                    onClick={exportExcel}
                                ><FileDownloadOutlinedIcon sx={{ mr: "2.5px", mb: "2.5px" }} />Excel</LoadingButton>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={1}>
                            <Grid className="reports-grid">
                                <LoadingButton
                                    variant="contained"
                                    className="btn-reports"
                                    loading = {pdfLoading}
                                    onClick={exportPdf}
                                ><FileDownloadOutlinedIcon sx={{ mr: "2.5px", mb: "2.5px" }} />PDF</LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div className='box-margin-adm-report'>
                    <AdministrationDataGrid rowdata={rowData} loading={loading} />
                </div>
                {toast && (
                    <ToastMessage
                        message={toastMessage.message}
                        severity={toastMessage.severity}
                    />
                )}
            </div>
        </ThemeProvider>
    )
}

export default Administration;