import { useEffect, useState } from "react";
import {
  uploadedDocumentProps,
  toast,
  GridValueFormatterParams,
  ValueFormatter,
} from "../../../interfaces/Interfaces";
import moment from "moment";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  DataGridPro,
  GridColDef,
  GridActionsCellItem,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { ToastMessage } from "../../utils/ToastMessage";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import {
  getAllDocumentData,
  getAllDocuments,
} from "../../../../redux/slices/documentSlice";
import { baseUrl } from "../../../../config/Config";
import { getApiCall, postApiCall } from "../../../../ApiHelper";
import { DialogBox } from "../../utils/DialogBox";
import { getFormData } from "../../../../redux/slices/getFormDataSlice";
import { filesTobeUploaded } from "../../../../redux/slices/fileUploadSlice";
export function UploadedDocument({ document, mode }: uploadedDocumentProps) {
  const dispatch = useAppDispatch();
  const allDocuments = useAppSelector(getAllDocumentData);
  const formData = useAppSelector(getFormData);
  const uploadedDoc = useAppSelector((state) => state.fileUploadData.data);
  const [toast, setToast] = useState(false);
  const [selectedKey, setSelectedKey] = useState<any>();
  const [confirmationDialogBox, setConfirmationDialogBox] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  useEffect(() => {
    dispatch(filesTobeUploaded(allDocuments));
  }, [allDocuments]);

  let columns: GridColDef[] = [
    {
      field: "docName",
      headerName: "Document",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <a
              className="downloadLink"
              onClick={() => {
                downloadFilePresignedUrl(params.formattedValue);
              }}
            >
              {params.row.docName}
            </a>
          </>
        );
      },
    },

    {
      field: "comment",
      headerName: "Comments",
      flex: 2,
      editable: true,

    },
    {
      field: "uploadedOn",
      headerName: "Uploaded Date",
      flex: 2,
      renderCell: (params) => {
        return moment(params.row.uploadedOn).format("MMM-DD-YYYY hh:mm A");
      },
    },

    {
      field: "action",
      headerName: "Action",
      type: "actions",
      flex: 2,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (mode === "edit") {
          return (
            <>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                style={{ color: "#BA1A2E" }}
                label="Delete"
                onClick={() => {
                  setConfirmationDialogBox(true);
                  setSelectedKey(params.id);
                }}
                color="inherit"
              />
            </>
          );
        }
      },
    },
  ];

  if (!formData?.isFormEditable && !formData?.allowDocumentUpload) {
    columns = [
      {
        field: "docName",
        headerName: "Document",
        flex: 2,
        renderCell: (params) => {
          return (
            <>
              <a
                className="downloadLink"
                onClick={() => {
                  downloadFilePresignedUrl(params.formattedValue);
                }}
              >
                {params.row.docName}
              </a>
            </>
          );
        },
      },

      {
        field: "comment",
        headerName: "Comments",
        flex: 2,
        editable: true,
        // cellClassName : 'word-wrap-cell'
      },
      {
        field: "uploadedOn",
        headerName: "Uploaded Date",
        flex: 2,
        renderCell: (params) => {
          return moment(params.row.uploadedOn).format("MMM-DD-YYYY hh:mm A");
        },
      },
    ];
  }

  const handelDocDelete = async () => {
    let authorizationId: any = formData?.id;
    const request = {
      authId: authorizationId,
      fileKey: selectedKey,
    };
    var url = `${baseUrl}Document/Delete`;
    try {
      await postApiCall(url, request).then((response: any) => {
        if (response === true) {
          dispatch(getAllDocuments(authorizationId));
          setToast(true);
          setToastMessage({
            message: "File Deleted sucessfully",
            severity: "success",
          });
          setTimeout(() => setToast(false), 4000);
        } else {
          setToast(true);
          setToastMessage({
            message: "Technical issue incountered while uploading",
            severity: "error",
          });
          setTimeout(() => setToast(false), 4000);
        }
      });
    } catch (err: any) {
      const msg = err.response.data.description;
      setToast(true);
      setToastMessage({
        message: msg,
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };

  const downloadFilePresignedUrl = async (fileName: string): Promise<void> => {
    try {
      if (typeof document === 'undefined') {
        throw new Error('Document is not available');
      }
  
      let authorizationId: any = formData?.id;
      var url = `${baseUrl}Document/download?authId=${authorizationId}&fileName=${encodeURIComponent(fileName)}`;
      
      const response:any = await getApiCall(url);
      const presignedUrl = response.url;

      try {
        // For all file types, use fetch to get the blob
        const fileResponse = await fetch(presignedUrl);
        const blob = await fileResponse.blob();      
        // Create blob URL
        const blobUrl = window.URL.createObjectURL(blob); 
        // Create link element
        const link = window.document.createElement('a') as HTMLAnchorElement;
        link.href = blobUrl;
        link.download = fileName;
        link.style.display = 'none';       
        // Add to document, click and remove
        window.document.body.appendChild(link);
        link.click();       
        // Cleanup
        setTimeout(() => {
          window.document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
  
      } catch (downloadError) {
        console.error('Download failed:', downloadError);
        
        // Fallback method
        const link = window.document.createElement('a') as HTMLAnchorElement;
        link.href = presignedUrl;
        link.download = fileName;
        link.style.display = 'none';
        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
      }
  
    } catch (error) {
      console.error('Download error:', error);
      throw error;
    }
  };
  
  
  
  const handelEditComment = (newRow: any, e: any) => {
    const updatedUsers = uploadedDoc.map((doc: any) => {
      if (doc.fileKey === newRow.fileKey) {
        return { ...doc, comment: newRow.comment };
      }
      return doc;
    });
    dispatch(filesTobeUploaded(updatedUsers));
  };

  return (
    <>
      {toast &&<ToastMessage
        message={toastMessage.message}
        severity={toastMessage.severity}
      />}
      <Box sx={{ width: "100%" }}>
        <div className="Container">
          <div className="Grid">
            <DataGridPro
              sx={{
                backgroundColor: "white",
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#00313C",
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
                "& .MuiDataGrid-filterIcon": {
                  color: "white",
                },
              }}
              pagination
              pageSizeOptions={[10, 25, 40, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableColumnMenu={true}
              autoHeight={true}
              hideFooter={allDocuments?.length ? false : true}
              className={allDocuments?.length ? "" : "table-no-row-found"}
              getRowId={(row) => row.fileKey}
              rows={allDocuments ?? []}
              columns={columns}
              // getRowHeight={() => 'auto'}
              processRowUpdate={(newRow: any, e) => {
                handelEditComment(newRow, e);
              }}
              onProcessRowUpdateError={(error: any) => {
                console.log(error);
              }}
            />
          </div>
        </div>
      </Box>
      {formData?.isFormEditable || formData?.allowDocumentUpload ? (
        <DialogBox
          openDialogBox={confirmationDialogBox}
          setOpenDialogBox={setConfirmationDialogBox}
          setConfirm={handelDocDelete}
          dialogText={"Are you sure you want to delete the file?"}
        />
      ) : (
        ""
      )}
    </>
  );
}
