import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../../config/Config";
import { getApiCall } from "../../../ApiHelper";
import { AttestDocuments } from "../../Interfaces/AttestInterfaces";

type InitialState = {
    documents: AttestDocuments | null,
    error: string,
    loading: boolean,
    uploadingDocuments: boolean,
    remainingAttachments: any,
    alldocuments : any

}

const initialState: InitialState = {
    documents: null,
    error: '',
    loading: false,
    uploadingDocuments:false,
    remainingAttachments: {} as any,
    alldocuments : {} as any
}

export const getAllDocuments = createAsyncThunk("getAllDocuments", async (payload:any, {rejectWithValue}) => {
    try {
        var url = `${baseUrl}Document/GetAllAttestDocument?authId=${payload}`;
        const response = await getApiCall<AttestDocuments>(url);
        return response;
    } catch (error: any) {
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const attestdocumentSlice = createSlice({
    name: 'allDocumentData',
    initialState,
    reducers: {
        uploadingDocuments(state, action){
            state.uploadingDocuments = action.payload
        },
        remainingAttachments(state, action){
            state.remainingAttachments = action.payload
        },
        alldocuments(state,action)
        {
            state.alldocuments = action.payload
        }
    },
    extraReducers: (builder) => {
            builder.addCase(getAllDocuments.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllDocuments.fulfilled, (state, action: PayloadAction<AttestDocuments | null>) => {
                state.loading = false;
                state.documents = action.payload;
            })
            .addCase(getAllDocuments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
    }
});

export const getAllDocumentData = (state:any) => state.documentData.documents
export const {uploadingDocuments, remainingAttachments ,alldocuments} = attestdocumentSlice.actions;
export default attestdocumentSlice.reducer;

