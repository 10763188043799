import { Button, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";

import { useEffect, useState } from "react";
import { ToastMessage } from "../Authorizations/components/utils/ToastMessage";
import { toast } from "./Interfaces/AttestInterfaces";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { resetSubmitFormErrorStateAction } from "./redux/slices/attestsubmitforerrorslice";
import { updateErrorFields } from "./redux/slices/attestsubmitforerrorslice";
import { setLoading } from "../redux/slices/loaderSlice";
import { baseUrl } from "../config/Config";
import { postApiCall } from "../ApiHelper";
import { resetmaterialchangeStateAction } from "./redux/slices/MaterialChangeDataSlice";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";

import { useNavigate, useParams } from "react-router-dom";
import { DialogBox } from "../attestutils/DialogBox";
const AttestActionButton = (props: any) => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openRestorePopup, setOpenRestorePopup] = useState(false);

  const [saveSubmitData, setSaveSubmitData] = useState<any>();
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const formData = useAppSelector((state) => state.ivaFormData.ivaFormData);
  const remainingAttachments = useAppSelector(
    (state) => state.documentData.remainingAttachments
  );
  const uploadingDocuments = useAppSelector(
    (state) => state.documentData.uploadingDocuments
  );

  const submiterrormodel = useAppSelector(
    (state) => state.attestsubmitformerror
  );
  const materialchangedate = useAppSelector(
    (state) => state.materialchangedata.data
  );
  const attestalldocuments = useAppSelector(
    (state) => state.attestdocumentData.alldocuments
  );
  const handleDeleteAttestation = async () => {
    var url = `${baseUrl}Attestations/deleteorrestore`;
    dispatch(setLoading(true));

    const deletePayload = {
      id: formData?.id,
      isDelete: true,
    };
    try {
      const res = await postApiCall(url, deletePayload);
      setToast(true);
      setToastMessage({
        message: "Attestation deleted sucessfully",
        severity: "success",
      });
      setTimeout(() => {
        setToast(false);
        nav("/attestations/invval/dashboard");
      }, 4000);
    } catch (error) {
      errorToastMsgwithCode(error);
    }
    dispatch(setLoading(false));
  };

  const fieldErrorToastMessage = (msg: string) => {
    setToast(true);
    setToastMessage({
      message: msg,
      severity: "error",
    });
    setTimeout(() => setToast(false), 4000);
  };
  useEffect(() => {
    if (formData?.id) {
      setSaveSubmitData({
        ...saveSubmitData,
        id: formData?.id,
      });
    }
  }, [formData?.id]);

  useEffect(() => {
    setSaveSubmitData((prevData: any) => ({
      ...prevData,
      documents: attestalldocuments,
    }));
  }, [attestalldocuments]);
  useEffect(() => {
    setSaveSubmitData((prevData: any) => ({
      ...prevData,

      formDate: materialchangedate.formDate,
      materialChangeDescription: materialchangedate.materialChangeDescription,
      invValChangeName: materialchangedate?.invValChangeName,
    }));
  }, [
    materialchangedate.materialChangeDescription,
    materialchangedate.formDate,
    materialchangedate.invValChangeName,
  ]);

  const checkValidation = () => {
    var isError = false;
    const startdate =
      materialchangedate.formDate === "" ||
      materialchangedate.formDate === undefined ||
      materialchangedate.formDate === null;

    const materialchange =
      materialchangedate.materialChangeDescription === "" ||
      materialchangedate.materialChangeDescription === undefined ||
      materialchangedate.materialChangeDescription === null;

    const invchangenamereason =
      materialchangedate.invValChangeName === "" ||
      materialchangedate.invValChangeName === undefined ||
      materialchangedate.invValChangeName === null;

    dispatch(
      updateErrorFields({
        ...submiterrormodel.data,
        attestformdate: startdate,
        attestmatericalchange: materialchange,
        attestinvchangename: invchangenamereason,
      })
    );
    isError = startdate || materialchange || invchangenamereason;
    return isError;
  };

  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
  };

  const clearAuthStateAfterSuccess = () => {
    setToast(true);
    setToastMessage({
      message: "Investment Valuation Change submitted sucessfully",
      severity: "success",
    });
    setTimeout(() => {
      dispatch(resetSubmitFormErrorStateAction());
      dispatch(resetmaterialchangeStateAction());
      setToast(false);
      nav("/attestations/invval/dashboard");
    }, 4000);
  };
  const remainingAttachmentsCheck = () => {
    if (remainingAttachments > 0) {
      setToast(true);
      setToastMessage({
        message: 'Please click on "Upload Documents" to upload attachments',
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
      return false;
    } else {
      return true;
    }
  };

  const handleSave = async () => {
    if (remainingAttachmentsCheck()) {
      dispatch(setLoading(true));
      const saveUrl = `${baseUrl}Attestations/invvalsavesubmit`;
      await postApiCall(saveUrl, saveSubmitData)
        .then((response: any) => {
          if (String(response) == "Success") {
            dispatch(setLoading(false));
            setToast(true);
            setToastMessage({
              message: "Investment Valuation Change saved sucessfully",
              severity: "success",
            });
            setTimeout(() => setToast(false), 4000);
          }
        })
        .catch((err: any) => {
          dispatch(setLoading(false));
          errorToastMsgwithCode(err);
        });
    }
  };

  const handlePost = () => {
    dispatch(setLoading(true));
    var attestationsValidated = checkValidation();
    if (attestationsValidated) {
      fieldErrorToastMessage("Please fill in the required field(s)");
      dispatch(setLoading(false));
    } else {
      const updatedData = {
        ...saveSubmitData,
        isSubmitted: true,
      };
      submitAPICall(updatedData);
    }
  };

  const submitAPICall = async (updatedData: any) => {
    const submitUrl = `${baseUrl}Attestations/invvalsavesubmit`;
    await postApiCall(submitUrl, updatedData)
      .then((response: any) => {
        if (String(response) == "Success") {
          dispatch(setLoading(false));
          clearAuthStateAfterSuccess();
        }
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
        errorToastMsgwithCode(err);
      });
  };
 

  const handleRestoreAttestation = async () => {
    var url = `${baseUrl}Attestations/deleteorrestore`;
    dispatch(setLoading(true));

    const restorePayload = {
      id: formData?.id,
      isDelete: false,
    };
    try {
      const res = await postApiCall(url, restorePayload);
      setToast(true);
      setToastMessage({
        message: "Attestation restored sucessfully",
        severity: "success",
      });
      setTimeout(() => {
        setToast(false);
        nav("/attestations/invval/dashboard");
      }, 4000);
    } catch (error) {
      errorToastMsgwithCode(error);

      console.log("error in restore", error);
    }
    dispatch(setLoading(false));

  };

  return (
    <>
      <Grid className="btn-padding" container>
        <Grid container item xs={12} sm={6} md={4}>
          {formData?.showSaveButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-btn-padding">
              <Button
                className={
                  uploadingDocuments ? "deactivate-btn" : "btn-primary"
                }
                variant="contained"
                disabled={uploadingDocuments}
                color="primary"
                onClick={() => handleSave()}
              >
                <SaveIcon sx={{ mr: "2.5px" }} /> Save
              </Button>
            </Grid>
          ) : null}
          {formData?.showCloseButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-btn-padding">
              <Button
                variant="contained"
                disabled={uploadingDocuments}
                className={
                  uploadingDocuments ? "deactivate-btn" : "btn-tertiary"
                }
                onClick={() => {
                  if (remainingAttachmentsCheck()) {
                    setTimeout(() => {
                      nav("/attestations/invval/dashboard");
                    }, 0);
                  }
                }}
              >
                <CloseIcon sx={{ mr: "2.5px" }} /> Close
              </Button>
            </Grid>
          ) : null}

          {formData?.showDeleteButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-btn-padding">
              <Button
                className={
                  uploadingDocuments ? "deactivate-btn" : "btn-tertiary"
                }
                disabled={uploadingDocuments}
                onClick={() => {
                  if (remainingAttachmentsCheck()) {
                    setOpenDeletePopup(true);
                  }
                }}
                variant="contained"
                color="primary"
              >
                <DeleteIcon sx={{ mr: "2.5px" }} /> Delete
              </Button>
            </Grid>
          ) : null}
          {formData?.showRestoreButton ? (
            <Button
              className={
                uploadingDocuments ? "deactivate-btn" : "btn-restore-width"
              }
              disabled={uploadingDocuments}
              onClick={() => {
                if (remainingAttachmentsCheck()) {
                  setOpenRestorePopup(true);
                }
              }}
              variant="contained"
              color="primary"
            >
              <RestoreFromTrashIcon sx={{ mr: "2.5px" }} /> Restore
            </Button>
          ) : null}
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          md={8}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {formData?.showSubmitButton ? (
            <Grid item xs={12} sm={3} md={3} className="grid-right-btn">
              <Button
                className={
                  uploadingDocuments
                    ? "deactivate-btn"
                    : "btn-primary btn-float"
                }
                variant="contained"
                color="primary"
                disabled={uploadingDocuments}
                onClick={() => {
                  if (remainingAttachmentsCheck()) {
                    handlePost();
                  }
                }}
              >
                <DoneIcon sx={{ mr: "2.5px" }} /> Submit
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <DialogBox
        openDialogBox={openDeletePopup}
        setOpenDialogBox={setOpenDeletePopup}
        setConfirm={handleDeleteAttestation}
        dialogTitle={"Delete confirmation"}
        dialogText={"Do you want to delete the selected attestation?"}
      />
      <DialogBox
        openDialogBox={openRestorePopup}
        setOpenDialogBox={setOpenRestorePopup}
        setConfirm={handleRestoreAttestation}
        dialogTitle={"Restore confirmation"}
        dialogText={"Do you want to restore the selected attestation?"}
      />
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
    </>
  );
};

export default AttestActionButton;
