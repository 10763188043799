import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IvaFormId, userAccess } from "../../Interfaces/AttestInterfaces";
import { baseUrl } from "../../../config/Config";
import { getApiCall } from "../../../ApiHelper";
import { getNewIvaFormIdrequest } from "../../Interfaces/AttestInterfaces";

type InitialState = {
    data: userAccess | null,
    error: string,
    loading: boolean
}
const initialState: InitialState = {
    data: null,
    error: '',
    loading: false
}
export const getLandingPageAccess = createAsyncThunk("getUserAccess",  async (_, {rejectWithValue}) => {
    try {
        var url = `${baseUrl}Users/AMPortalUserAccessDetails`;
        const response = await getApiCall<userAccess>(url);
        return response;
    } catch (error: any) {
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const landingPageAccessSlice = createSlice({
    name: 'landingPageAccess',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLandingPageAccess.pending, (state) => {
            state.loading = true;
        })
        .addCase(getLandingPageAccess.fulfilled, (state, action: PayloadAction<userAccess>) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(getLandingPageAccess.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'something went wrong';
        })
    },
});

export const getLandingPageAccessData = (state:any) => state.landingPageAccessData.data
export default landingPageAccessSlice.reducer;

