import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { UserDetails } from "../../components/utils/UserDetails";
import AppBreadCrumbs from "../Breadcrumbs";
import { UserGuide } from "./userGuide";
import { useDocumentTitle } from "../../../customHooks/useDocumentTitle";

const HeaderRoot = (props: any) => {
    useDocumentTitle('Authorizations')
    return (
        <>
            <div className="header">
                <h2 className="app-title">
                    <div className="logo"></div> <span>| Authorizations</span>
                </h2>
                <div className="header-right">
                    <UserGuide />
                    <span className="app-title">|</span>
                    <UserDetails accounts={props.accounts} signOutClickHandler={props.signOutClickHandler} />
                </div>
            </div>
            <div className="nav-area">
                <Navbar isAdmin={props.isAdmin} />
            </div>
            <AppBreadCrumbs />
            <Outlet />
        </>
    );
};

export default HeaderRoot;