import { baseUrl } from "../../config/Config";
import { getApiCall, postApiCall } from "../../ApiHelper";


export class AttestationService {

  FetchDashboardAttestations(payload:any) {
    var url = `${baseUrl}Dashboard/invvaldata`;   
    const res = postApiCall(url, payload).then((data) => data);
    return res;
  }
  
  FetchDashboardAttestationsExcel(payload:any) {
    var url = `${baseUrl}Dashboard/ExcelInvValData`;   
    const res = postApiCall(url, payload).then((data) => data);
    return res;
  }

}
