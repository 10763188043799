// hooks/useGlobalErrors.ts
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { clearError, clearAllErrors } from '../../redux/slices/errorSlice';

export const useGlobalErrors = () => {
  const dispatch = useDispatch();
  const globalErrors = useSelector((state: RootState) => state.error.globalErrors);

  return {
    errors: globalErrors,
    hasErrors: Object.keys(globalErrors).length > 0,
    getErrorsBySlice: (sliceName: string) => 
      Object.entries(globalErrors)
        .filter(([_, error]) => error.sliceName === sliceName)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    clearError: (actionType: string) => dispatch(clearError(actionType)),
    clearAllErrors: () => dispatch(clearAllErrors())
  };
};
