import AccordionTab from '../../AccordianTab';
import { Container, Grid, Input, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField'
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useEffect } from 'react';
import { setLoading } from '../../redux/slices/loaderSlice';

const AttestInvestment: React.FC<Record<any, any>> = () => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.ivaFormData.ivaFormData);
  useEffect(() => {
    dispatch(setLoading(false));
  }, [formData?.id])

 return (
    <AccordionTab 
    title={'Investment'} children={
      <div>
        <Grid container spacing={3} >
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Investment ID</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={formData?.investmentId} disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Investment Name</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true}  value={formData?.investmentName} disabled></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Fund (Portfolio)</label>
            </Grid>
            <Grid>
              <TextField  size='small' fullWidth={true} value={formData?.fundName}  disabled></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>PrM Company</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true}  value={formData?.propertyMgmtCompany} disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Sector Head</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={formData?.sectorHeadName}  disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Asset Manager</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={formData?.assetManagerName} disabled></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Portfolio Manager</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={formData?.portfolioManagerName} disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Property Manager</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth value={formData?.propManagerName}  disabled ></TextField>
            </Grid>
          </Grid>
        </Grid>
      </div>
    } />
  )
}
export default AttestInvestment;