
  export const menuItemsAdminData = [
    {
      title: 'Dashboard',
      submenu : [
        {
          title : 'Inv Val Chg Dashboard',
          url: '/attestations/invval/dashboard',
        },
        {
          title : 'Qtrly Attestations Dashboard',
          url : '/attestations/qa/dashboard',
        }
      ]
    },
    {
      title: 'Reports',
      submenu: [
        {
          title: 'Attestation Report',
          url: '/attestations/reports/attestationreport',
        },
        
      ]
    },
    {
      title: 'Settings',
      submenu: [
        {
          title: 'Qtrly Attestations Funds/Sector',
          url: '/attestations/settings/qtrlyattestationsfundsectors',
        },
      ]
    },
   
    
  ];
  
  
  export const menuItemsUserData = [
    {
      title: 'Dashboard',
      submenu : [
        {
          title : 'Inv Val Chg Dashboard',
          url: '/attestations/invval/dashboard',
        },
        {
          title : 'Qtrly Attestations Dashboard',
          url : '/attestations/qa/dashboard',
        },
        
       
      ],
   
     
    },
    {
      title: 'Reports',
      submenu: [
        {
          title: 'Attestation Report',
          url: '/attestations/reports/attestationreport',
        },
        
      ]
    },
    
  ]