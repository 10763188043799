import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateAuthorizationData } from "../../../redux/slices/authorizationDataSlice";
import { updateErrorFields } from "../../../redux/slices/submitFormErrorSlice";
import AccordionTab from "../../../AccordianTab";
import { Grid } from "@mui/material";
import { postApiCall } from "../../../ApiHelper";
import { baseUrl } from "../../../config/Config";
import ApproverField from "./authorizationSectionHelper/ApproverField";
import ApproverReadOnlyField from "./authorizationSectionHelper/ApproverReadOnlyField";
import AssetManagerField from "./authorizationSectionHelper/AssetManagerField";
import AuthorizationHistory from "./authorizationSectionHelper/AuthorizationHistory";
import PropertyManagerField from "./authorizationSectionHelper/PropertyManagerField";
import ValuationField from "./authorizationSectionHelper/ValuationField";
import { useAppState, useLocalState } from "./authorizationSectionHelper/useStateHooks";
import { useAuthorizationConstants } from "./authorizationSectionHelper/useAuthorizationConstants";
import CapitalAuthSection from "./CapitalAuthSection";
import ModAuthSection from "./ModAuthSection";
import LeaseAuthSection from "./LeaseAuthSection";
import LeaseAndPropAuthSection from "./LeaseAndPropAuthSection";
import { ApproversErrorModel } from "../../interfaces/Interfaces";
import { ToastMessage } from "../utils/ToastMessage";

const Authorization = (props: any) => {
  const dispatch = useAppDispatch();
  const appState = useAppState();
  const localState = useLocalState(appState?.formData);
  const constants = useAuthorizationConstants(appState?.formData, appState?.AuthData,
    appState?.capitalAuthFormType, appState?.userdata, appState?.budgetedData, appState?.nonBudgetedData, appState?.ModProjectDetailData, appState?.proposaldata, appState.leaseProjectDetails, appState?.authorizationData);

  const parentComp: any = {
    CaptialAuthorization: CapitalAuthSection,
    LeaseAuth: LeaseAuthSection,
    AppointmentLCNPMC: LeaseAndPropAuthSection,
    Modifications: ModAuthSection
  };
  const AuthorizationComponent = parentComp[props.authType];

  const errorToastMsgwithCode = (err: any) => {
    localState.setToast(true);
    localState.setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
    setTimeout(() => localState.setToast(false), 4000);
  };

  // Call to get approvers
  const fetchApprovers = useCallback(async () => {
    localState.authApproversRequest.authType = appState?.formData?.authCode;
    localState.authApproversRequest.fundName = appState?.formData?.fundName;
    localState?.setLoading(true);
    try {
      const response: any = await postApiCall(`${baseUrl}Approvers/details`, localState?.authApproversRequest);
      localState?.setApproversList(response);
      localState.setShowApprovers(true);
    } catch (error) {
      errorToastMsgwithCode(error);
      dispatch(updateAuthorizationData({
        ...appState.authorizationData,
        selectedApprovers: []
      }));
      localState.setShowApprovers(false);
    } finally {
      localState?.setLoading(false);
    }
  }, [localState?.authApproversRequest]);

  const handleValuationChange = useCallback(async (selectedValue: any) => {
    localState.authApproversRequest.authType = appState?.formData?.authCode;
    localState.authApproversRequest.fundName = appState?.formData?.fundName;
    localState.authApproversRequest.isAmtHigherThanValuationPercentage = selectedValue === "Yes";
    localState?.setValuationSelect(selectedValue as string);
    localState?.setLoading(true);
    fetchApprovers()

  }, [appState?.authorizationData, fetchApprovers]);


  const resetApprovers = () => {
    dispatch(updateAuthorizationData({
      ...appState.authorizationData,
      selectedApprovers: []
    }));
    localState.setShowApprovers(false);
    localState?.setShowValuationText(false);
  };

  // Effect for initializing authorization data when formData changes
  useEffect(() => {
    dispatch(updateAuthorizationData({
      propertyManager: appState?.formData?.propManagerName,
      propertyManagerEmail: appState?.formData?.propManagerEmail,
      recommendedBy: appState?.formData?.assetManagerName,
      recommendedByEmail: appState?.formData?.assetManagerEmail,
      isAmtHigherThanValuationPercentage: appState?.formData?.isAmtHigherThanValuationPercentage,
      selectedApprovers: appState?.formData?.approvers,
      approversListModel: []
    }));
  }, [appState?.formData]);

  // Effect for updating selected managers when formData changes
  useEffect(() => {
    localState?.setSelectedAssetManager(
      appState?.formData?.assetManager?.find((option: any) => option.name === appState?.authorizationData?.recommendedBy) ||
      {
        name: appState?.formData?.assetManagerName || "",
        email: appState?.formData?.assetManagerEmail || ""
      }
    );
    localState?.setSelectedPropertyManager(
      !appState?.formData?.disablePropertyManager
        ? (appState?.formData?.propertyManager?.find((option: any) => option.name === appState?.authorizationData?.propertyManager) ||
        {
          name: appState?.formData?.propManagerName || "",
          email: appState?.formData?.propManagerEmail || ""
        })
        : null
    );
  }, [appState?.formData]);

  useEffect(() => {
    if (localState?.approversList !== undefined) {

      var tempApproversArray: Array<any> = [];
      localState?.approversList.forEach((element: any, index: number) => {
        tempApproversArray.push({ level: element.level, isEmpty: false, approver: index });
      });

      dispatch(updateAuthorizationData({
        ...appState?.authorizationData,
        approversListModel: tempApproversArray
      }));
    }

  }, [localState?.approversList]);

  // Change on selecting approvers
  const onChangeSelecteApprovers = (index: number, newInputValue: any, value: any) => {
    var updatedApprovers = [];
    var filteredSelectedApprovers: Array<ApproversErrorModel> = appState?.submitErrorModel.data.approversListError?.filter((element: ApproversErrorModel) => element.approver !== index + 1);
    filteredSelectedApprovers.push({ level: value.level, isEmpty: false, approver: index + 1 });

    dispatch(updateErrorFields({ ...appState?.submitErrorModel.data, approversListError: filteredSelectedApprovers.sort((a, b) => a.approver - b.approver) }));

    if (newInputValue !== null) {
      const approver: any = appState?.authorizationData?.selectedApprovers?.find((elm: any) => elm.approver !== index + 1);
      const newApprovers: any = {
        name: newInputValue?.name ? newInputValue.name : "",
        approver: index + 1,
        mail: newInputValue?.emailId ? newInputValue.emailId : "",
        level: value.level,
        comments: "",
        isApproved: false
      };
      updatedApprovers = approver ? [approver, newApprovers] : [newApprovers];
    } else {
      const approver = [...appState?.authorizationData?.selectedApprovers];
      updatedApprovers = approver.filter(e => e.approver !== index + 1);
    }
    dispatch(updateAuthorizationData({
      ...appState?.authorizationData,
      selectedApprovers: updatedApprovers
    }));
  };

  // Rendering approvers fields
  const renderApproverFields = useCallback(() => {
    if (localState?.loading) return null;
    if ((localState?.approversList?.length > 0 && localState.showApprovers) || appState?.formData?.showApprovers) {
      return localState?.approversList?.map((value: any, index: number) => (
        <ApproverField
          key={index}
          index={index}
          value={value}
          formData={appState?.formData}
          onChangeSelecteApprovers={onChangeSelecteApprovers}
          submitErrorModel={appState?.submitErrorModel}
        />
      ));
    }
    if (!appState?.formData?.isFormEditable && !appState?.formData?.showApprovers && appState?.formData?.approvers) {
      return appState?.formData.approvers.map((value: any, index: number) => (
        <ApproverReadOnlyField key={index} index={index} value={value} />
      ));
    }

    return null;
  }, [localState?.approversList,localState.showApprovers,appState]);

  return (
    <AccordionTab
      title="Authorization"
      children={
        <div className="authorization-form-container">
          <label className="authorization-form-header">{appState?.formData?.verbiageText}</label>
          {appState?.formData?.approvalsInfo  ? (
            <AuthorizationHistory formData={appState?.formData} />
          ) : (
            <Grid container spacing={2}>
              <PropertyManagerField
                formData={appState?.formData}
                userdata={appState?.userdata}
                selectedPropertyManager={localState?.selectedPropertyManager}
                setSelectedPropertyManager={localState?.setSelectedPropertyManager}
                dispatch={dispatch}
                submitErrorModel={appState?.submitErrorModel}
                authorizationData={appState?.authorizationData}
              />
              <AssetManagerField
                formData={appState?.formData}
                selectedAssetManager={localState?.selectedAssetManager}
                setSelectedAssetManager={localState?.setSelectedAssetManager}
                dispatch={dispatch}
                submitErrorModel={appState?.submitErrorModel}
                authorizationData={appState?.authorizationData}
              />
              {!appState?.formData?.showPropertyManager && <Grid item md={6} className="grid-hide">
              </Grid>}
              {localState?.showValuationText && (
                <ValuationField
                  formData={appState?.formData}
                  valuationSelect={localState?.valuationSelect}
                  handleValuationChange={handleValuationChange}
                  dispatch={dispatch}
                  submitErrorModel={appState?.submitErrorModel}
                  authorizationData={appState?.authorizationData}
                />
              )}
              {AuthorizationComponent && (
                <AuthorizationComponent
                  constants={constants}
                  localState={localState}
                  appState={appState}
                  resetApprovers={resetApprovers}
                  handleValuationChange={handleValuationChange}
                  fetchApprovers={fetchApprovers}
                />
              )}
              {renderApproverFields()}
            </Grid>
          )}
          {localState?.toast && (
        <ToastMessage
          message={localState.toastMessage.message}
          severity={localState.toastMessage.severity}
        />
      )}
        </div>
      }
    />
  );
};

export default Authorization;
