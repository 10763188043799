import React, { useEffect, useState } from "react";
import PlayArrow from "@mui/icons-material/PlayArrow";
import { attestationreportRequest } from "../../../../Attestations/Interfaces/AttestInterfaces";
import QAViewTabs from "./QAViewTabs";

import { baseUrl, theme } from "../../../../config/Config";
import { postApiCall } from "../../../../ApiHelper";
import { useAppSelector } from "../../../../redux/hooks";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as XLSX from "xlsx";
import { qadatagridcolumns } from "./datagridviewcolumns";
import { datagridinvcolumns } from "./datagridviewcolumns";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import IosShare from "@mui/icons-material/IosShare";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LoadingButton } from "@mui/lab";
import { toast } from "../../../../Authorizations/interfaces/Interfaces";
import { ToastMessage } from "../../../../Authorizations/components/utils/ToastMessage";
import moment from "moment";




const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const QuarterlyReports: React.FC<Record<any, any>> = () => {
  const currentUserEmail: string = useAppSelector(
    (state: { currentUser: { data: { email: any } } }) => {
      return state.currentUser.data.email;
    }
  );
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const [periodFrom, setPeriodFrom] = useState(dayjs(firstDay));
  const [periodTo, setPeriodTo] = useState(dayjs(date));
  const [selectedSectorHead, setSelectedSectorHead] = useState<string[]>();
  const [selectedAssetManager, setSelectedAssetManager] = useState<string[]>();
    const [selectedPortfolio, setSelectedPortfolio] = useState<string[]>()

  const [selectedFund, setselectedFund] = useState<string[]>();

  const [rowData, setRowData] = useState<any>();
  const [rowdataqa, setRowqadata] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [excelLoading, setExcelLoading] = useState<boolean>(false)
  const [pdfLoading, setPdfLoading] = useState<boolean>(false)
  const [assetManagerDropDown, setAssetManagerDropDown] = useState<string[]>([])
  const [sectorDropDown, setsectorDropDown] = useState<string[]>([]);

  const [portfolioDropDown, setPortfoliorDropDown] = useState<string[]>([]);
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [reportRequest, setReportRequest] = useState<attestationreportRequest>({
    columnFilters: {
      portfolio: [],
      assetManager : [],
      sectorHead : [],
      startDate: periodFrom,
      endDate: periodTo,
    },
    isInitialRequest: true,
  });
  useEffect(() => {
    const data = getattestationData();
  }, [currentUserEmail]);

  var url = `${baseUrl}Reports/attestdata`;


  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
    setTimeout(() => setToast(false), 4000);
  };

  const getattestationData = async () => {
    try {
      setLoading(true);
      const res = await postApiCall(url, reportRequest).then(
        (response: any) => {
          response?.reportDropdowns && setAssetManagerDropDown(response?.reportDropdowns.assetManager);
          response?.reportDropdowns && setSelectedAssetManager(response?.reportDropdowns.assetManager);
          response?.reportDropdowns && setPortfoliorDropDown(response?.reportDropdowns.portfolio);
          response?.reportDropdowns && setSelectedPortfolio(response?.reportDropdowns.portfolio);
          response?.reportDropdowns && setSelectedSectorHead(response?.reportDropdowns.sectorHead);
          response?.reportDropdowns && setsectorDropDown (response?.reportDropdowns.sectorHead)
      
            setRowData([ ...response?.invValData]);
            setRowqadata([...response?.qaData])       
      
          
          setLoading(false);
        // }
        }
      );
    } catch (error) {
      setLoading(false);
      errorToastMsgwithCode(error)
      console.log("error in restore", error);
    }
  };

  const handelRunClick = () => {
    reportRequest.columnFilters = {
        portfolio: selectedPortfolio,
        assetManager: selectedAssetManager,
        sectorHead : selectedSectorHead,
        startDate: periodFrom,
        endDate: periodTo
    };
    (assetManagerDropDown.length || portfolioDropDown.length || sectorDropDown) !== 0 || undefined
        ? reportRequest.isInitialRequest = false : reportRequest.isInitialRequest = true
    setReportRequest(reportRequest)
    getattestationData()

}


  const exportExcel = () => {
    setExcelLoading(true)
    const workbook = XLSX.utils.book_new();

    if (rowData?.length > 0) {
      const data = rowData?.map((row: any) =>
      
       qadatagridcolumns ?.map((col) =>
       {
        if(col.field === 'formDate')
        {
          return row[col.field] ?
           moment(row[col.field]).format("MMM-DD-YYYY hh:mm A")
         : ""
       }
     return   row[col.field] || ""
      }))
      
      const worksheet: any = XLSX.utils.aoa_to_sheet([
        qadatagridcolumns?.map((col) => col.headerName),
        ...data,
      ]);
      console.log(worksheet , "test")
     
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Inv Val Data"
      );
    }
    if (rowdataqa?.length > 0) {
      const qadata = rowdataqa?.map((row: any) =>

        datagridinvcolumns?.map((col) =>
        {
          if(col.field === 'formDate')
            {
              return row[col.field] ?
               moment(row[col.field]).format("MMM-DD-YYYY hh:mm A")
             : ""
           }
         return   row[col.field] || ""
          }
      
      ))
      const modificationworksheet: any = XLSX.utils.aoa_to_sheet([
        datagridinvcolumns?.map((col) => col.headerName),
        ...qadata,
      ]);
      XLSX.utils.book_append_sheet(
        workbook,
        modificationworksheet,
        "Quarterly Attestations"
      );
    }

    if (rowData?.length > 0 || rowdataqa?.length > 0) {
      const now = dayjs();
      const hour = now.format('HH'); // 12-hour format
      const minute = now.format('mm');
      const day = now.format('DD');
      const month = now.format('MM');
      const year = now.format('YYYY');
      const seconds = now.format('ss')
      const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;
      XLSX.writeFile(workbook, `Attestation_Report_${timestamp}.xlsx`);
    }
    setExcelLoading(false);
  };

  const exportPdf = () => {
    setPdfLoading(true);
    const doc = new jsPDF();
    doc.setFontSize(12);
    if (rowData?.length > 0) {
      const text = `Inv Val Attestations (${periodFrom.format(
        "MMM-DD-YYYY"
      )} to ${periodTo.format("MMM-DD-YYYY")})  `
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const textWidth = doc.getTextWidth(text);
      const fontSize = doc.getFontSize();

      const textHeight = fontSize * 0.3528;
      const xPosition = (pageWidth - textWidth) / 2;
      const yPosition = (pageHeight - textHeight) / 2;
      doc.text(text, xPosition, 10)
      const formattedmodificationRows = rowData?.map((row: any) => ({
        ...row,
      }));
      // let jointext = selectedPortfolio?.join(', ')

      // doc.text(`${jointext}`, xPosition / 2, 20);
   
      autoTable(doc, {
        startY: 30,
        columns: qadatagridcolumns.map((col) => ({
          header: col.headerName,
          datakey: col.field,
        })),
         body: formattedmodificationRows.map((row: any) =>

          qadatagridcolumns .map((col) =>
            {
              if(col.field === 'formDate')
              {
                return row[col.field] ?
                 moment(row[col.field]).format("MMM-DD-YYYY hh:mm A")
               : ""
             }
           return   row[col.field] || ""
            })),
      
      
        headStyles: { fillColor: "#253746" },
        styles: { fontSize: 5 },
        tableWidth: "auto",
        columnStyles: { 5: { halign: "right" } },
        margin: { top: 10, right: 5, left: 5 },
      });
    }
    if (rowdataqa?.length > 0) {
      doc.addPage();
      const text =            
        `Quarterly Attestations(${periodFrom.format( 
          "MMM-DD-YYYY"
        )} to ${periodTo.format("MMM-DD-YYYY")}) `
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const textWidth = doc.getTextWidth(text);
      const fontSize = doc.getFontSize();

      const textHeight = fontSize * 0.3528;
      const xPosition = (pageWidth - textWidth) / 2;
      doc.text(text, xPosition, 10)
      // let jointext = selectedPortfolio?.join(', ')

      // doc.text(`${jointext}`, xPosition / 2, 20);

      const formattedmodificationRows = rowdataqa?.map((row: any) => ({
        ...row,
      }));
      autoTable(doc, {
        startY: 30,
        columns: datagridinvcolumns.map((col) => ({
          header: col.headerName,
          datakey: col.field,
        })),
        body: formattedmodificationRows.map((row: any) =>
          datagridinvcolumns.map((col) => 
            
            {
              if(col.field === 'formDate')
                {
                  return row[col.field] ?
                   moment(row[col.field]).format("MMM-DD-YYYY hh:mm A")
                 : ""
               }
             return   row[col.field] || ""
              }
          
          )),
        
        headStyles: { fillColor: "#253746" },

        styles: { fontSize: 5 },
        tableWidth: "auto",
        columnStyles: { 5: { halign: "left" } },
        margin: { top: 10, right: 5, left: 5 },
      });
    }
    const now = dayjs();
    const hour = now.format('HH');
    const minute = now.format('mm');
    const day = now.format('DD');
    const month = now.format('MM');
    const year = now.format('YYYY');
    const seconds = now.format('ss')
    const timestamp = `${day}${month}${year}${hour}${minute}${seconds}`;



    if (rowData?.length > 0 || rowdataqa?.length > 0) {
      doc.save(`Attestation_Report_${timestamp}.pdf`,);
    }
    setPdfLoading(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <div className="reports-container">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label>Select report parameters</label>
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid>
                <label>Period From</label>
              </Grid>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="MMM-DD-YYYY"
                    slotProps={{ textField: { fullWidth: true, size: "small"  , inputProps : {readOnly : true}} }}
                    value={periodFrom}
                    maxDate={periodTo}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        setPeriodFrom(newValue);
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid>
                <label>Period To</label>
              </Grid>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="MMM-DD-YYYY"
                    slotProps={{ textField: { fullWidth: true, size: "small"  , inputProps : {readOnly : true}} }}
                    value={periodTo}
                    minDate={periodFrom}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        setPeriodTo(newValue);
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid>
                <label>Fund </label>
              </Grid>
              <Grid>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  options={portfolioDropDown ?? []}
                  value={selectedPortfolio ?? []}
                  limitTags={1}
                  onChange={(event, newInputValue) =>
                    setSelectedPortfolio(newInputValue)
                  }
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid>
                <label>Sector Head</label>
              </Grid>
              <Grid>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  options={sectorDropDown ?? []}
                  value={selectedSectorHead ?? []}
                  limitTags={1}
                  onChange={(event, newInputValue) =>
                    setSelectedSectorHead(newInputValue)
                  }
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid>
                <label>Asset Manager</label>
              </Grid>
              <Grid>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  options={assetManagerDropDown ?? []}
                  value={selectedAssetManager ?? []}
                  getOptionLabel={(option) => option}

                  limitTags={1}
                  onChange={(event, newInputValue) =>
                    setSelectedAssetManager(newInputValue)
                }
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
         
                  
            <Grid item xs={12} sm={12} md={2}>
              <Grid className="reports-grid">
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  className="run-btn-reports"
                  onClick={handelRunClick}
                >
                  <PlayArrow sx={{ mr: "2.5px", mb: "2.5px" }} />
                  Run
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid item xs={8} className="grid-hide">

            </Grid>
            <Grid item xs={12} sm={6} md={1}>
                <Grid className="reports-grid">
                  <LoadingButton
                    loading= {excelLoading}
                    variant="contained"
                    className="excel-btn-reports"
                    onClick={exportExcel}
                  >
                    <FileDownloadOutlinedIcon sx={{ mr: "2.5px", mb:"2.5px" }} />
                    Excel
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={1}>
                <Grid className="reports-grid">
                  <LoadingButton
                    loading ={pdfLoading}
                    variant="contained"
                    className="btn-reports"
                    onClick={exportPdf}
                  >
                    <FileDownloadOutlinedIcon sx={{ mr: "2.5px", mb:"2.5px" }} />
                    PDF
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
        </div>
        <QAViewTabs data={rowData} qadata={rowdataqa} loading={loading} />
        {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
      </div>
    </ThemeProvider>
  );
};

export default QuarterlyReports;
