import { Outlet } from "react-router-dom";
import AppBreadcrumbs from "../AttestBreadcrumbs";
import { UserDetails } from "../../../Authorizations/components/utils/UserDetails";
import AttestNavbar from "./AttestNavbar";
import { AttestUserGuide } from "./AttestUserGuide";
import { useDocumentTitle } from "../../../customHooks/useDocumentTitle";
const AttestHeaderRoot = (props: any) => {
    useDocumentTitle('Attestations')
    return (
        <>
            <div className="header">
                <h2 className="app-title">
                    <div className="logo"></div> <span>| Attestations</span>
                </h2>
                <div className="header-right">
                    <AttestUserGuide />
                    <span className="app-title">|</span>
                    <UserDetails accounts={props.accounts} signOutClickHandler={props.signOutClickHandler} />
                </div>
            </div>
            <div className="nav-area">
                <AttestNavbar isAdmin={props.isAdmin} />
            </div>
            <AppBreadcrumbs />

            <Outlet />
        </>
    );
};

export default AttestHeaderRoot;