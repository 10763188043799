import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";

import { useAppSelector, useAppDispatch } from "../../redux/hooks";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
  };
}) as typeof Chip;

const AppBreadcrumbs = () => {
  const nav = useNavigate();

  const invId = useAppSelector(
    (state) => state.authData.data.invID
  );

  const authName = useAppSelector(
    (state) => state.authData.data.authName
  );

  const breadcrumbData = useAppSelector(
    (state) => state.authData
  );

  const goToDashboard = () => {
    setTimeout(() => {
      nav("/authorizations/dashboard");
    }, 0);
  };

  var pathName = window.location.pathname;

  if (window.location.pathname.slice(-1) === "/") {
    pathName = pathName.replace(/.$/, '');
    console.log(pathName , "pathName")
  }

  const pathnames = pathName.split("/").filter((x) => x);
  console.log(pathnames , "test")
  const formData = useAppSelector((state) => state.getFormData);

  const breadcrumbNameMap: { [key: string]: string } = {
    "/authorizations/dashboard": " Dashboards",
    "/authorizations/settings": " Settings",
    "/authorizations/reports": " Reports",
  };

  const formBreadcrumb = pathnames[0] == null || invId == null || pathnames.length === 1 ? " " : " /Dashboard/" + (!formData?.loading ? breadcrumbData.data?.invID + " - " + breadcrumbData.data.authType : "");
  const formBreadcrumb2 = pathnames[0] == null || invId == null ? " " : "/" + (!formData?.loading ? breadcrumbData.data?.invID + " - " + breadcrumbData.data.authType : "");

  const id = pathnames.slice(-1)[0];
  const id2 = pathnames.slice(-2)[0];
  const id3 = pathnames.slice(-3)[0];

  breadcrumbNameMap["/authorizations/dashboard/" + id2 + "/" + id] = formBreadcrumb;
  breadcrumbNameMap["/"+ id3 + "/" + id2 + "/" + id] = formBreadcrumb2;

  breadcrumbNameMap["/authorizations/settings/types"] = " Settings/ Authorizations Types";
  breadcrumbNameMap["/authorizations/settings/approverlevels"] = " Settings/ Authorizations Approver Levels";
  breadcrumbNameMap["/authorizations/settings/verbiage"] = " Settings/ Authorizations Verbiage";
  breadcrumbNameMap["/authorizations/settings/fundsemail"] = " Settings/ Funds Email";
  breadcrumbNameMap["/authorizations/settings/fundsemail"] = " Settings/ Funds Email";

  breadcrumbNameMap["/authorizations/settings/funds"] = " Settings/ Funds";
  breadcrumbNameMap["/authorizations/settings/approvers"] = " Settings/ Approvers";
  breadcrumbNameMap["/authorizations/settings/approvers"] = " Settings/ Approvers";
  breadcrumbNameMap["/authorizations/settings/approvedCapitalProjects"] = " Settings/ Approved Capital Projects";

  breadcrumbNameMap["/authorizations/reports/administrationOfInvestments"] = " Reports/ Administration of Investments";
  breadcrumbNameMap["/authorizations/reports/investmentActivitySummary"] = " Reports/ Investment Activity Summary";
  if (breadcrumbNameMap[window.location.pathname] === undefined) {
    breadcrumbNameMap[pathName] = " NA"
  }

  return (<div role="presentation" className="mt-10 mb-10">
    <Breadcrumbs aria-label="breadcrumb" className="brd">
      <StyledBreadcrumb
        label="Authorizations"
        onClick={() => goToDashboard()}
        icon={<HomeIcon fontSize="small" className="hm" />}
      />
      {pathnames.map((name, index) => {
        if (index + 1 == pathnames.length) {
          const goTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const mappedKey = Object.keys(breadcrumbNameMap).filter((key) =>
            goTo.includes(key)
          );
          const mappedValue = breadcrumbNameMap[goTo];
          return mappedValue
            .split("/")
            .filter((x) => x)
            .map((n, i) => <StyledBreadcrumb label={n} component="a" />);
        }
      })}
    </Breadcrumbs>
  </div>);
}

export default AppBreadcrumbs;
