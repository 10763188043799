import { createSlice } from "@reduxjs/toolkit";
import { materialchange } from "../../Interfaces/AttestInterfaces";
interface InitialState {
    data: materialchange,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as materialchange,
    error: '',
    loading: false
}

const MaterialChangeDataSlice = createSlice(
    {
        name: 'MaterialChange',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as materialchange;
                state.error = "";
                state.loading = false;
            },
            materialchangetStateInImprovement: (state) => {
                state.data = {initialDataSet : false} as materialchange;
                state.error = "";
                state.loading = false;
            },
            updateMaterialChangeData(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { updateMaterialChangeData ,resetState: resetmaterialchangeStateAction, materialchangetStateInImprovement} = MaterialChangeDataSlice.actions;
export default MaterialChangeDataSlice.reducer;
