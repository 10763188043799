import * as React from 'react';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import ViewTabs from '../AttestDashboard/Viewtabs';
import AttestationDashboardDataGrid from './AttestationDashboardDataGrid';
import { getAttestationsTabs } from '../redux/slices/attestationDbTabsSlice';
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getInvdetailsdata, getinvdetails } from '../../redux/slices/createAuthSlice';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, Grid, TextareaAutosize } from "@mui/material";

import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { theme } from "../../config/Config";
import { ThemeProvider } from "@mui/material/styles";


const AttestationDashboard = () => {

  const dispatch = useAppDispatch();
  const currentUserEmail: string | undefined = useAppSelector((state) => { return state.currentUser.data.email });
  const tabs: string[] | null = useAppSelector((state) => { return state.attestationDbTabs.tabs});
  const [open, setOpen] = useState<boolean>(false);
  const [selectedViewTab, setSelectedViewTab] = useState<string>("")
  const [pageSize, setPageSize] = useState<number>(10);
  const [isActive, setIsActive] = useState<any>(JSON.parse(localStorage.getItem("attestInvType") || "[]").isActive === "false" ? "false" : "true");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const activestate = value;
    setIsActive(activestate);
    localStorage.removeItem("attestationsDashBoardPayload");
  };

  useEffect(() => {
    dispatch(getAttestationsTabs(currentUserEmail));
  }, [currentUserEmail])

  const tabChange = (id: string) => {
    setSelectedViewTab(id)
  }

  return (
    <React.Fragment>
        <Grid container spacing={2} className='grid-header-dashboard'>
          <Grid item xs={8}>
            <h2 className="text-left title" > Investment Valuation Change Dashboard</h2>
          </Grid>
          <Grid item xs={4}>
            <div className='activeall'>
              <FormControl>
                <RadioGroup row className="new-auth-btn"
                  onChange={handleRadioChange}
                  value={isActive}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Active" />
                  <FormControlLabel value="false" control={<Radio />} label="All" />

                </RadioGroup>

              </FormControl>
            </div>
          </Grid>
        </Grid>
        <div className="clear"></div>
        <div className="wrapper">
          <ViewTabs sendData={tabChange} tabData={tabs} />
          <AttestationDashboardDataGrid selectedTab={selectedViewTab} isActive={isActive} />
        </div>
    </React.Fragment>
  )
}

export default AttestationDashboard;