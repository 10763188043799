import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { toast } from "../../../Authorizations/interfaces/Interfaces";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";
import { QuarterlyMSValidate } from "../../../Attestations/Interfaces/AttestInterfaces";
import { QuarterlytMSResponse } from "../../../Attestations/Interfaces/AttestInterfaces";
import { postApiCall } from "../../../ApiHelper";
import { baseUrl } from "../../../config/Config";
import { ToastMessage } from "../../../Authorizations/components/utils/ToastMessage";
import { PeopleSharp } from "@mui/icons-material";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const QADialogBox = (props: any) => {
  const [toast, setToast] = useState(false);
  const [responseData, setResponseData] = useState<QuarterlytMSResponse>({
    id: null,
    fundNames: [],
    sectorHeadName: "",
    sectorHeadEmail: "",
    sectors: "",
    isActive: true,
  });

  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [disableLoader, setDisableLoader] = useState<boolean>(false);

  const [validateData, setValidateData] = useState<QuarterlyMSValidate>({
    fundNames: false,
    sectorHeads: false,
    sectors: false,
  });
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [selectedOptions, setSelectedOptions] = useState<any>();
  const [disableOtherOptions, setDisableOtherOptions] =
    useState<boolean>(false);
  useEffect(() => {
    setResponseData(props.updateRow);
  }, [props.updateRow]);

  const validatesectordescription = (input: any) => {
    input = input.trim();
    const regex = /^\s*[a-zA-Z0-9\/'-]+(\s*,\s*[a-zA-Z0-9\/'-]+)*\s*$/;

    return regex.test(input);
  };

  const checkValidation = () => {
    validatesectordescription(responseData?.sectors ?? "");
    setValidateData({
      sectorHeads: responseData.sectorHeadName == "" ? true : false,
      fundNames: responseData.fundNames?.length == 0 ? true : false,
      sectors: responseData.sectors == "" ? true : false,
    });
    return (
      responseData.sectorHeadName == "" ||
      responseData.fundNames?.length == 0 ||
      responseData.sectors == ""
    );
  };
  const handleSubmit = async () => {
    var url = `${baseUrl}SectorHead/data`;
    try {
      const payloaddata = {
        id: responseData?.id,
        fundNames: responseData?.fundNames,
        sectorHeadName: responseData?.sectorHeadName.value,
        sectorHeadEmail: responseData?.sectorHeadName.name,
        sectors: responseData?.sectors,

        isActive: responseData?.isActive,
      };

      const res = await postApiCall(url, payloaddata).then(() => {
        setToast(true);

        setToastMessage({
          message: `Funds/Sector saved sucessfully`,
          severity: "success",
        });
        setButtonLoader(false);
        setDisableLoader(true);
        setTimeout(() => setToast(false), 4000);
        setTimeout(() => {
          props.setOpen(false);
        }, 4000);
        props.submitClicked(true);
      });
    } catch (error: any) {
      setToast(true);
      setToastMessage({
        message: `${error.response?.data?.description} (${error?.response?.data?.code})`,
        severity: "error",
      });
      setButtonLoader(false);
      setTimeout(() => {
        setToast(false);
      }, 4000);
    }
  };
  const handleSubmitClick = async () => {
    var checkFormValidation = checkValidation();
    if (
      checkFormValidation !== true &&
      validatesectordescription(responseData?.sectors ?? "") === true
    ) {
      setButtonLoader(true);
      handleSubmit();
    } else if (
      responseData?.sectors &&
      validatesectordescription(responseData?.sectors ?? "") === false
    ) {
      setToast(true);
      setToastMessage({
        message: "Please enter multiple sector names with ,",
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    } else {
      setToast(true);
      setToastMessage({
        message: "Please fill in the required field(s)",
        severity: "error",
      });
      setTimeout(() => setToast(false), 4000);
    }
  };

  return (
    <>
      <Dialog
        open={props?.open}
        onClose={props?.onClose}
        maxWidth="md"
        fullWidth
        className="common-dialog-box"
      >
        <DialogTitle id="alert-dialog-title">
          {props.type} Funds/Sectors
        </DialogTitle>
        <DialogContent
          style={{
            height: "calc(60vh - 64px)",
            justifyContent: "center",
          }}
          className="form-field-label"
        >
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Grid>
                <label>Fund:</label>
                <span className="mandatory"> * </span>
              </Grid>
              <Grid>
                <Autocomplete
                  multiple
                 loading = {!props.popupfundata}
                  options={props?.popupfundata ?? []}

                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  value={responseData?.fundNames ?? []}
                  onChange={(event: any, newValue: any) => {
                    setResponseData({
                      ...responseData,
                      fundNames: newValue,
                    });
                    setValidateData({ ...validateData, fundNames: false });
                  }}
                  renderOption={(props, option: any, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                      ></Checkbox>
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      error={validateData?.fundNames}
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={6}>
              <Grid>
                <label htmlFor="field1">Sector Head Name</label>

                <span className="mandatory"> * </span>
              </Grid>
              <Grid>
                <Autocomplete
                loading = {!props.popupsectorheadname}
                  options={
                    props?.popupsectorheadname
                      ? Object.keys(props?.popupsectorheadname)?.map((key) => ({
                          name: key, // Key ("name", "Name1")
                          value: props?.popupsectorheadname[key], // Corresponding value ("test", "test1")
                        }))
                      : []
                  }

                  value={responseData?.sectorHeadName}
                  getOptionLabel={(option) => option?.value || ""}
                  id="checkboxes-tags-demo"
                  disableCloseOnSelect
                  limitTags={1}
                  onChange={(event: any, newValue: any) => {
                    setResponseData({
                      ...responseData,
                      sectorHeadName: newValue,
                    });
                    setValidateData({ ...validateData, sectorHeads: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={validateData?.sectorHeads}
                      {...params}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  size="small"
                />{" "}
              </Grid>
              {/* Bottom field, centered */}
            </Grid>

            <Grid item xs={12} md={12}>
              <label htmlFor="field1">Sector Names</label>
              <span className="mandatory"> * </span>
              <TextField
               inputProps={{
                min: 0,
                maxLength: 256,
                onPaste: (e) => {
                  const pastedText = e.clipboardData.getData('text');
                  if (pastedText.length > 256) {
                    e.preventDefault();
                  }
                }
              }}
                placeholder="Please enter multiple sector names with ,"
                fullWidth
              
                
                error={validateData?.sectors}
                value={responseData?.sectors}
                onChange={(event) => {
                  setResponseData({
                    ...responseData,
                    sectors: event.target.value,
                  });
                  setValidateData({ ...validateData, sectors: false });
                }}
              >
                {" "}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={responseData?.isActive}
                    onChange={(event) =>
                      setResponseData({
                        ...responseData,
                        isActive: event.target.checked,
                      })
                    }
                  />
                }
                label="Is Active"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#343434",
                    lineHeight: "24px",
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              props.setOpen(false);
            }}
            color="primary"
            variant="outlined"
            className="btn-tertiary"
          >
            Cancel
          </Button>
          <LoadingButton
            className={disableLoader ? "btn-primary-disabled" : "btn-primary"}
            variant="contained"
            disabled={disableLoader}
            loading={buttonLoader}
            onClick={() => {
              handleSubmitClick();
            }}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
    </>
  );
};
export default QADialogBox;
