import { createSlice } from "@reduxjs/toolkit";
import { errorFields } from "../../Interfaces/AttestInterfaces";

interface InitialState {
  data: errorFields;
  error: string;
  loading: boolean;
}
var initialData = {
  attestmatericalchange: false,
  attestformdate: false,
  finalCheck: true,
  attestinvchangename: false,
};

const initialState: InitialState = {
  data: { ...initialData } as errorFields,
  error: "",
  loading: false,
};

const AttestsubmitFormErrorSlice = createSlice({
  name: "MaterialchangeData",
  initialState,
  reducers: {
    resetState: (state) => {
      state.data = { ...initialData } as errorFields;
      state.error = "";
      state.loading = false;
    },
    updateErrorFields(state, action) {
      state.data = action.payload;
    },
    submitErrorCheck(state) {
      state.data.finalCheck =
        state.data.attestmatericalchange || state.data.attestformdate ||state.data.attestinvchangename;
    },
  },
});
export const {
  updateErrorFields,
  resetState: resetSubmitFormErrorStateAction,
} = AttestsubmitFormErrorSlice.actions;
export default AttestsubmitFormErrorSlice.reducer;
