import AccordionTab from '../../../AccordianTab';
import { Container, Grid, Input, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField'
import { useAppSelector } from '../../../redux/hooks';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import * as React from 'react';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);
const Investment: React.FC<Record<any, any>> = () => {

  const data = useAppSelector((state) => {
    return state.getFormData.formData;
  });

  return (
    <AccordionTab title={'Investment'} children={
      <>
        <Grid container spacing={3} >
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Name</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={data?.invName} disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Type</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={data?.type} disabled></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>TIAA Ownership (%)</label>
            </Grid>
            <Grid>
              <TextField type="number" size='small' fullWidth={true} inputProps={{ min: 0, style: { textAlign: 'right' } }} value={data?.tiaaOwnershipShare.toFixed(2)} disabled></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Address</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={data?.address} disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Total Investment NRA (SF)</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} InputProps={{
                inputComponent: NumericFormatCustom as any
              }} inputProps={{ min: 0, style: { textAlign: 'right' } }} value={data?.totalInvestmentShare} disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Ownership Type</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={data?.ownershipType} disabled></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Multiple Properties</label>
            </Grid>
            <Grid>
              <TextField size='small' fullWidth={true} value={data?.multipleProps} disabled />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid>
              <label>Investment Occupancy (%)</label>
            </Grid>
            <Grid>
              <TextField type="number" fullWidth size='small' inputProps={{ min: 0, style: { textAlign: 'right' } }} value={data?.investmentOccupancy.toFixed(2)} disabled ></TextField>
            </Grid>
          </Grid>
        </Grid>
      </>
    } />
  )
}
export default Investment;