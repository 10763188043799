import React, { useEffect, useState } from 'react';
import FundsDataGrid from './FundsDataGrid';
import { ThemeProvider } from 'styled-components';
import { baseUrl, theme } from '../../../../config/Config';
import { getApiCall, postApiCall } from '../../../../ApiHelper';
import { Grid } from "@mui/material";
import { ToastMessage } from '../../../components/utils/ToastMessage';
import { toast } from '../../../interfaces/Interfaces';

function Fund() {
    const [rowData, setRowData] = useState<any>();
    const [fundNameDropdown, setFundNameDropdown] = useState<any>();
    const [statusDropDown, setStatusDropDown] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [toast, setToast] = useState(false);
    const [toastMessage, setToastMessage] = useState<toast>({
      message: "",
      severity: "",
    });

    useEffect(() => {
        getFundsData();
    }, []);

    const errorToastMsgwithCode = (err: any) => {
        setToast(true);
        setToastMessage({
          message: `${err.response.data.description} (${err.response.data.code})`,
          severity: "error",
        });
        setTimeout(() => setToast(false), 4000);
      };
    const getFundsData = async () => {
        var url = `${baseUrl}funds/details`;
        try {
            setLoading(true)
            const res = await getApiCall(url).then((response: any) => {
                console.log(response, "response")
                setRowData(response.funds);
                setFundNameDropdown(response.fundNames);
                setStatusDropDown(response.status)
                setLoading(false);
            })

        } catch (error) {
            setLoading(false);
            errorToastMsgwithCode(error);
            console.log("error in fetching funds", error);
        }
    }

    return (
        <ThemeProvider theme={theme}>

            <div className="wrapper">
                <Grid item lg={4}>
                    <h2 className="text-left mstitle" >Funds</h2>
                </Grid>
                <FundsDataGrid rowdata={rowData} loading={loading} fundNameDropdown={fundNameDropdown} statusDropDown={statusDropDown} />
                {toast && (
                    <ToastMessage
                        message={toastMessage.message}
                        severity={toastMessage.severity}
                    />
                )}
            </div>
        </ThemeProvider>
    );
}

export default Fund;