import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { baseUrl, theme } from "../../../config/Config";
import { Grid } from "@mui/material";

import { toast } from "../../../Attestations/Interfaces/AttestInterfaces";
import { ToastMessage } from "../../../Authorizations/components/utils/ToastMessage";
import QAdatagrid from "./QAdatagrid";
import QADialogBox from "./QADialogBox";
import { getApiCall } from "../../../ApiHelper";
import { QuarterlytMSResponse } from "../../../Attestations/Interfaces/AttestInterfaces";
function QASettings() {
  const [open, setOpen] = useState(false);

  const [rowData, setRowData] = useState<any>();
  const [fundNameDropdown, setFundNameDropdown] = useState<any>();
  const [authorizationDropDown, setauthorizationDropDown] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [authorization, setAuthorization] = useState<any>();
  const [fund, setFundData] = useState<any>();
  const [verbiage, setVerbiageData] = useState<any>();
  const [code, setcode] = useState<any>();
  const [id, setid] = useState<any>();
  const [fundname, setfundname] = useState<any>();
  const [sectorheadsname, setsectorheadsname] = useState<any>();
  const [sectorheadsemail, setsectorheadsemail] = useState<any>();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [adHocDropdown, setAdHocDropdown] = useState<any>();
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [createdon, setCreatedOn] = useState<any>();
  const [createdby, setCreatedBy] = useState<any>();
  const [updatedby, setUpdatedBy] = useState<any>();
  const [updatedat, setUpdatedAt] = useState<any>();
  const [toast, setToast] = useState(false);
  const [popupfunddata, setpopupdata] = useState<any>();
  const [popupsectorheadname, setpopupsectorheadname] = useState<any>();
  const [gridDropDown, setGridDropDown] = useState<any>({
    fundNames: [],
    sectorHeadName: [],
    CreatedOn: [],
    UpdatedBy: [],
    UpdatedOn: [],
  });
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });
  const [updateData, setUpdateData] = useState<QuarterlytMSResponse>({
    id: null,
    fundNames: [],
    sectorHeadName: "",
    sectorHeadEmail: "",
    sectors: "",
    isActive: true,
  });

  useEffect(() => {
    if (submitClicked) {
      getQAData();
    }
  }, [submitClicked]);
  useEffect(() => {
    getQAData();
  }, []);

  useEffect(() => {
    if (!openDialog) {
      setUpdateData({
        fundNames: [],
        id: null,
        sectors: "",
        sectorHeadName: "",
        sectorHeadEmail: "",
        isActive: true,
      });
    }
  }, [openDialog]);

  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response?.data?.description} (${err.response?.data?.code})`,
      severity: "error",
    });
    setTimeout(() => setToast(false), 4000);
  };

  const getQAData = async () => {
    var url = `${baseUrl}SectorHead/data`;
    try {
      setLoading(true);
      const res = await getApiCall(url).then((response: any) => {
        const formattedRows = response?.data?.map((row: any) => ({
          ...row,
          isActive: row?.isActive ? "Yes" : "No",
        }));

        setRowData(formattedRows);
        setGridDropDown({
          fundNames: response?.filterDropdowns?.fundNames,
          sectorHeadName: response?.filterDropdowns?.sectorHeadName,
          createdBy: response?.filterDropdowns?.createdBy,
          createdOn: response?.filterDropdowns?.createdOn,
          updatedBy: response?.filterDropdowns?.updatedBy,
          updatedAt: response?.filterDropdowns?.updatedAt,
        });
        setSubmitClicked(false);

        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      errorToastMsgwithCode(error);
      console.log("error in fetching funds", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="wrapper">
        <Grid item lg={4}>
          <h2 className="text-left mstitle">Qtrly Attestations Funds/Sectors </h2>
        </Grid>
        {openDialog ? (
          <QADialogBox
            open={setOpenDialog}
            setOpen={setOpenDialog}
            rowdata={rowData}
            fundnames={fundname}
            sectoremail={sectorheadsemail}
            sectorname={sectorheadsname}
            popupfundata={popupfunddata}
            popupsectorheadname={popupsectorheadname}
            submitClicked={setSubmitClicked}
            updateRow={updateData}
            type={type}
          />
        ) : null}
        <QAdatagrid
          open={setOpenDialog}
          setOpen={setOpenDialog}
          loading={loading}
          rowdata={rowData}
          gridDropDown={gridDropDown}
          setpopupdata={setpopupdata}
          setpopupsectorheadname={setpopupsectorheadname}
          updateRow={setUpdateData}
          type={setType}
        />
       
        {toast && (
          <ToastMessage
            message={toastMessage.message}
            severity={toastMessage.severity}
          />
        )} 
      </div>
    </ThemeProvider>
  );
}

export default QASettings;
