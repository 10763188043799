import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton, Tooltip, CircularProgress } from '@mui/material';  
import { baseUrl } from '../../../config/Config';
import { getApiCall } from '../../../ApiHelper';
import { useState } from 'react'; 


export const UserGuide = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);

    const downloadFilePresignedUrl = async (): Promise<void> => {
        try {
            setIsLoading(true); 
            var id = "7467c87f-d8bb-4909-8af5-94d8a3506434"
            var name = "Authorizations User Manual.pdf"
            var url = `${baseUrl}Document/download?authId=${id}&fileName=${name}`;
            const response: any = await getApiCall(url);
            const presignedUrl = response.url;
            try {
                const fileResponse = await fetch(presignedUrl);
                const blob = await fileResponse.blob();
                const blobUrl = window.URL.createObjectURL(blob);
                const link = window.document.createElement('a') as HTMLAnchorElement;
                link.href = blobUrl;
                link.download = name;
                link.style.display = 'none';
                window.document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    window.document.body.removeChild(link);
                    window.URL.revokeObjectURL(blobUrl);
                }, 100);
            } catch (downloadError) {
                console.error('Download failed:', downloadError);
                const link = window.document.createElement('a') as HTMLAnchorElement;
                link.href = presignedUrl;
                link.download = name;
                link.style.display = 'none';
                window.document.body.appendChild(link);
                link.click();
                window.document.body.removeChild(link);
            }
        } catch (error) {
            console.error('Download error:', error);
            throw error;
        } finally {
            setIsLoading(false); 
        }
    };

    return (
        <div>
            <Tooltip title="Click here to download Help Guide" placement="bottom">
                <IconButton
                    data-testid="user-name-button"
                    sx={{
                        fontSize: "1rem",
                        marginRight: "10px",
                        padding: "0px",
                        "&:hover": { backgroundColor: "transparent", cursor: "pointer" },
                    }}
                    onClick={() => downloadFilePresignedUrl()}
                    disabled={isLoading} 
                >
                    {isLoading ? (
                        <CircularProgress
                            size={25}
                            sx={{
                                color: "#fff"
                            }}
                        />
                    ) : (
                        <HelpOutlineIcon
                            sx={{
                                color: "#fff",
                                width: "30px",
                                height: "30px",
                            }}
                        />
                    )}
                </IconButton>
            </Tooltip>
        </div>
    );
};
