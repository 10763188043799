import {
  GridCellParams,
  GridColDef,
  GridColTypeDef,
} from "@mui/x-data-grid-pro";
import moment from "moment";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const usdPrice: GridColTypeDef = {
  type: "number",
  width: 130,
  minWidth: 130,
  valueFormatter: (value) => currencyFormatter.format(value),
  cellClassName: (params: GridCellParams<any, number>) => {
    if (params.row.uniqueId === 0) {
      return "dv-amount-font";
    }
    return "font-tabular-nums";
  },
};

export const qadatagridcolumns: GridColDef[] = [
 
  {
    field: "invValChangeName",
    headerName: "Inv Val Chg Name",
    flex: 0.5,
    minWidth: 180,
  },
  {
    field: "valuationChangeMonth",
    headerName: "Valuation Change Month",
    flex: 0.5,
    minWidth: 120,
  },
  {
    field: "valuationChangeYear",
    headerName: "Valuation Change Year",
    flex: 0.5,
    minWidth: 140,
    align: "right",

  },
  {
    field: "fundName",
    headerName: "Fund",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "investmentId",
    headerName: "Investment Id",
    flex: 0.3,
    minWidth: 120,
  },
  {
    field: "investmentName",
    headerName: "Investment name",
    flex: 0.4,
    minWidth: 120,
  },
  {
    field: "ownershipType",
    headerName: "Investment  Type",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "formDate",
    headerName: "Date",
    flex: 0.3,
    minWidth: 130,
    renderCell: (params) => {
      return moment(params.value).format("MMM-DD-YYYY hh:mm A");      
    },
  },

  {
    field: "sectorHeadName",
    headerName: "Sector Head",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "assetManagerName",
    headerName: "Assest Manager",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "assetManagerHeadName",
    headerName: "AM Head",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "quarter",
    headerName: "Quarter",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.3,
    minWidth: 130,
  },
];

export const datagridinvcolumns: GridColDef[] = [
  
  {
    field: "title",
    headerName: "Title",
    flex: 0.3,
    minWidth: 160,
  },
  {
    field: "fundName",
    headerName: "Fund",
    flex: 0.3,
    minWidth: 120,
  },

  {
    field: "quarter",
    headerName: "Attestation Period",
    flex: 0.3,
    minWidth: 120,
  },
  {
    field: "year",
    headerName: "Year",
    flex: 0.3,
    minWidth: 140,
    align: "right",

  },
  {
    field: "formDate",
    headerName: "Attestation Date",
    flex: 0.3,
    minWidth: 130,
    renderCell: (params) => {
      return moment(params.value).format("MMM-DD-YYYY hh:mm A");      
    },
  },

  {
    field: "sector",
    headerName: "Sector",
    flex: 0.3,
    minWidth: 120,
  },
  {
    field: "sectorHeadName",
    headerName: "Sector Head",
    flex: 0.3,
    minWidth: 130,
  },
  {
    field: "attestComplete",
    headerName: "Attestation Completed",
    flex: 0.3,
    minWidth: 150,
  },
];
