import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
import QAAttestation from "../components/QAAttestation";

///api/Attestations/qadata
const QAHeaderMain = (props: any) => {
  const divRef = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const elementRef = useRef<HTMLDivElement | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    if (!elementRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        requestAnimationFrame(() => {
          setSize((prevSize) => {
            const newWidth = entry.contentRect.width;
            const newHeight = entry.contentRect.height;
            // Prevent unnecessary state updates
            if (prevSize.width !== newWidth || prevSize.height !== newHeight) {
              return { width: newWidth, height: newHeight };
            }
            return prevSize;
          });
        });
      }
    });
    resizeObserver.observe(elementRef.current);
    resizeObserverRef.current = resizeObserver;
    return () => resizeObserver.disconnect(); // Cleanup on unmount
  }, []);

  return (
    <>
      <Grid container ref={divRef}>
        <Grid item xs={12} className="grid-title">
          <div className="header-form-header">{props?.qaData?.subHeader}</div>
        </Grid>
      </Grid>
      <Grid container item xs={12} className="QA-grid-padding "></Grid>
      <Grid container className="grid-header">
        {/* Fund Group */}
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={4}>
                <label>Fund Group: </label>
              </Grid>
              <Grid item xs={6} md={4}>
                {props?.qaData?.fundGroup}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={5}>
                <label>Attestation Year: </label>
              </Grid>
              <Grid item xs={6} md={3}>
                {props?.qaData?.quarter}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={5}>
                <label>Date:</label>
              </Grid>
              <Grid item xs={6} md={6}>
                {props?.qaData?.formDate
                  ? moment(props?.qaData?.formDate).format("MMM-DD-YYYY hh:mm A")
                  : ""}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={5}>
                <label>Last Updated By:</label>
              </Grid>
              <Grid item xs={6} md={6}>
                {props?.qaData?.updatedByName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={6}></Grid>
              <Grid item xs={6} md={6}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={5}>
                <label>Last Updated At:</label>
              </Grid>
              <Grid item xs={6} md={6}>
              {props?.qaData?.updatedAt
                  ? moment(props?.qaData?.lastUpdatedAt).format("MMM-DD-YYYY hh:mm A")
                  : ""}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};

export default QAHeaderMain;
