import "./App.css";
import {
  Configuration,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import Footer from "./Authorizations/Layout/Footer";
import QAHeaderRoot from "./QuarterlyAttestations/Header/QAHeader";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AttestationDashboard from "./Attestations/AttestDashboard/AttestationDashboard";
import HeaderRoot from "./Authorizations/Layout/Header/HeaderRoot";
import AttestHeaderRoot from "./Attestations/Layout/Header/Header";
import { LicenseInfo } from "@mui/x-license-pro";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  useMsal,
  MsalAuthenticationTemplate,
  MsalProvider,
} from "@azure/msal-react";
import React, { useState, useEffect, useMemo } from "react";
import QAAttestation from "./QuarterlyAttestations/components/QAAttestation";
import { Roles } from "./Authorizations/interfaces/Interfaces";
import { CurrentUser } from "./Authorizations/interfaces/Interfaces";
import useConfig from "./Authorizations/hooks/common-hooks";
import Dashboard from "./Authorizations/pages/Dashboard";
import QuarterlyReports from "./QuarterlyAttestations/pages/reports/QuarterlyReports/QuarterlyReports";
import AuthorizationTypes from "./Authorizations/pages/Settings/AuthorizationTypes/AuthorizationTypes";
import Fund from "./Authorizations/pages/Settings/Funds/Fund";
import Administration from "./Authorizations/pages/Reports/AdministrationOfInv/Administration";
import ActivitySummary from "./Authorizations/pages/Reports/InvestmentActivitySummary/ActivitySummary";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { updateCurrentUser } from "./redux/slices/currentUserSlice";
import {
  getUserData,
} from "./redux/slices/userContextDetailSlice";
import ConditionalRoute from "./routes/ConditionalRouting";
import VerbiageMS from "./Authorizations/pages/Settings/Verbiagemasterscreen/Verbiage";
import CapitalProjects from "./Authorizations/pages/Settings/CapitalProjects/CapitalProjects";
import Approvers from "./Authorizations/pages/Settings/Approversmasterscreen/Approvers";
import ApproverLevel from "./Authorizations/pages/Settings/ApproverLevel/ApproverLevel";
import { SessionTimeoutPopup } from "./Authorizations/components/notifications/sessionTimeoutPopup";
import FundsEmail from "./Authorizations/pages/Settings/Funds_email/FundsEmail";
import { GlobalErrorDisplay } from "./Authorizations/components/utils/GlobalErrorDisplay";
import AttestationComponent from "./Attestations/AttestationComponent";
import { attestgetUserData } from "./Attestations/redux/slices/attestuserContextDetailslice";
import LandingPageRoute from "./routes/LandingPageRouting";
import LandingPageHeaderRoot from "./LandingPage/LandingPageHeaderRoot";
import QaDashboard from "./Attestations/QaDashboard/QaDashboard";
import QAComponent from "./QuarterlyAttestations/QAComponent";
import QASettings from "./QuarterlyAttestations/pages/settings/QASettings";
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY as string);

const signOutAndClearCache = async (instance: IPublicClientApplication) => {
  try {
    const accounts = instance.getAllAccounts();
    sessionStorage.clear();
    localStorage.removeItem("dashBoardPayload");
    localStorage.removeItem("invType");
    if (accounts.length > 0) {
      await instance.logoutPopup({
        account: accounts[0],
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      }).catch(() => {
        return instance.logoutRedirect({
          postLogoutRedirectUri: "/",
        });
      });
    } else {
      await instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }

  } catch (error) {
    console.error("Error during sign out:", error);
    await instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [config] = useConfig();

  const msalInstance = useMemo(() => {
    if (!config?.msalConfig?.auth) {
      return null;
    }

    console.log(window.sessionStorage)
    return new PublicClientApplication({
      auth: {
        clientId: config.msalConfig.auth.clientId,
        authority: config.msalConfig.auth.authority,
        redirectUri: config.msalConfig.auth.redirectUri,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
      },
      system: {
        allowNativeBroker: false,
        tokenRenewalOffsetSeconds: 300
      }
    });
  }, [config]);

  if (!config?.msalConfig?.auth || !msalInstance) {
    return <>Loading config...</>;
  }

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export const Main = () => {
  const { accounts, instance } = useMsal();
  const [loggedInUserRole, setLoggedInUserRole] = useState<Roles>();
  const dispatch = useAppDispatch();
  const userdetailsdata = useAppSelector((state) => state.userContextDetail.loading);
  const [authId, setAuthId] = useState<string>('');

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const account = accounts[0];
      instance.setActiveAccount(account);

      const currentUser: CurrentUser = {
        name: account?.name,
        email: account?.username,
        tenantId: account?.tenantId,
      };

      dispatch(updateCurrentUser(currentUser));


    }
  }, [])


  const signOutClickHandler = () => {
    const logoutRequest = {
      postLogoutRedirectUri: "/",
    };
    localStorage.setItem("dashBoardPayload", JSON.stringify({}));
    localStorage.removeItem("invType");
    instance.logoutRedirect(logoutRequest);
  };
  const test = window.location.search

  const contextpath = window?.location?.pathname;
 
  useEffect(() => {


    if (contextpath?.startsWith("/attestations") ) {
      dispatch(attestgetUserData());
    } else if (contextpath.startsWith("/authorizations")) {
      dispatch(getUserData(accounts[0].username));
    }
  
  }, [contextpath]);



  // const router = createBrowserRouter([
  //   {
  //     element: (
  //       <>
  //         <HeaderRoot
  //           accounts={accounts}
  //           signOutClickHandler={signOutClickHandler}
  //           isAdmin={loggedInUserRole?.isAdmin}
  //           setAuthId={setAuthId}
  //         />
  //       </>
  //     ),
  //     errorElement: (
  //       <>
  //         <h1>Error 404 - Page Not Found</h1>
  //       </>
  //     ),
  //     children: [
  //       {
  //         path: "/",
  //         element: <Navigate to="/authorizations/dashboard" />,
  //       },
  //       {
  //         path: "/authorizations/dashboard",
  //         element: <Dashboard />,
  //       },
  //       {
  //         path: "/authorizations/settings/types",
  //         element: <AuthorizationTypes />,
  //       },
  //       {
  //         path: "/authorizations/settings/approverlevels",
  //         element: <ApproverLevel />,
  //       },
  //       {
  //         path: "/authorizations/settings/verbiage",
  //         element: <VerbiageMS />,
  //       },
  //       {
  //         path: "/authorizations/settings/fundsemail",
  //         element: <FundsEmail />,
  //       },
  //       {
  //         path: "/authorizations/settings/funds",
  //         element: <Fund />,
  //       },
  //       {
  //         path: "/authorizations/settings/approvers",
  //         element: <Approvers />,
  //       },
  //       {
  //         path: "/authorizations/settings/approvedCapitalProjects",
  //         element: <CapitalProjects />,
  //       },
  //       {
  //         path: "/authorizations/reports/administrationOfInvestments",
  //         element: <Administration />,
  //       },
  //       {
  //         path: "/authorizations/reports/investmentActivitySummary",
  //         element: <ActivitySummary />,
  //       },
  //       {
  //         path: "/authorizations/form/:id",
  //         element: <ConditionalRoute />,
  //       },
  //       {
  //         path: "/attestations/dashboard",
  //         element: <AttestationDashboard />,
  //       },
  //       {
  //         path: "*",
  //         element: <Navigate to="/authorizations/dashboard" />,
  //       },

  //     ],
  //   },
  // ]);


  const router = createBrowserRouter([
    {
      path: "/authorizations",
      element:
        <HeaderRoot
          accounts={accounts}
          signOutClickHandler={signOutClickHandler}
          isAdmin={loggedInUserRole?.isAdmin}
          setAuthId={setAuthId}
          
        />,
      errorElement: (
        <>
          <h1>Error 404 - Page Not Found</h1>
        </>
      ),

      children: [
        {
          path: "/authorizations/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/authorizations/settings/types",
          element: <AuthorizationTypes />,
        },
      
        {
          path: "/authorizations/settings/approverlevels",
          element: <ApproverLevel />,
        },
        {
          path: "/authorizations/settings/verbiage",
          element: <VerbiageMS />,
        },
        {
          path: "/authorizations/settings/fundsemail",
          element: <FundsEmail />,
        },
        {
          path: "/authorizations/settings/funds",
          element: <Fund />,
        },
        {
          path: "/authorizations/settings/approvers",
          element: <Approvers />,
        },
        {
          path: "/authorizations/settings/approvedCapitalProjects",
          element: <CapitalProjects />,
        },
        {
          path: "/authorizations/reports/administrationOfInvestments",
          element: <Administration />,
        },
        {
          path: "/authorizations/reports/investmentActivitySummary",
          element: <ActivitySummary />,
        },
        {
          path: "/authorizations/form/:id",
          element: <ConditionalRoute />,
        },
      ],

    },
    {
      path: "/attestations",
      element: <AttestHeaderRoot
        accounts={accounts}
        signOutClickHandler={signOutClickHandler}
        isAdmin={loggedInUserRole?.isAdmin}
        setAuthId={setAuthId}
      />,

      children: [
        {
          path: "/attestations/invval/form/:id",
          element: <AttestationComponent />,
        },    
        {
          path: "/attestations/invval/dashboard",
          element: <AttestationDashboard />,
        },
        {
          path: "/attestations/qa/dashboard",
          element: <QaDashboard/>,
        },      
        {
          path : "/attestations/qa/form/:id",
          element : <QAComponent/>
        },
        {
          path :  "/attestations/settings/qtrlyattestationsfundsectors",
          element : <QASettings/>
        },
        {
          path: "/attestations/reports/attestationreport",
          element: <QuarterlyReports />,
        },
        
      ]
    },
  


    {
      element: <LandingPageHeaderRoot
        accounts={accounts}
        signOutClickHandler={signOutClickHandler}
        isAdmin={loggedInUserRole?.isAdmin}
        setAuthId={setAuthId} />,
      children: [
        {
          path: "/",
          element: <LandingPageRoute  accounts={accounts}/>
        },
        {
          path: "*",
          element: <Navigate to="/" />,
        },

      ]
    }
  ]);

  return (
    <div className="main-container">
      <React.Fragment>
        <SessionTimeoutPopup />
        <GlobalErrorDisplay />
        {!userdetailsdata && <RouterProvider router={router} />}
      </React.Fragment>

      <Footer />
    </div>
  );
};

function App() {

  function ErrorComponent({ error }: { error: any }) {
    const { instance } = useMsal();
  
    useEffect(() => {
      if (error?.errorMessage?.includes('AADSTS50178')) {
        signOutAndClearCache(instance);
      }
    }, [error, instance]);
    return null;
  }
  function LoadingComponent() {
    return <p>Authentication in progress...</p>;
  }

  return (
    <AuthProvider>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <Main />
      </MsalAuthenticationTemplate>
    </AuthProvider>
  );
}

export default App;
