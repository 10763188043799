import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { getNewIvaFormIdrequest } from "../Attestations/Interfaces/AttestInterfaces";
import { getIvaInvdata } from "../Attestations/redux/slices/createAttestationslice";
import { getNewIvaFormIdData } from "../Attestations/redux/slices/newIvaFormIdSlice";
import { getNewIvaFormId } from "../Attestations/redux/slices/newIvaFormIdSlice";
import { useNavigate } from "react-router-dom";
import { resetInvestmentDataStateAction } from "../Attestations/redux/slices/IvaFormDataslice";
import { resetmaterialchangeStateAction } from "../Attestations/redux/slices/MaterialChangeDataSlice";
import { resetSubmitFormErrorStateAction } from "../redux/slices/submitFormErrorSlice";
import { setLoading } from "../redux/slices/loaderSlice";

interface CreateAuthProps {
  open: boolean;
  handleclose: () => void;
}

const CreateAttestation: React.FC<CreateAuthProps> = (props: any) => {

  const [invId, setInvID] = useState<string>();
  const ivaInvData = useAppSelector(getIvaInvdata);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const IvaFormId = useAppSelector(getNewIvaFormIdData)
  const drpdownLoading = useAppSelector((state) => state.attesdetailsloading.loading);
  const AuthDataLoading = useAppSelector((state) => state.newIvaFormIdData.loading);

  const payload: getNewIvaFormIdrequest =
  {
    invId: invId as string
  }

  const handelClose = () => {
    props.handleclose();
  };

  const SubmitButton = async () => {
  
    dispatch(getNewIvaFormId(payload));
  };
  useEffect(() => {
    if (IvaFormId?.Id) {
      dispatch(setLoading(true));
      navigate(`/attestations/invval/form/${IvaFormId?.Id}`);
      dispatch(resetInvestmentDataStateAction());
      dispatch(resetmaterialchangeStateAction());
      dispatch(resetSubmitFormErrorStateAction());
      
      props.handleclose();
      setInvID(undefined);
    }
  }, [IvaFormId?.Id]);

  const disabledbutton = !(invId)
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dashboard-dialog-box"
      >
        <DialogTitle>Create Attestations</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={ivaInvData ?? []}
            getOptionLabel={(option) => option?.invName}
            renderInput={(params) => (
              <TextField {...params} label="   Investment ID" />
            )}
            sx={{ marginTop: 2 }}
            onChange={(event: any, newValue: any) => {
              setInvID(newValue?.invId)
            }}
            loading={drpdownLoading}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            disabled={disabledbutton}
            onClick={SubmitButton}
            variant="contained"
            className="upload-btn"
            loading={AuthDataLoading}
          >
            Create
          </LoadingButton>
          <Button className="tertiary" onClick={handelClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CreateAttestation;


