
import { ThemeProvider } from "styled-components"
import Investment from "../components/shared/Investment"
import Proposal from "../components/shared/Proposal"
import { theme } from "../../config/Config"
import ProjectsDetailSectionAPLC from "../components/shared/ProjectDetailSectionAPLC"
import Attachments from "../components/shared/Attachments"
import { Attachment } from "@mui/icons-material"
import Authorization from "../components/shared/Authorization"
import Header from "../components/shared/Header"
import CapAuthActionButtons from "../components/actionButtons/ActionButtons"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import { getFormDataById } from "../../redux/slices/getFormDataSlice"
import { CircularProgress } from "@mui/material"
import ProjectsDetailSection from "../components/shared/ProjectsDetailSection"
import Loader from "../components/utils/Loader"
// import LeasingPropertyActionButton from "../components/actionButtons/LeasingPropertyActionbutton"
export const LeasingandPropertyManagement = () => {
  const formData = useAppSelector((state) => state.getFormData);
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getFormDataById(id))
    }
  }, [id]);

  return (
    <>
      {!formData?.loading ? <div className="form-dv form-field-label">
        <ThemeProvider theme={theme}>
          <Header />
          <Investment />
          <Proposal />
          <ProjectsDetailSection />
          <Attachments />
          <Authorization authType = "AppointmentLCNPMC"/>
          <CapAuthActionButtons />
          <Loader />

        </ThemeProvider>

      </div> : <div className="loading-backdrop loading-backdrop-full">
        <CircularProgress />
      </div>}
    </>
  )
}

