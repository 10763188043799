import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import LandingPage from "../LandingPage/LandingPage";
import Dashboard from "../Authorizations/pages/Dashboard";
import AttestationDashboard from "../Attestations/AttestDashboard/AttestationDashboard";
import {
  getLandingPageAccess,
  getLandingPageAccessData,
} from "../Attestations/redux/slices/landingPageSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { attestgetUserData } from "../Attestations/redux/slices/attestuserContextDetailslice";
import { getUserData } from "../redux/slices/userContextDetailSlice";
import { useMsal } from "@azure/msal-react";
type ComponentType =
  | typeof LandingPage
  | typeof Dashboard
  | typeof AttestationDashboard;
const LandingPageRoute = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const access = useAppSelector(getLandingPageAccessData);
  
  const parentComp = useMemo(
    () => ({
      LandingPage,
      Dashboard,
      AttestationDashboard,
    }),
    []
  );

  useEffect(() => {
    dispatch(getLandingPageAccess());
  }, [dispatch]);
  const { accounts, instance } = useMsal();
  const Component = useMemo(() => {
    if (!access) return null;

    if (access.hasMultipleAccess) {
      return parentComp.LandingPage;
    }

    if (access.hasAuthorizationAccess) {
      navigate("/authorizations/dashboard");
      dispatch(getUserData(props.accounts[0].username));
      return parentComp.Dashboard;
    }

    if (access.hasAttestationAccess) {
      navigate("/attestations/invval/dashboard");
      dispatch(attestgetUserData());
      return parentComp.AttestationDashboard;
    }

    return null;
  }, [access, navigate, parentComp]);

  if (!Component) {
    return (
      <div className="loading-backdrop loading-backdrop-full">
        <CircularProgress />
      </div>
    );
  }

  return <Component />;
};

export default LandingPageRoute;
