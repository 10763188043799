import { Button, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect, useState } from "react";
import { toast } from "../../Authorizations/interfaces/Interfaces";

import { useNavigate, useParams } from "react-router-dom";
import { ToastMessage } from "../../Authorizations/components/utils/ToastMessage";
const QAActionButton = (props: any) => {

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
      message: "",
      severity: "",
    });

  const nav = useNavigate();

  return (
    <>
      <Grid className="QA-btn-padding " container>
        <Grid container item xs={12} sm={6} md={4}>
            <Grid item xs={12} sm={3} md={3} className="grid-btn-padding">
              <Button
                variant="contained"
                className= {"btn-tertiary"
                }
                onClick={() =>
                {
                  setTimeout(() =>
                  {
                    nav("/attestations/qa/dashboard")
                  } , 0)
                }
                }
              >
                <CloseIcon sx={{ mr: "2.5px" }} /> Close
              </Button>
            </Grid>
        </Grid>
      
      </Grid>
      {toast && (
        <ToastMessage
          message={toastMessage.message}
          severity={toastMessage.severity}
        />
      )}
    </>
  );
};

export default QAActionButton;
