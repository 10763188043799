import { createSlice } from "@reduxjs/toolkit";
import { authorizationData } from "../../Authorizations/interfaces/Interfaces";

interface InitialState {
    data: authorizationData,
    error: string,
    loading: boolean
}
const initialState: InitialState =
{
    data: {} as authorizationData,
    error: '',
    loading: false
}

const authorizationDataSlice=createSlice(
    {
        name: 'authorizationData',
        initialState,
        reducers:{
            resetState: (state) => {
                state.data = {} as authorizationData;
                state.error = "";
                state.loading = false;
            },
            updateAuthorizationData(state,action){
                state.data=action.payload;
            }
        }, 
        
    }
);
export const { updateAuthorizationData,resetState: resetAuthorizationDataStateAction } = authorizationDataSlice.actions;
export default authorizationDataSlice.reducer;
