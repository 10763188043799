import { Container, Grid, Input, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import AccordionTab from "../../AccordianTab";
import { LoadingButton } from "@mui/lab";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppDispatch } from "../../redux/hooks";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";
import { updateErrorFields } from "../redux/slices/attestsubmitforerrorslice";
import { updateMaterialChangeData } from "../redux/slices/MaterialChangeDataSlice";
import dayjs, { Dayjs } from "dayjs";

const AttestationChange: React.FC = () => {

  const dispatch = useAppDispatch();
  const submiterrormodel = useAppSelector((state) => state.attestsubmitformerror);
  const materialchangedate = useAppSelector((state) => state.materialchangedata.data);
  const formData = useAppSelector((state) => state.ivaFormData.ivaFormData);

  useEffect(() => {
    if (formData?.id) {

      dispatch(
        updateMaterialChangeData({
          materialChangeDescription: formData.materialChangeDescription,
          formDate: formData.formDate,
          invValChangeName: formData?.invValChangeName

        })
      );
    }
  }, [formData?.id]);


  interface CustomProps {
    onChange: (event: { target: { name: string; value: any } }) => void;
    name: string;
  }


  return (
    <AccordionTab
      title={"Investment Valuation Change"}
      children={
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Grid>
                <label>Investment Valuation Change Name</label>
                <span className="mandatory"> *</span>
              </Grid>
              <Grid>
                <TextField size="small" fullWidth={true}
                  inputProps={{
                    min: 0,
                    maxLength: 256,
                    onPaste: (e) => {
                      const pastedText = e.clipboardData.getData('text');
                      if (pastedText.length > 256) {
                        e.preventDefault();
                      }
                    }
                  }}
                  error={submiterrormodel.data.attestinvchangename}
                  value={materialchangedate.invValChangeName === undefined ? "" : materialchangedate.invValChangeName}
                  disabled={!formData?.isFormEditable}
                  onChange={(event) => {
                    dispatch(
                      updateErrorFields({
                        ...submiterrormodel.data,
                        attestinvchangename: false,
                      })
                    );
                    dispatch(
                      updateMaterialChangeData({
                        ...materialchangedate,
                        invValChangeName: event.target.value,
                      })
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid>
                <label> Date</label>
                <span className="mandatory"> *</span>
              </Grid>
              <Grid className="datepicker-grid">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format="MMM-DD-YYYY"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        size: "small",
                        inputProps: { readOnly: true },
                        error: submiterrormodel.data.attestformdate,
                      },
                    }}

                    value={
                      materialchangedate.formDate
                        ? dayjs(materialchangedate.formDate)
                        : null
                    }
                    disabled={!formData?.isFormEditable}
                    onChange={(newValue) => {
                      if (newValue === null) {
                        dispatch(
                          updateMaterialChangeData({
                            ...materialchangedate,
                            startDate: null,
                          })
                        );
                      } else if (
                        dayjs.isDayjs(newValue) &&
                        newValue.isValid()
                      ) {
                        const newStartDate = new Date(
                          newValue.year(),
                          newValue.month(),
                          newValue.date(),
                          12,
                          0,
                          0
                        );
                        dispatch(
                          updateMaterialChangeData({
                            ...materialchangedate,
                            formDate: newStartDate,
                          })
                        );
                        dispatch(
                          updateErrorFields({
                            ...submiterrormodel.data,
                            attestformdate: false,
                          })
                        );

                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid>
                <label>Material Change</label>
                <span className="mandatory"> *</span>
              </Grid>
              <Grid>
                <TextField
                  multiline
                  minRows={3}
                  maxRows={7}
                  fullWidth
                  size="small"
                 
                  error={submiterrormodel.data.attestmatericalchange}
                  value={materialchangedate.materialChangeDescription === undefined ? "" : materialchangedate.materialChangeDescription}
                  disabled={!formData?.isFormEditable}
                  onChange={(event) => {
                    dispatch(
                      updateErrorFields({
                        ...submiterrormodel.data,
                        attestmatericalchange: false,
                      })
                    );
                    dispatch(
                      updateMaterialChangeData({
                        ...materialchangedate,
                        materialChangeDescription: event.target.value,
                      })
                    );
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </div>
      }
    ></AccordionTab>
  );
};
export default AttestationChange;
