import { Box, Button } from "@mui/material";
import { DataGridPro, GridCellParams, GridColDef } from "@mui/x-data-grid-pro";
// import { datagridcolumns } from "./datagridcolumns";
// import ViewTabs from "./ViewTabs";
// im
// import { datagridmodigficationcolumns } from "./datagridcolumns";
// import ActivitySummary from "./ActivitySummary";
import { qadatagridcolumns } from "./datagridviewcolumns";
import { datagridinvcolumns } from "./datagridviewcolumns";
import { useState, useEffect } from "react";
const QuarterlyR = (props: any) => {
  const getRowClassName = (params: GridCellParams) => {
    return params.row.id === 1 ? 'firstRow' : '';
  };
    const [rowData, setRowData] = useState<any>()
   
   
    useEffect(() => {
    
      setRowData(props?.data)
    
  }, [props?.data])


  return (
    <>
    

      <Box className="dashboard-table-wrapper">
        <div style={{ width: "100%" }}>
          <DataGridPro
            className="custom-data-grid"
            loading={props?.loading}         
            getRowId={(row) => row.uniqueId ?? Math.random().toString(36).substring(2,9)}
            rows = {rowData ?? []}
            columns={props.selected === 0 ? qadatagridcolumns : datagridinvcolumns}
            columnHeaderHeight={48}
            headerFilterHeight={48}
            pageSizeOptions={[2, 10, 100]}
            rowHeight={40}
            disableRowSelectionOnClick
            pagination
            disableColumnMenu={true}
            autoHeight
            hideFooter={false}
          />
        </div>
      </Box>
    </>
  );
};

export default QuarterlyR;