import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IvaFormId } from "../../Interfaces/AttestInterfaces";
import { baseUrl } from "../../../config/Config";
import { getApiCall } from "../../../ApiHelper";
import { getNewIvaFormIdrequest } from "../../Interfaces/AttestInterfaces";

type InitialState = {
    ivaFormId: IvaFormId | null,
    error: string,
    loading: boolean
}
const initialState: InitialState = {
    ivaFormId: null,
    error: '',
    loading: false
}
export const getNewIvaFormId = createAsyncThunk("getNewInvFormId", async (payload: getNewIvaFormIdrequest, {rejectWithValue}) => {
    try {
        var url = `${baseUrl}Attestations/newVCforminfo?invId=${payload.invId}`;
        const response = await getApiCall<IvaFormId>(url);
        return response;
    } catch (error: any) {
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const newIvaFormIdSlice = createSlice({
    name: 'ivaFormId',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
            builder.addCase(getNewIvaFormId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNewIvaFormId.fulfilled, (state, action: PayloadAction<IvaFormId | null>) => {
                state.loading = false;
                state.ivaFormId = action.payload;
            })
            .addCase(getNewIvaFormId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
    }
});

export const getNewIvaFormIdData = (state:any) => state.newIvaFormIdData.ivaFormId
export default newIvaFormIdSlice.reducer;

