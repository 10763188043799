import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/store";
import { getApiCall } from "../../../ApiHelper";
import { baseUrl } from "../../../config/Config";

export interface UserData {
    email: string | null;
    isExternal: boolean;
    isPropertyManager: boolean;
    isAssetManager: boolean;
    isAccounting: boolean;
    isAdmin: boolean;
}

interface UserState {
    data: UserData;
    loading: boolean;
    error: string | null;
}
const initialState: UserState = {
    data: {
        email: null,
        isExternal: false,
        isPropertyManager: false,
        isAssetManager: false,
        isAccounting: false,
        isAdmin: false
    },
    loading: false,
    error: null,
};


export const attestgetUserData = createAsyncThunk("getUserData", async (_, {rejectWithValue}) => {
    try {
        var url = `${baseUrl}Users/AttesUserContextDetails`;
        const response = await getApiCall<UserData>(url);
        return response;
    } catch (error: any) {
        console.error('Error in GET request:', error);
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const attestuserContextDetailSlice = createSlice({
    name: 'userContextDetail',
    initialState,
    reducers: {
        // Define your other reducers here
    },
    extraReducers: (builder) => {
        builder.addCase(attestgetUserData.pending, (state) => {
            state.loading = true;
        })
        .addCase(attestgetUserData.fulfilled, (state, action: PayloadAction<UserData>) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(attestgetUserData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'something went wrong';
        })
    },
});

export const attestgetuserdetailsdata= (state:any) => state.attestuserContextDetailSlice.data

export default attestuserContextDetailSlice.reducer;
