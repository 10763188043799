import * as React from 'react';
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridLoadingOverlay,
  GridRenderHeaderFilterProps,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { Autocomplete, Box, Checkbox, Grid, styled, TextField } from '@mui/material';
import qaDashboardColumn from './qaDashboardColumn.json';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useCallback, useEffect, useState } from 'react';
import { toUpper } from 'lodash';
import { baseUrl } from '../../config/Config';
import { getApiCall } from '../../ApiHelper';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function QaDashboard() {

  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [rowData, setRowData] = useState<any>();
  const [initialRowData, setInitialRowData] = useState<any>();
  const [filterModelState, setFilterModelState] = useState<any>();
  const [headingFilters, setHeadingFilters] = useState<any>({});
  const [loading, setLoading] = useState<any>(true);
  const nav = useNavigate();
  const getGridData = async () => {
    var url = `${baseUrl}Dashboard/qaData`;

    try {
      setLoading(true);
      const res = await getApiCall(url).then((response: any) => {
        const formattedRows = response?.rows?.map((row: any) => ({
          ...row,
          formDate: row?.formDate !== undefined ? moment(row?.formDate).format("MMM-DD-YYYY") : undefined
        }))
        setInitialRowData(formattedRows);
        setRowData(formattedRows);
        setHeadingFilters(response.dropdownFilter);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log("error in fetching qaGridData", error);
    }
  };

  useEffect(() => {
    getGridData();
    if (filterModelState?.items !== undefined) {
      filterData(filterModelState);
    }
  }, []);


  const dropDownFiltering = (
    value: Array<string>,
    field: string,
    filteredRows: any
  ) => {
    var filteredDropdownData: any = [];
    value.forEach((selectedDropdown: string) => {
      var filteredData = filteredRows.filter(
        (fundRow: any) =>
          fundRow[field] !== undefined &&
          toUpper(fundRow[field]) === toUpper(selectedDropdown)
      );

      filteredData.forEach((perFilterd: any) => {
        filteredDropdownData.push(perFilterd);
      });
    });
    return filteredDropdownData;
  };

  const CustomTextHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    const { colDef, inputRef } = params;
    return (
      <TextField
        id="standard-basic"
        variant="standard"
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        onChange={(event) => {
          handleFilterChange(event.target.value, params.item.field);
        }}
        InputProps={{
          ref: inputRef
        }}
      />
    );
  };

  const handleFilterChange = (e: any, field: string) => {
    var value = e;
    var fieldName = field;
    var filterModel = {
      items: [],
    };
    setSelectedOptions({
      ...selectedOptions,
      ...{
        [fieldName]: value,
      },
    });
    filterModel.items = {
      ...selectedOptions,
      ...{
        [fieldName]: value,
      },
    };
    filterData(filterModel);
    setFilterModelState(filterModel);
  };

  const filterData = (filterModel: any) => {
    var filteredRowData: any = initialRowData;
    for (const key of Object.keys(filterModel.items)) {
      switch (typeof filterModel.items[key]) {
        case "object":
          if (filterModel.items[key].length !== 0) {
            filteredRowData = dropDownFiltering(
              filterModel.items[key],
              key,
              filteredRowData
            );
          }
          break;
        case "string":
          if (filterModel.items[key].length !== 0) {
            filteredRowData = filteredRowData?.filter(
              (fundRow: any) =>
                fundRow[key] !== undefined &&
                toUpper(fundRow[key]).includes(toUpper(filterModel.items[key]))
            );
          }
          break;
        default:
          break;
      }
    }
    setRowData(filteredRowData);
  };

  const CustomDropDownHeaderFilter = (params: GridRenderHeaderFilterProps) => {
    const apiRef = useGridApiContext();
    var columnFilterList = headingFilters[params.item.field] || [];
    const { colDef, inputRef } = params;

    return (
      <Autocomplete
        className="autocomplete-root"
        ListboxProps={{
          className: "autocomplete-dashboard"
        }}
        multiple
        limitTags={1}
        id="tags-standard"
        onChange={(event, newInputValue) => {
          handleFilterChange(newInputValue, params.item.field);
        }
        }
        onFocus={() => apiRef.current.startHeaderFilterEditMode(colDef.field)}
        options={columnFilterList}
        disableCloseOnSelect
        renderOption={(props, option: any, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option}
          </li>
        )}
        fullWidth
        renderInput={(params) => <TextField {...params} variant="standard" />}
      />
    );
  };

  const columns: any = qaDashboardColumn.colDef.map((colDef: any) => {
    let { valueFormatter, ...rest } = colDef;
    if (colDef.field === "sector") {
      return {
        ...rest,
        renderHeaderFilter: CustomTextHeaderFilter,

      }
    }
    else {
      return {
        ...rest,
        renderHeaderFilter: CustomDropDownHeaderFilter,
      };
    }

  });

  const handleCellClick = (params: any) => {

    if (params.field === "__tree_data_group__" && params.rowNode.type === "leaf") {
      nav(`/attestations/qa/form/${params.row.formID}`)
    }
  };

  const getTreeDataPath = (row: any) => {
    return [...row.hierarchy, `${row.fundGrp}_${row.attestationPeriod}_${row.year}_${row.formID}`];
  };

  const groupingColDef: DataGridProProps['groupingColDef'] = {
    headerName: 'Attestation',
    width: 350,
    valueFormatter: (params: any) => {
      return params.includes('_') ? params.substring(0, params.lastIndexOf('_')) : params;
    },
    cellClassName: (params: GridCellParams<any, number>) => {
      if (params.value && params.rowNode.type === "leaf") {
        return "attestations-name-cell";
      }
      return "attestations-name-blank";
    },
  };


  return (
    <React.Fragment>
    <Grid container spacing={2} className='grid-header-dashboard' style={{ marginTop : "10px" }}>
      <Grid item xs={8}>
        <h2 className="text-left title" > Quarterly Attestations Dashboard</h2>
      </Grid>
    </Grid>
    <div className="clear" ></div>
    <div className="wrapper" style={{ marginTop : "50px" }}>
      <Box className="dashboard-table-wrapper">
        <DataGridPro
          className="dashboard-dataGrid"
          slots={{ 
            loadingOverlay: GridLoadingOverlay,
          }} 
          sx={{
            pointerEvents: loading ? 'none' : 'auto',
            opacity: loading ? 0.7 : 1,
          }}
          loading={loading}
          onCellClick={handleCellClick}
          getRowId={(row) => row.formID}
          treeData
          rows={rowData ?? []}
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          headerFilters
          columnHeaderHeight={48}
          headerFilterHeight={48}
          pageSizeOptions={[5, 10, 100]}
          rowHeight={40}
          pagination
          disableColumnMenu={true}
          autoHeight
          hideFooter={false}
          defaultGroupingExpansionDepth={2}
        />
      </Box>
    </div>
</React.Fragment>

  );
}
export default QaDashboard;