import { useState } from "react";
import MenuItems from "../../../Authorizations/Layout/Header/menuItems";
import {
  menuItemsAdminData,
  menuItemsUserData,
} from "./attestmenuItemData";
import Button from "@mui/material/Button";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { attestgetuserdetailsdata } from "../../redux/slices/attestuserContextDetailslice";
import AddIcon from "@mui/icons-material/Add";
import { getIvaInvdata } from "../../redux/slices/createAttestationslice";
import CreateAttestation from "../../../attestutils/CreateAttestation";
import { getIvaInvDetails } from "../../redux/slices/createAttestationslice";
import { resetmaterialchangeStateAction } from "../../redux/slices/MaterialChangeDataSlice";
import { resetSubmitFormErrorStateAction } from "../../redux/slices/attestsubmitforerrorslice";
import { resetInvestmentDataStateAction } from "../../redux/slices/IvaFormDataslice";
import AttestMenuItems from "./AttestMenuItems";

const AttestNavbar = (props: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const attestdata = useAppSelector(getIvaInvdata);
  const attestuserdata = useAppSelector(attestgetuserdetailsdata);

  const handleClickOpen = () => {
    if (!attestdata) {
      dispatch(getIvaInvDetails())
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  // Property manager internal - external who to show the reports section
  return (
    <>
      <nav className="desktop-nav">
        <ul className="menus">
          
          {attestuserdata?.isAdmin        
            ? menuItemsAdminData.map((menu, index) => {
              return <AttestMenuItems items={menu} key={index} />;
            })
            : menuItemsUserData.map((menu, index) => {
              return <AttestMenuItems items={menu} key={index} />;
            })}
        </ul>
      </nav>
      <div className="new-auth-dv">
        {attestuserdata?.showNewAttestButton ? (
          <Button
            variant="contained"
            style={{ backgroundColor: "#0263E0" }}
            onClick={handleClickOpen}
            color="primary"
            className="new-auth-btn"
          >
            <AddIcon
              sx={{
                fontSize: "1rem",
                marginRight: "2.5px",
                marginLeft: "-4px",
              }}
            />{" "}
            New Inv Val
          </Button>
        ) : (
          <></>
        )}
        <CreateAttestation open={open} handleclose={handleClose} />
      </div>
    </>
  );
};

export default AttestNavbar;
