import AccordionTab from "../../AccordianTab";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect, useLayoutEffect, useState } from "react";
import { setLoading } from "../../redux/slices/loaderSlice";
import Checkbox from "@mui/material/Checkbox";
// import { QADialogBox } from "../QAutils/QADialogBox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LoadingButton } from "@mui/lab";
import { baseUrl } from "../../config/Config";
import { postApiCall } from "../../ApiHelper";
import { toast } from "../../Authorizations/interfaces/Interfaces";
import { ToastMessage } from "../../Authorizations/components/utils/ToastMessage";
import { useNavigate } from "react-router-dom";
const QAAttestation: React.FC<Record<any, any>> = (props: any) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.ivaFormData.ivaFormData); //add qa details after dashboard
  useEffect(() => {
    dispatch(setLoading(false));
  }, [formData?.id]);
  const [open, setOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [saveSubmitData, setSaveSubmitData] = useState<any>();
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState<toast>({
    message: "",
    severity: "",
  });

  const [rows, setRows] = useState(1);

  const nav = useNavigate();
  useEffect(() => {
    const lineBreaks =
      (props?.attestdescription?.match(/\n/g) || []).length + 1; // Count lines
    console.log(Math.max(1, lineBreaks));

    setRows(Math.max(1, lineBreaks)); // Ensure at least 1 row
  }, [props?.attestdescription]);
  const handlecheckboxchange = (event: any) => {
    setChecked(event.target.checked);
    setOpen(event.target.checked);
  };

  const handlecheckboxclose = (event: any) => {
    setOpen(false);
  };
  const errorToastMsgwithCode = (err: any) => {
    setToast(true);
    setToastMessage({
      message: `${err.response.data.description} (${err.response.data.code})`,
      severity: "error",
    });
  };

  const payload = {
    id: props.formId,
    isAttested: true,
  };
  const Savebutton = async () => {
    const saveUrl = `${baseUrl}Attestations/qasave`;
    await postApiCall(saveUrl, payload)
      .then((response: any) => {
        if (String(response) == "Success") {
          dispatch(setLoading(false));
          setToast(true);
          setToastMessage({
            message: "Quarterly Attestation submitted sucessfully",
            severity: "success",
          });
          setTimeout(() => {
            setToast(false); 
            nav("/attestations/qa/dashboard");
          }, 2000);
        }
      })
      .catch((err: any) => {
        dispatch(setLoading(false));
        errorToastMsgwithCode(err);
      });

    setOpen(false);
  };

  return (
    <AccordionTab
      title={"Attestation"}
      children={
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                InputProps={{
                  rows: 8,
                  multiline: true,
                  inputComponent: "textarea",
                }}
                multiline
                minRows={3}
                maxRows={8}
                fullWidth
                size="small"
                value={props?.attestdescription}
                disabled={true}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={8}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handlecheckboxchange}
                      checked={checked || props?.isAttested === true}
                      disabled={!props.canattest}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: "bold" }}>
                      Please click here to attest
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12} md={4} sx={{ mt: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <label>Sector Head:</label>
                </Grid>
                <Grid item xs={12} md={5}>
                  {props?.sectorheadname}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="common-dialog-box"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you want to proceed with Attestation?"}
            </DialogTitle>
            <DialogActions>
              <LoadingButton
                onClick={Savebutton}
                variant="contained"
                className="primary-outline"
              >
                Yes
              </LoadingButton>
              <Button
                className="primary-outline"
                onClick={handlecheckboxclose}
                style={{ backgroundColor: "#0263E0" }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
          {toast && (
            <ToastMessage
              message={toastMessage.message}
              severity={toastMessage.severity}
            />
          )}
        </div>
      }
    />
  );
};
export default QAAttestation;
