import React, { useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  CircularProgress
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useState } from "react";
import { getAuditDetails } from "../../../redux/slices/auditButtonSlice";
import { getNewAuditData } from "../../../redux/slices/auditButtonSlice";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

import {
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import moment from "moment";
import { UseSelector } from "react-redux";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getFormData } from "../../../redux/slices/getFormDataSlice";
import { theme } from "../../../config/Config";

import { authData, resetAuthDataStateAction } from "../../../redux/slices/authDataSlice";
import { wordBreak } from "html2canvas/dist/types/css/property-descriptors/word-break";
type audit = {
  userName: string | undefined | null;
  action: string | undefined | null;
  createdOn: Date;
  comments: string | undefined | null;
  role: string | undefined | null;
};

const Header = (props: any) => {
  const [openAuditLog, setOpenAuditLog] = useState(false);

  const dispatch = useAppDispatch();

  const formData = useAppSelector((state) => state.getFormData);

  const handleAuditLogClose = () => {
    setOpenAuditLog(false);
  };

  const handleOpen = () => {
    dispatch(getAuditDetails(formData?.formData?.id));
    setOpenAuditLog(true);
  };

  const data = useAppSelector(getNewAuditData);

  useEffect(() => {
    dispatch(authData({ invID: formData?.formData?.invId, authType: formData?.formData?.authName, authName: formData?.formData?.fundName }));
  }, []);

  return (
    <>
    
      <Grid container>
        <Grid item xs={12} className="grid-title">
          <div className="header-form-header">{formData?.formData?.formTitle}</div>
        </Grid>
      </Grid>
      <Grid container item xs={12} className="grid-padding">
        <Grid item xs={12}>
          <Button className="btn-primary"
            style={{ float: "right" }}
            data-testid="open-audit-button"
            variant="contained"
            onClick={handleOpen}
            size="small"
          >
            <ManageSearchIcon sx={{ mr: "2.5px" }} />
            Audit
          </Button>
        </Grid>
      </Grid>
      <Grid container className="grid-header">
        <Grid container item xs={12}>
          <Grid container item xs={12} md={8}>
            <Grid container item xs={12} md={5}>
              <Grid item xs={6} md={6}>
                <label>Investment ID</label><span>:</span>
              </Grid>
              <Grid item xs={6} md={6}>{formData?.formData?.invId}</Grid>
            </Grid>
            <Grid container item xs={12} md={7}>
              <Grid item xs={6} md={2}>
                <label>Fund</label><span>:</span>
              </Grid>
              <Grid item xs={6} md={10}>{formData?.formData?.fundName}</Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={4}>
            <Grid container item xs={12}>
              <Grid item xs={6} md={6}>
                <label>Status</label><span>:</span>
              </Grid>
              <Grid item xs={6} md={6}>
                {formData?.formData?.status}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6} md={6}>
                <label>Authorization #</label><span>:</span>
              </Grid>
              <Grid item xs={6} md={6}>
                {formData?.formData?.authId}
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6} md={6}>
                <label>Authorized On</label><span>:</span>
              </Grid>
              <Grid item xs={6} md={6} className="grid-text-wrap">
                {formData?.formData?.authDate ? moment(formData?.formData?.authDate).format("MMM-DD-YYYY hh:mm A") : ''}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog data-testid="close-audit-button" open={openAuditLog} onClose={handleAuditLogClose} className="common-dialog-box audit-log" >
        <DialogTitle>
          Audit Log
          <IconButton
            color="inherit"
            className="close-btn"
            aria-label="close"
            onClick={handleAuditLogClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>

          <ThemeProvider theme={theme}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #ccc",
                padding: "10px",
                overflowY: "auto"
              }}
            >
              <Box sx={{ width: "95%" }}>

                {data !== null ? data.map((item: audit, index: any) => (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent sx={{ flex: 1, textAlign: "right" }}>
                      <Typography color="textSecondary">
                        {moment(item.createdOn).format("MMM-DD-YYYY hh:mm A")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="secondary" sx={{ bgcolor: "#0263E0" }} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        color="textSecondary"
                        variant="caption"
                        sx={{ mb: "4px" }}
                      >
                        {item.action}
                      </Typography>
                      <Typography>{item.userName}</Typography>
                      <Typography sx= {{wordBreak : "break-word"}}>{item.comments}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                )) :
                  <div className="loading-backdrop">
                    <CircularProgress />
                  </div>
                }


              </Box>


            </Box>
          </ThemeProvider>
        </DialogContent>
      </Dialog >
    </>
  );
};

export default Header;
