import { Checkbox, FormControl, Grid, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { makeStyles } from "tss-react/mui";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import * as React from 'react';
import _debounce from 'lodash/debounce';
import AccordionTab from "../../../AccordianTab";
import { getuserdetailsdata } from "../../../redux/slices/userContextDetailSlice";
import { updateleaseProjectDetail } from "../../../redux/slices/leaseProjectDetailSlice";
import { updateErrorFields } from "../../../redux/slices/submitFormErrorSlice";
import { leaseDataForApprovers } from "../../../redux/slices/commonslice";
import { useEffect } from "react";
import { updateAuthorizationData } from "../../../redux/slices/authorizationDataSlice";

const useStyles = makeStyles()((theme) => ({
    input: {
      '& input[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
      },
    },
  }));

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                allowNegative = {false}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="$ "
            />
        );
    },
);

const NumericFormatCustomNoSign = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        const  max_imit = 100
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                isAllowed={(values) => {
                    const floatValue = parseFloat(values.value || "0");
                    return floatValue <= max_imit;
                  }}
                  decimalScale={2}
                
                allowNegative = {false}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                valueIsNumericString
            />
        );
    },
);

const NumericFormatCustomNoSignSeparator = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                allowNegative = {false}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                decimalScale={0}
            />
        );
    },
);

const LeaseProjectDetails: React.FC<Record<any, any>> = (props) => {
    const dispatch = useAppDispatch();

    const formData = useAppSelector((state) => state.getFormData.formData);
    const userdata = useAppSelector(getuserdetailsdata);
    const submitErrorModel = useAppSelector((state) => state.submitFormError);
    const leaseProjectDetailData = useAppSelector((state) => state.leaseProjectDetail.data)
    const authorizationData = useAppSelector((state) => { return state.authorizationData.data });

    const classes = useStyles();

    const [OFACCheckboc, setOFACCheckbox] = React.useState<boolean>(false);
    const [leaseMarketRate, setLeaseMarketRate] = React.useState('');

    React.useEffect(() => {
        if (formData !== null) {
            setOFACCheckbox(formData?.ofacDocCertified);
            setLeaseMarketRate(formData?.leaseMarketRate === null || formData?.leaseMarketRate === undefined ? "" : formData?.leaseMarketRate)
            dispatch(updateleaseProjectDetail({
                ...leaseProjectDetailData,
                projectName: formData?.projectName,
                detailedProjDesc: formData?.description,
                leaseTerms: formData?.leaseTerm,
                leasePremise: formData?.leasePremises,
                leaseMarketRate: formData?.leaseMarketRate,
                amount: formData?.authAmount,
                ofacDoc: formData?.ofacDocCertified
            }));

            if (formData?.showApprovers) {
                dispatch(leaseDataForApprovers({
                    leaseamount: formData?.authAmount,
                    leaseTerms: formData?.leaseTerm,
                    leasePremises: formData?.leasePremises
                }));
            }
        }
    }, [formData]);

    const handleMarketRateChange = (event: any) => {
        setLeaseMarketRate(event.target.value);
        dispatch(updateAuthorizationData({ ...authorizationData, selectedApprovers: [] }));
        dispatch(updateleaseProjectDetail({ ...leaseProjectDetailData, leaseMarketRate: event.target.value }));
        dispatch(updateErrorFields({ ...submitErrorModel.data, leaseTerms: false, leasePremises: false, leaseMarketRate: false }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOFACCheckbox(event.target.checked);
        dispatch(updateleaseProjectDetail({ ...leaseProjectDetailData, ofacDoc: event.target.checked }));
        dispatch(updateErrorFields({ ...submitErrorModel.data, leaseOFAC: false }));
    };

    const handleAmountDebounceFn = (value: any) => {
        if (userdata.isAssetManager && formData?.isFormEditable) {
            dispatch(leaseDataForApprovers({ leaseamount: parseFloat(value) }));
        }
    }

    const handleLeaseTermsDebounceFn = (value: any) => {
        if (userdata.isAssetManager && formData?.isFormEditable) {
            dispatch(leaseDataForApprovers({ leaseTerms: parseFloat(value) }));
        }
    }

    const handleLeasePremisesDebounceFn = (value: any) => {
        if (userdata.isAssetManager && formData?.isFormEditable) {
            dispatch(leaseDataForApprovers({ leasePremises: parseFloat(value) }));
        }
    }

    const debounceFnAmount = React.useCallback(_debounce(handleAmountDebounceFn, 1000), []);
    const debounceFnLease = React.useCallback(_debounce(handleLeaseTermsDebounceFn, 1000), []);
    const debounceFnLeasePremises = React.useCallback(_debounce(handleLeasePremisesDebounceFn, 1000), []);

    const handleAmountChange = (e: any) => {
        dispatch(updateleaseProjectDetail({ ...leaseProjectDetailData, amount: Number.isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value) }));
        dispatch(updateErrorFields({ ...submitErrorModel.data, leaseAmount: false, leaseTerms: false, leasePremises: false, leaseMarketRate: false }));

        if (parseFloat(e.target.value) <= 5000000000000000000 || e.target.value === "") {
            debounceFnAmount(e.target.value);
        }
    };

    const handleLeaseTermsChange = (e: any) => {
        dispatch(updateleaseProjectDetail({ ...leaseProjectDetailData, leaseTerms: parseFloat(e.target.value) }));
        dispatch(updateErrorFields({ ...submitErrorModel.data, leaseAmount: false, leaseTerms: false, leasePremises: false, leaseMarketRate: false }));

        if (parseFloat(e.target.value) <= 5000000000000000000 || e.target.value === "") {
            debounceFnLease(e.target.value);
        }
    };

    const handleLeasePremisesChange = (e: any) => {
        dispatch(updateleaseProjectDetail({ ...leaseProjectDetailData, leasePremise: parseFloat(e.target.value) }));
        dispatch(updateErrorFields({ ...submitErrorModel.data, leaseAmount: false, leaseTerms: false, leasePremises: false, leaseMarketRate: false }));

        if (parseFloat(e.target.value) <= 5000000000000000000 || e.target.value === "") {
            debounceFnLeasePremises(e.target.value);
        }
    };

    function usePrevious(value: any) {
        const ref = React.useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    var prevAmount: any = usePrevious(leaseProjectDetailData.amount);
    var prevLeaseTerms: any = usePrevious(leaseProjectDetailData.leaseTerms);
    var prevLeasePremises: any = usePrevious(leaseProjectDetailData.leasePremise);

    useEffect(() => {
        var leaseAmountPrevCheck = (prevAmount !== undefined && prevAmount !== null && prevAmount !== leaseProjectDetailData.amount);
        var leaseTermsPrevCheck = (prevLeaseTerms !== undefined && prevLeaseTerms !== null && prevLeaseTerms !== leaseProjectDetailData.leaseTerms);
        var leasePremisesPrevCheck = (prevLeasePremises !== undefined && prevLeasePremises !== null && prevLeasePremises !== leaseProjectDetailData.leasePremise);

        if (leaseAmountPrevCheck || leaseTermsPrevCheck || leasePremisesPrevCheck) {
            dispatch(updateAuthorizationData({ ...authorizationData, selectedApprovers: [] }));
        }

    }, [leaseProjectDetailData.amount, leaseProjectDetailData.leaseTerms, leaseProjectDetailData.leasePremise]);

    const projectNameHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(updateleaseProjectDetail({ ...leaseProjectDetailData, projectName: event.target.value }));
    }

    const projectDescriptionHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(updateleaseProjectDetail({ ...leaseProjectDetailData, detailedProjDesc: event.target.value }));
    }

    return (
        <AccordionTab title={'Project Detail'} children={
            <>
                <Grid container spacing={3} >
                    <Grid item xs={12} md={6}>
                        <Grid>
                            <label>Project Name</label>
                            <span className="mandatory"> *</span>
                        </Grid>
                        <Grid>
                            <TextField size="small"
                                fullWidth={true}
                                value={leaseProjectDetailData.projectName}
                                error={submitErrorModel.data.leaseprojectname}
                                inputProps={{
                                    min: 0,
                                    maxLength: 256,
                                    onPaste: (e) => {
                                      const pastedText = e.clipboardData.getData('text');
                                      if (pastedText.length > 256) {
                                        e.preventDefault();
                                      }
                                    }
                                  }}
                                onChange={(event) => { projectNameHandler(event)  
                                    dispatch(
                                    updateErrorFields({
                                      ...submitErrorModel.data,
                                      leaseprojectname: false,
                                    })
                                  )}}
                                 disabled={!formData?.isFormEditable}></TextField>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid>
                            <label>Detailed Project Description</label>
                        </Grid>
                        <Grid>
                            <TextField
                                value={leaseProjectDetailData.detailedProjDesc}
                                onChange={(event) => {
                                    projectDescriptionHandler(event);
                                }}
                                multiline
                                minRows={3}
                                maxRows={7}
                                size='small'
                                fullWidth={true}
                                disabled={!formData?.isFormEditable}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Grid>
                            <label>Lease Terms(YRS)</label>

                            <label
                                style={{ fontSize: "smaller" }}
                            >  (Including Renewal Options)</label>
                        </Grid>
                        <Grid>
                            <TextField
                                InputProps={{
                                    inputComponent: NumericFormatCustomNoSign as any
                                }}
                                inputProps={{ min: 0, style: { textAlign: 'right' } ,maxLength :18  }}
                                fullWidth
                                size="small"
                                disabled={!formData?.isFormEditable}
                                className={classes.classes.input}
                                value={leaseProjectDetailData.leaseTerms}
                                onChange={(event) => {
                                    handleLeaseTermsChange(event);
                                }}
                                error={submitErrorModel.data.leaseTerms}>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Grid>
                            <label>Lease Premises(SF)</label>

                            <label
                                style={{ fontSize: "smaller" }}
                            >  (Including Expansion Options)</label>
                        </Grid>
                        <Grid>
                            <TextField
                                InputProps={{
                                    inputComponent: NumericFormatCustomNoSignSeparator as any
                                }}
                                inputProps={{ min: 0, style: { textAlign: 'right' } , maxLength :18 }}
                                fullWidth
                                size="small"
                                disabled={!formData?.isFormEditable}
                                className={classes.classes.input}
                                value={leaseProjectDetailData.leasePremise}
                                onChange={(event) => {
                                    handleLeasePremisesChange(event);
                                }}
                                error={submitErrorModel.data.leasePremises}>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {/* <Grid container spacing={5}> */}
                        <Grid item xs={6}>
                            <label>Lease Market Rate</label>
                        </Grid>
                        <Grid>
                            <FormControl sx={{ m: 1, minWidth: 120, margin: 0, width: "100%" }} size="small">
                                <Select
                                    value={leaseMarketRate}
                                    onChange={handleMarketRateChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    error={submitErrorModel.data.leaseMarketRate}
                                    disabled={!formData?.isFormEditable}
                                >
                                    <MenuItem value="">Select</MenuItem>
                                    <MenuItem value={"yes"}>Yes</MenuItem>
                                    <MenuItem value={"no"}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {formData?.showOfacCheck ? <Grid item md={8} >
                        <label>I certify that an OFAC search has been completed and supporting documentation has been attached</label><span className="mandatory"> *</span>
                        <Checkbox size="small" onChange={handleCheckboxChange} disabled={!formData?.isFormEditable} checked={OFACCheckboc}
                            className={submitErrorModel.data.leaseOFAC ? `lease-Checkbox` : ``}
                        />
                    </Grid> : <Grid item md={8} className="grid-hide">
                    </Grid>}

                    <Grid item xs={12} md={4}>
                        <Grid>
                            {formData?.showTiaaOwnershipAmt ? <label>JV Venture Amount</label> : <label>Amount</label>}
                        </Grid>
                        <Grid>
                            <TextField
                                InputProps={{
                                    inputComponent: NumericFormatCustom as any
                                }}
                                inputProps={{ min: 0, style: { textAlign: 'right' } , maxLength : 20 }}
                                fullWidth
                                size="small"
                                disabled={!formData?.isFormEditable}
                                className={classes.classes.input}
                                value={leaseProjectDetailData.amount}
                                onChange={(event) => {
                                    handleAmountChange(event);
                                }}
                                error={submitErrorModel.data.leaseAmount}>
                            </TextField>
                        </Grid>
                    </Grid >
                    {formData?.showTiaaOwnershipAmt ?
                        <><Grid item md={8} className="grid-hide"> </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid>
                                    <label>Amount</label>
                                    <span className="mandatory"> </span>
                                </Grid>
                                <Grid>
                                    <TextField
                                        InputProps={{
                                            inputComponent: NumericFormatCustom as any
                                        }}
                                        inputProps={{ min: 0, style: { textAlign: 'right' },maxLength : 20 }}
                                        fullWidth
                                        size="small"
                                        disabled={true}
                                        className={classes.classes.input}
                                        value={(leaseProjectDetailData?.amount * (formData?.tiaaOwnershipShare / 100))}
                                        error={false}>
                                    </TextField>
                                </Grid>
                            </Grid ></> : null}
                </Grid>
            </>
        } />
    );
};


export default LeaseProjectDetails;
