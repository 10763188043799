import AttestInvestment from "./Form/AttestationInvestment";
import AttestHeaderMain from "./Layout/Header/AttestHeaderMain";
import AttestationChange from "./Form/AttestationChange";
import { theme } from "../config/Config";
import { ThemeProvider } from "@mui/material/styles";
import AttestActionButton from "./AttestActionButton";
import AttestAttachments from "./Form/AttestAttachment";
import Loader from "../Authorizations/components/utils/Loader"; 
import { getIvaFormData } from "./redux/slices/IvaFormDataslice";
import { useEffect } from "react";
import { useAppDispatch } from "../redux/hooks";
import { useParams } from "react-router-dom";
import { setLoading } from "../redux/slices/loaderSlice";

const AttestationComponent = (props: any) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      dispatch(getIvaFormData(id))
    }
  }, [id]);
  return (
    <>
      <div className="form-dv form-field-label">
        <ThemeProvider theme={theme}>
          <AttestHeaderMain />
          <AttestInvestment />
          <AttestationChange />
          <AttestAttachments />
          <AttestActionButton />
          <Loader />
        </ThemeProvider>
      </div>
    </>
  );
};

export default AttestationComponent;
