import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../../config/Config";
import { getApiCall } from "../../../ApiHelper";

type InitialState = {
    ivaInvdata: FormData | null,
    error: string,
    loading: boolean
}

const initialState: InitialState = {
    ivaInvdata: null,
    error: '',
    loading: false
}

export const getIvaInvDetails = createAsyncThunk("ivainvdetails", async (_, { rejectWithValue }) => {
    try {
        var url = `${baseUrl}Dashboard/ivainvdetails`
        const response = await getApiCall<FormData>(url);
        return response;
    } catch (error: any) {
        console.error('Error in POST request:', error);
        return rejectWithValue(`${error.response.data.description} (${error.response.data.code})`||error.message);
    }
})

const createattestInvDataSlice = createSlice({
    name: 'ivainvdetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getIvaInvDetails.pending, (state) => {
            state.loading = true;
        })
            .addCase(getIvaInvDetails.fulfilled, (state, action: PayloadAction<FormData | null>) => {
                state.loading = false;
                state.ivaInvdata = action.payload;
            })
            .addCase(getIvaInvDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'something went wrong';
            })
    }
});

export const getIvaInvdata = (state: any) => state.createattestInvData.ivaInvdata

export default createattestInvDataSlice.reducer;
