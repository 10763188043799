import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import QuarterlyR from './QuarterlyR';
const QAViewTabs = (props: any) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [data, setdata] = useState<any>()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  return (
    <Box className="box-margin">
      <Tabs value={selectedTab} onChange={handleChange} aria-label="view tabs">
        <Tab label="Inv Val Data" />
        <Tab label="Qa Data" />
      </Tabs>
      <Box>
        {selectedTab === 0 && <QuarterlyR selected={selectedTab} data={props?.data ? props?.data : []} loading = {props.loading} />}

        {selectedTab === 1 && <QuarterlyR selected={selectedTab} data={props?.data ? props?.qadata : []} loading = {props.loading} />}
      </Box>
    </Box>
  );
};
export default QAViewTabs;