import { createSlice } from "@reduxjs/toolkit";
import { leaseProjectDetail } from "../../Authorizations/interfaces/Interfaces";

interface InitialState {
    data: leaseProjectDetail,
    error: string,
    loading: boolean
}

const initialState: InitialState =
{
    data: {} as leaseProjectDetail,
    error: '',
    loading: false
}

const leaseProjectDetailSlice = createSlice(
    {
        name: 'leaseProjectDetail',
        initialState,
        reducers: {
            resetState: (state) => {
                state.data = {} as leaseProjectDetail;
                state.error = "";
                state.loading = false;
            },
            budgResetStateInImprovement: (state) => {
                state.data = {} as leaseProjectDetail;
                state.error = "";
                state.loading = false;
            },
            updateleaseProjectDetail(state, action) {
                state.data = action.payload;
            }
        },

    }
);
export const { updateleaseProjectDetail, resetState: resetleaseProjectDetailStateAction, budgResetStateInImprovement } = leaseProjectDetailSlice.actions;
export default leaseProjectDetailSlice.reducer;
